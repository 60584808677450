import { FC } from 'react'
import { useAddCollectionProcessMutation } from '../../../graphql/schema'
import { ReactComponent as LoadingIcon } from './../../../svg/icons/loading.svg'

interface AddCollectionProcessProps {
  dealId: string
  paymentId: number
  onAddCollectionProcess: () => void
  disabled?: boolean
}

const AddCollectionProcess: FC<AddCollectionProcessProps> = ({
  dealId,
  paymentId,
  onAddCollectionProcess,
  disabled
}) => {
  const [addCollectionProcess, { loading: addCollectionProcessLoading }] = useAddCollectionProcessMutation()

  return (
    <button
      disabled={disabled}
      onClick={async () => {
        await addCollectionProcess({
          variables: { input: { payment: paymentId, deal: `${dealId}` } }
        })
        onAddCollectionProcess()
      }}
      className='flex items-center rounded-md border-1 border-grayscale-400 px-5 py-2 font-medium text-labels-secondary'
    >
      Запустить коллекшн
      {addCollectionProcessLoading && <LoadingIcon className='mx-auto ml-5 animate-spin' width='14px' height='14px' />}
    </button>
  )
}

export default AddCollectionProcess
