declare global {
  interface Window {
    env: any
  }
}

type EnvType = {
  REACT_APP_DADATA_TOKEN?: string
  REACT_APP_SITE_URL?: string
  REACT_APP_API_URL?: string
  REACT_APP_BILLING_API_URL?: string
  REACT_APP_VERIFICATION_API_URL?: string
  REACT_APP_PUBLIC_VERIFICATION_URL?: string
  REACT_APP_ENVIRONMENT?: string
  REACT_APP_NOTIFICATION_API_URL?: string
  REACT_APP_COMMENTS_API_URL?: string
  REACT_APP_COMMENTS_WS_API_URL?: string
  REACT_APP_RISKS_API_URL?: string
  REACT_APP_TASKS_API_URL?: string
  REACT_APP_TASKS_WS_API_URL?: string
  REACT_APP_OPENAI_API_KEY?: string
  REACT_APP_HISTORY_API_URL?: string
  REACT_APP_DOCUMENT_GENERATOR_API_URL?: string
  REACT_APP_YANDEX_MAP_API_KEY?: string
  REACT_APP_RUBY_BACKEND_API_URL?: string
}

const environment: EnvType = {
  REACT_APP_DADATA_TOKEN: import.meta.env.REACT_APP_DADATA_TOKEN,
  REACT_APP_SITE_URL: import.meta.env.REACT_APP_SITE_URL,
  REACT_APP_API_URL: import.meta.env.REACT_APP_API_URL,
  REACT_APP_BILLING_API_URL: import.meta.env.REACT_APP_BILLING_API_URL,
  REACT_APP_VERIFICATION_API_URL: import.meta.env.REACT_APP_VERIFICATION_API_URL,
  REACT_APP_PUBLIC_VERIFICATION_URL: import.meta.env.REACT_APP_PUBLIC_VERIFICATION_URL,
  REACT_APP_ENVIRONMENT: import.meta.env.REACT_APP_ENVIRONMENT,
  REACT_APP_NOTIFICATION_API_URL: import.meta.env.REACT_APP_NOTIFICATION_API_URL,
  REACT_APP_COMMENTS_API_URL: import.meta.env.REACT_APP_COMMENTS_API_URL,
  REACT_APP_COMMENTS_WS_API_URL: import.meta.env.REACT_APP_COMMENTS_WS_API_URL,
  REACT_APP_RISKS_API_URL: import.meta.env.REACT_APP_RISKS_API_URL,
  REACT_APP_TASKS_API_URL: import.meta.env.REACT_APP_TASKS_API_URL,
  REACT_APP_TASKS_WS_API_URL: import.meta.env.REACT_APP_TASKS_WS_API_URL,
  REACT_APP_OPENAI_API_KEY: import.meta.env.REACT_APP_OPENAI_API_KEY,
  REACT_APP_HISTORY_API_URL: import.meta.env.REACT_APP_HISTORY_API_URL,
  REACT_APP_DOCUMENT_GENERATOR_API_URL: import.meta.env.REACT_APP_DOCUMENT_GENERATOR_API_URL,
  REACT_APP_YANDEX_MAP_API_KEY: import.meta.env.REACT_APP_YANDEX_MAP_API_KEY,
  REACT_APP_RUBY_BACKEND_API_URL: import.meta.env.REACT_APP_RUBY_BACKEND_API_URL
}

export { environment as env }
