import { FC } from 'react'
import { ReactComponent as PlusIcon } from '../../../../svg/icons/plus.svg'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../../components/SubmitButton'
import RelativeDateInput from '../../../../components/Forms/ControlledInputs/RelativeDateInput'
import { CollectionPostponableType, useCreateCollectionPostponeMutation } from '../../../../graphql/schema.tsx'

interface LegalPostponeFormProps {
  postponableId?: string
  onDone?: () => void
}

interface Inputs {
  dueDate: string
}

const LegalPostponeForm: FC<LegalPostponeFormProps> = ({ postponableId, onDone }) => {
  const [createCollectionPostpone, { loading }] = useCreateCollectionPostponeMutation()

  const { handleSubmit, control } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!postponableId) return
    await createCollectionPostpone({
      variables: {
        input: {
          postponableId,
          postponableType: CollectionPostponableType.LegalCollection,
          dueDate: data.dueDate
        }
      }
    })
    onDone && onDone()
  }

  return (
    <section className='overflow-x-hidden p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>Отложить процесс</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12'>
          <RelativeDateInput label='' name='dueDate' control={control} />
        </div>

        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Создать отсрочку
        </SubmitButton>
      </form>
    </section>
  )
}

export default LegalPostponeForm
