import { FC } from 'react'
import { useIrrTransactionsSuspenseQuery } from '../../graphql/schema'
import { formatDecimal } from '../../utils/formatNumber'
import { dateFormatter } from '../../utils/dateFormatter'

interface IrrTransactionsModalProps {
  dealId: string
}

const IrrTransactionsModal: FC<IrrTransactionsModalProps> = ({ dealId }) => {
  const { data: irrTransactionsData } = useIrrTransactionsSuspenseQuery({ variables: { id: dealId } })
  const irrTransactions = irrTransactionsData?.irrTransactions

  return (
    <div className='w-[680px]'>
      <h1 className='px-12 py-10 font-display text-h200'>Транзакции для IRR</h1>
      <div className='mx-12 pb-3'>
        {irrTransactions?.length ? (
          irrTransactions.map((t, index) => (
            <div className='flex justify-between border-b border-separators-primary py-5 last:border-b-0'>
              <div>Платеж №{index + 1}</div>
              <div key={t.date}>{t.amount && formatDecimal(t.amount * 100) + ' ₽'}</div>
              <div className='text-labels-secondary'>{dateFormatter.format(new Date(t.date))}</div>
            </div>
          ))
        ) : (
          <div className='text-labels-secondary'>Нет данных о транзакциях</div>
        )}
      </div>
    </div>
  )
}

export default IrrTransactionsModal
