import { FC, useState } from 'react'
import {
  AccreditationStatus,
  DocumentTypeEntity,
  SupplierCompaniesByApplicationIdQuery,
  useRemoveSupplierCompanyFromApplicationMutation
} from '../../../graphql/schema'
import { NodeType } from '../../../types'
import { Link } from 'react-router-dom'
import { companyAccreditationStatusDict } from '../../../utils/dictionaries'
import { ReactComponent as TrashIcon } from '../../../svg/icons/trash.svg'
import ConfirmationForm from '../../../components/ConfirmationForm'
import Modal from '../../../components/Modal'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import SupplyOverdueTooltip from '../../../components/SupplyOverdueTooltip.tsx'

interface SupplierProps {
  applicationId: number
  supplierCompany: NodeType<NonNullable<SupplierCompaniesByApplicationIdQuery['application']>['supplierCompanies']>
}

const Supplier: FC<SupplierProps> = ({ supplierCompany, applicationId }) => {
  const [removeDealSupply] = useRemoveSupplierCompanyFromApplicationMutation()

  const [deletionFormOpen, setDeletionFormOpen] = useState(false)

  return (
    <>
      <div className='rounded-md bg-surface-primary pb-4 shadow-card'>
        <div className='px-10 pb-4 pt-10'>
          <div className='flex items-center justify-between'>
            <Link className='hover:text-red-100' to={`/suppliers/${supplierCompany._id}`}>
              <h1 className='font-display text-h200'>{supplierCompany?.company?.shortWithOpf}</h1>
            </Link>

            <SupplyOverdueTooltip supplyId={supplierCompany._id} />
          </div>
          {supplierCompany.accreditationStatus &&
            supplierCompany.accreditationStatus !== AccreditationStatus.Pending && (
              <div className='text-grayscale-150'>
                {companyAccreditationStatusDict[supplierCompany.accreditationStatus]}
              </div>
            )}
        </div>
        <div className='my-4 px-4'>
          <table className='w-full'>
            <tbody>
              <tr className='group'>
                <td className='px-6 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>ИНН</td>
                <td className='px-6 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                  {supplierCompany.company?.inn}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='border-t-1 border-grayscale-400 px-10 py-6'>
          <div className='mb-6 font-display font-medium'>КП или счёт</div>
          <EntityFolder
            canDelete={true}
            entityId={`${applicationId}_${supplierCompany._id}`}
            folderName='КП или Счёт'
            entityType={DocumentTypeEntity.ApplicationSupplierCompany}
          />
        </div>
        <div className='border-t border-grayscale-400'>
          <button
            className='mt-3 flex h-7 w-full cursor-pointer items-center px-11 py-10 text-red-100 disabled:text-grayscale-250'
            onClick={() => setDeletionFormOpen(true)}
          >
            <TrashIcon width='20px' className='mr-8 flex-none' title='Удалить' />
            <span className='text-red-100'>Удалить поставщика</span>
          </button>
        </div>
        <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <ConfirmationForm
              title='Удаление поставки'
              onDone={() => {
                setDeletionFormOpen(false)
                removeDealSupply({
                  variables: {
                    input: { application: applicationId.toString(), supplierCompany: supplierCompany._id.toString() }
                  }
                })
              }}
              onDismiss={() => setDeletionFormOpen(false)}
            >
              Вы действительно хотите удалить поставщика?
            </ConfirmationForm>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Supplier
