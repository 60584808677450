import parseDecimal from '../../../utils/parseDecimal'
import { formatDecimal } from '../../../utils/formatNumber.ts'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { SpecItem, VatKind } from '../../../graphql/schema.tsx'

export type ParsedSpecResult = {
  items: SpecItem[]
  vatRate: number
  vatKind?: VatKind
}

const tableHeaders = [
  ['№', '№ п/п'],
  ['Наименование, страна происхождения', 'Наименование товара (1)', 'Товар'],
  ['Кол-во', 'Количество'],
  ['Ед.', 'Ед. изм.(2)'],
  ['Цена за единицу с НДС', 'Цена за ед. изм. с НДС'],
  ['НДС', 'Сумма налога'],
  ['Сумма с НДС', 'Стоимость товара, всего с учетом налога']
]
const validUnits = ['м', 'кг', 'шт', 'л', 'м2', 'м3', 'кт', 'пара']
// to lower case and remove everything except letters and numbers
const simplifyString = (str: string) => str?.toLowerCase()?.replace(/[^a-zа-я0-9]/g, '') || ''
const expectedHeaders = tableHeaders.map((strArr) => strArr.map(simplifyString))

const parseDirtyNumber = (str?: string | null): number | undefined => {
  if (!str) return
  // remove everything except numbers, dots and commas
  return parseDecimal(str.replace(/[^0-9.,]/g, ''))
}

const toCents = (n?: number) => n && Math.round(n * 100)

const checkTotalPrice = (count: number, price: number, totalPrice: number) =>
  Math.round(count * price * 100) / 100 === totalPrice

const checkVatSumm = (vatSumm: number, totalPrice: number, vatRate: number) => {
  if (vatSumm === 0) return true
  const diff = Math.abs(+((totalPrice / (100 + vatRate)) * vatRate).toFixed(2) - +vatSumm.toFixed(2))
  return diff < 0.1
}

const checkVatArticle = (paragraphs: Element[], vatRate: number, problems: string[]): VatKind | undefined => {
  const vatArticlePatterns = {
    [VatKind.Medicine]: 'Реализация мед. оборудования не облагается НДС на основании пп.1 п. 2 ст. 149 НК РФ',
    [VatKind.Usn]: 'НДС не облагается на основании статей 346.12 и 346.13 главы 26.2 НК РФ'
  }

  const vatLineIndex = paragraphs.findIndex((p) =>
    simplifyString(p.textContent || '').includes(simplifyString('В т. ч. НДС'))
  )

  if (vatLineIndex === -1) {
    if (vatRate === 0) problems.push('Отсутствует блок "В т. ч. НДС"')
    return
  }

  const vatText = paragraphs[vatLineIndex + 2].textContent || ''

  let foundKind: VatKind | undefined

  // Поиск статьи в тексте
  for (const [kind, pattern] of Object.entries(vatArticlePatterns)) {
    if (vatText.includes(pattern)) {
      foundKind = kind as VatKind
      break
    }
  }

  // Валидация для 0%
  if (vatRate === 0) {
    if (!foundKind) {
      problems.push('Ставка НДС 0% - отсутствует статья освобождения от налогообложения')
    }
  } else {
    if (foundKind) {
      problems.push('В документе присутствует статья освобождения от налогов и указана ненулевая ставка')
    }
  }

  // Если ставка 5% или 7%, это УСН
  if (vatRate === 5 || vatRate === 7) {
    return VatKind.Usn
  }

  return foundKind
}

export const parseSpec = (documentText: string, currency: string): ParsedSpecResult => {
  // create DOM parser
  const parser = new DOMParser()
  // parse document
  const doc = parser.parseFromString(documentText, 'application/xml')
  // get all tables
  const tables = doc.getElementsByTagName('w:tbl')
  // find table with exactly 7 columns
  const table = Array.from(tables).find((table) => {
    const rows = table.getElementsByTagName('w:tr')
    const columns = rows[0].getElementsByTagName('w:tc')
    return columns.length === 7
  })

  const paragraphs = Array.from(doc.getElementsByTagName('w:p')).filter((p) => !!p?.textContent)

  const totalTextElIndex = paragraphs.findIndex((p) =>
    simplifyString(p?.textContent || '')?.includes(simplifyString('Итого'))
  )
  let totalAmountFound
  for (let i = totalTextElIndex; i <= paragraphs.length; i++) {
    if (/[0-9]/.test(paragraphs[i]?.textContent || '')) {
      const match = paragraphs[i]?.textContent?.match(/\d/)
      totalAmountFound = paragraphs[i]?.textContent?.slice(match?.index)
      break
    }
  }
  const totalAmountFromDoc = toCents(parseDirtyNumber(totalAmountFound))

  const totalVatTextElIndex = paragraphs.findIndex((p) =>
    simplifyString(p?.textContent || '')?.includes(simplifyString('В т. ч. НДС'))
  )
  let totalVatFound
  for (let i = totalVatTextElIndex; i <= paragraphs.length; i++) {
    if (/[0-9]/.test(paragraphs[i]?.textContent || '')) {
      const match = paragraphs[i]?.textContent?.match(/\d/)
      totalVatFound = paragraphs[i]?.textContent?.slice(match?.index)
      break
    }
  }
  const totalVatFromDoc = toCents(parseDirtyNumber(totalVatFound))

  if (!table) throw new Error('Не найдена таблица с товарами. Убедитесь, что поставщик не изменял структуру таблицы.')

  // get rows
  const rows = table.getElementsByTagName('w:tr')

  // get headers
  const headers = Array.from(rows[0].getElementsByTagName('w:tc')).map((el) => el?.textContent)

  let extractedVatRate = 0 // Переменная для хранения ставки НДС

  const problems: string[] = []
  const headersAreValid = headers.every((header, index) => {
    if (!header) return false

    const valid = expectedHeaders[index].some((i) => simplifyString(header).includes(i))
    if (!valid) {
      problems.push(`Столбец "${header}" должен называться "${tableHeaders[index].join(' или ')}"`)
      return false
    }
    if (index === 5) {
      if (!simplifyString(header).includes('ндс')) {
        problems.push(`Столбец "${header}" должен содержать "НДС"`)
        return false
      }

      const hasVatPercentage = /\d+%/.test(header)
      if (!hasVatPercentage) {
        problems.push('Не указан % ставки НДС в шапке таблицы')
        return false
      }
      const vatMatch = header.match(/(\d+)%?/)
      if (!vatMatch) {
        problems.push('Не удалось определить ставку НДС')
        return false
      }

      extractedVatRate = parseInt(vatMatch[1], 10)
      if (isNaN(extractedVatRate) || extractedVatRate < 0 || extractedVatRate > 100) {
        problems.push(`Некорректная ставка НДС: ${vatMatch[1]}%`)
        return false
      }
    }

    if (header.includes('НДС') && !header.includes(currency)) {
      // исключение для рубля, позволяем использовать "руб" или "₽"
      if (currency === 'RUB' && (simplifyString(header).includes('руб') || header.includes('₽'))) return true

      problems.push(`Неправильная валюта столбца "${header}". Ожидаемая: "${currency}"`)
      return false
    }
    return true
  })
  if (!headersAreValid) {
    problems.forEach((problem) => {
      throw new Error(problem)
    })
  }

  const vatKind = checkVatArticle(paragraphs, extractedVatRate, problems)

  const spec: Partial<SpecItem>[] = []

  const vatSummProblems: string[] = []
  const vatProblems: string[] = []
  const summProblems: string[] = []
  const unitProblems: string[] = []

  const vats: { value: number; lines: string[] }[] = []
  Array.from(rows).forEach((row, index) => {
    if (index === 0) return
    const columns = row.getElementsByTagName('w:tc')
    const textContents = Array.from(columns).map((el) => el?.textContent)

    const isRowEmpty = textContents.every((text, i) => {
      // skip first column
      if (i === 0) return true
      return !text
    })

    if (isRowEmpty) return

    if (textContents[0]?.length && !Number.isFinite(parseDirtyNumber(textContents[5]))) {
      vatSummProblems.push(textContents[0])
    }

    const vatFound = vats.find((v) => v.value === extractedVatRate)
    if (textContents[0]?.length) {
      if (vatFound) {
        vatFound.lines.push(textContents[0])
      } else {
        vats.push({ value: extractedVatRate, lines: [textContents[0]] })
      }
    }

    if (
      textContents[0]?.length &&
      !checkVatSumm(parseDirtyNumber(textContents[5]) || 0, parseDirtyNumber(textContents[6]) || 0, extractedVatRate)
    ) {
      vatProblems.push(textContents[0])
    }

    if (
      textContents[0]?.length &&
      !checkTotalPrice(
        parseDirtyNumber(textContents[2]) || 0,
        parseDirtyNumber(textContents[4]) || 0,
        parseDirtyNumber(textContents[6]) || 0
      )
    ) {
      summProblems.push(textContents[0])
    }

    if (textContents[0]?.length && textContents[3]?.length && !validUnits.includes(simplifyString(textContents[3]))) {
      unitProblems.push(textContents[0])
    }

    const specItem: Partial<SpecItem> = {
      name: textContents[1] || undefined,
      count: parseDirtyNumber(textContents[2]),
      unit: textContents[3] || undefined,
      pricePerUnitCents: toCents(parseDirtyNumber(textContents[4])),
      VATCents: toCents(parseDirtyNumber(textContents[5])),
      totalPriceCents: toCents(parseDirtyNumber(textContents[6]))
    }
    spec.push(specItem)
  })
  if (vatSummProblems.length) {
    problems.push(`Укажите НДС в сумме в строке №${vatSummProblems.toString()}`)
  }
  if (vatProblems.length) {
    problems.push(`Проверьте НДС в строке №${vatProblems.toString()}`)
  }
  if (summProblems.length) {
    problems.push(`Проверьте сумму в строке №${summProblems.toString()}`)
  }
  if (unitProblems.length) {
    problems.push(`Недопустимое значение в столбце “Единица измерения” в строке №${unitProblems.toString()}`)
  }
  if (vats.length > 1) {
    const vatWithMaxLines = vats.reduce(
      (max, current) => (current.lines.length > max.lines.length ? current : max),
      vats[0]
    )
    const vatsWithoutMaxLines = vats.filter((v) => v.value !== vatWithMaxLines.value)
    problems.push(
      vatsWithoutMaxLines
        .map((v) => `В строке №${v.lines.toString()} ставка НДС ${v.value}%, ожидаемая ${vatWithMaxLines.value}%`)
        .join('. ')
    )
  }

  const { totalAmount, totalVat } = getSpecTotals(spec as SpecItem[])
  if (totalAmountFromDoc !== totalAmount) {
    problems.push(`Перепроверьте итоговую сумму.
      Ожидаемая: ${formatDecimal(totalAmount)};
      Итоговая: ${formatDecimal(totalAmountFromDoc)}`)
  }
  const diffVat = Math.abs(
    (parseDirtyNumber(formatDecimal(totalVatFromDoc)) || 0) - (parseDirtyNumber(formatDecimal(totalVat)) || 0)
  )
  if (diffVat > 0.1) {
    problems.push(`Перепроверьте итоговый НДС.
      Ожидаемый: ${formatDecimal(totalVat)};
      Итоговый: ${formatDecimal(totalVatFromDoc)}`)
  }

  if (problems.length) {
    problems.forEach((problem, index, arr) => {
      if (index === arr.length - 1) {
        throw new Error(problem)
      }
      showPopup({ title: 'Ошибка распознавания', subtitle: problem })
    })
  }

  return {
    items: spec as SpecItem[],
    vatRate: extractedVatRate,
    vatKind: vatKind
  }
}

export const getSpecTotals = (spec: SpecItem[]) => {
  const result = spec?.reduce(
    (acc, entry) => {
      acc.totalAmount += entry?.totalPriceCents || 0
      acc.totalVat += entry?.VATCents || 0
      return acc
    },
    { totalAmount: 0, totalVat: 0 }
  ) ?? { totalAmount: 0, totalVat: 0 }
  return result
}
