import { FC, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  AccreditationStatus,
  TagEntity,
  useAddTagToSupplierCompanyMutation,
  useRemoveTagFromSupplierCompanyMutation,
  useSupplierByIdQuery
} from '../../graphql/schema'
import {
  companyAccreditationColorDict,
  companyAccreditationTextColorDict,
  companyColorDict,
  CompanyStatus,
  companyStatusDict,
  companyTextColorDict
} from './models'
import { companyAccreditationStatusDict } from '../../utils/dictionaries'
import c from 'clsx'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import SuspenseModal from '../../components/SuspenseModal'
import FrameContractForm from './FrameContractForm'
import { dateFormatter } from '../../utils/dateFormatter'
import SupplierStatusSwitcher from './StatusSwitcher.tsx'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader.tsx'
import { EntityPage } from '../../components/EntityPage/EntityPage.tsx'
import Tags from '../../components/Tags/index.tsx'
import useNodes from '../../hooks/useNodes.ts'

const Supplier: FC = () => {
  const { id } = useParams<'id'>()
  const _id = id ? parseInt(id) : undefined

  const { data, refetch, loading } = useSupplierByIdQuery({ variables: { id: `${id}` }, skip: !id })

  const supplier = data?.supplierCompany
  const status = supplier?.company?.dadata?.data?.state?.status as CompanyStatus
  const tags = useNodes(supplier?.tags?.edges)

  const [frameFormOpen, setFrameFormOpen] = useState(false)

  const [addTagToSupplerCompany] = useAddTagToSupplierCompanyMutation()
  const [removeTagFromSupplierCompany] = useRemoveTagFromSupplierCompanyMutation()

  const addTag = async (tagId: number) => {
    await addTagToSupplerCompany({
      variables: {
        input: {
          supplierCompany: `${id}`,
          tag: `${tagId}`
        }
      }
    })
  }

  const removeTag = async (tagId: number) => {
    await removeTagFromSupplierCompany({
      variables: {
        input: {
          supplierCompany: `${id}`,
          tag: `${tagId}`
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeTagFromSupplierCompany: {
          __typename: 'removeTagFromSupplierCompanyPayload',
          supplierCompany: {
            __typename: 'SupplierCompany',
            _id: parseInt(`${id}`),
            tags: {
              __typename: 'TagConnection',
              edges: tags
                .filter((tag) => tag._id !== tagId)
                .map((t) => ({
                  __typename: 'TagEdge',
                  cursor: '',
                  node: t
                }))
            }
          }
        }
      }
    })
  }

  if (!supplier) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='mb-6 flex items-start justify-between'>
          <div>
            <EntityInfo>Компания-поставщик</EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName value={supplier?.company?.shortWithOpf} loading={loading} />
              <CopyInn inn={supplier?.company?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={supplier?.company?.inn} />
          </div>
        </div>
        <div className='mb-12 flex gap-x-4'>
          {supplier && !!_id && <SupplierStatusSwitcher id={_id} />}
          {supplier?.hasRetrobonus && (
            <div className={'rounded-md bg-tr-yellow px-5 py-3 font-medium text-dark-yellow'}>Ретробонус</div>
          )}

          {supplier?.accreditationStatus &&
            supplier?.accreditationStatus !== AccreditationStatus.Pending &&
            !!companyAccreditationStatusDict[supplier.accreditationStatus] && (
              <div
                className={c(
                  'rounded-md px-5 py-3 font-medium',
                  companyAccreditationColorDict[supplier.accreditationStatus],
                  companyAccreditationTextColorDict[supplier.accreditationStatus]
                )}
              >
                {companyAccreditationStatusDict[supplier.accreditationStatus]}
              </div>
            )}

          {!!companyStatusDict[status] && (
            <div
              className={c('rounded-md px-5 py-3 font-medium', companyColorDict[status], companyTextColorDict[status])}
            >
              {companyStatusDict[status]}
            </div>
          )}
          <Tags entityType={TagEntity.SupplierCompany} tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />
        </div>
        <EntityBar>
          <EntityBarItem title='Рамочный договор поставки' action='Создать' onAction={() => setFrameFormOpen(true)}>
            {supplier.frameContractDocumentDate && (
              <div className='flex gap-5'>
                {supplier.frameContractDocumentNumber} от{' '}
                {dateFormatter.format(new Date(supplier.frameContractDocumentDate))}
                <button className='text-labels-secondary hover:text-base-red' onClick={() => setFrameFormOpen(true)}>
                  <EditIcon />
                </button>
              </div>
            )}
          </EntityBarItem>
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Заявки' to='applications' />
          <EntityNavItem title='Сделки' to='deals' />
          <EntityNavItem title='Ретробонусы' to='retrobonuses' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      {supplier && (
        <SuspenseModal open={frameFormOpen} setOpen={setFrameFormOpen}>
          <div className='z-10 rounded-xl bg-white-0'>
            <FrameContractForm
              supplierId={supplier._id}
              onDone={() => {
                setFrameFormOpen(false)
                refetch()
              }}
            />
          </div>
        </SuspenseModal>
      )}
      <EntityPage>
        <Outlet context={[data, refetch]} />
      </EntityPage>
    </div>
  )
}

export default Supplier
