import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'
import { GridTable, GridTableCell, GridTableRow } from '../../components/Table.tsx'
import { useCompanyRelationsByIdQuery } from '../../graphql/schema.tsx'
import useNodes from '../../hooks/useNodes.ts'

const Relations: FC = () => {
  const { id } = useParams<'id'>()

  const { data } = useCompanyRelationsByIdQuery({
    variables: {
      id: parseInt(`${id}`)
    },
    skip: !id
  })
  const relations = useNodes(data?.companyRelations?.edges)
  const customerCompanyId = relations?.find((r) => r.name === 'CustomerCompany')?.entityId
  const supplierCompanyId = relations?.find((r) => r.name === 'SupplierCompany')?.entityId
  const sourceId = relations?.find((r) => r.name === 'Source')?.entityId

  return (
    <Card>
      <CardHeader>
        <CardTitle>Связанные сущности</CardTitle>
      </CardHeader>

      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <GridTable className='grid-cols-2'>
            <GridTableRow>
              <GridTableCell className='text-labels-secondary'>Клиент</GridTableCell>
              <GridTableCell>
                {customerCompanyId ? (
                  <Link className='hover:text-base-red' to={`/customers/${customerCompanyId}`}>
                    Перейти в клиента
                  </Link>
                ) : (
                  'Нет связи'
                )}
              </GridTableCell>
            </GridTableRow>
            <GridTableRow>
              <GridTableCell className='text-labels-secondary'>Поставщик</GridTableCell>
              <GridTableCell>
                {supplierCompanyId ? (
                  <Link className='hover:text-base-red' to={`/suppliers/${supplierCompanyId}`}>
                    Перейти в поставщика
                  </Link>
                ) : (
                  'Нет связи'
                )}
              </GridTableCell>
            </GridTableRow>
            <GridTableRow>
              <GridTableCell className='text-labels-secondary'>Источник</GridTableCell>
              <GridTableCell>
                {sourceId ? (
                  <Link className='hover:text-base-red' to={`/sources/${sourceId}`}>
                    Перейти в источник
                  </Link>
                ) : (
                  'Нет связи'
                )}
              </GridTableCell>
            </GridTableRow>
          </GridTable>
        </div>
      </div>
    </Card>
  )
}

export default Relations
