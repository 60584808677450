import { useCallback } from 'react'
import { ScoringAnalysisStatus, useUpdateScoringAnalysisMutation } from '../../../graphql/schema.tsx'

const useChangeStatus = (id?: number) => {
  const [updateScoringAnalysisStatus, { loading }] = useUpdateScoringAnalysisMutation()

  const changeStatus = useCallback(
    async (status: ScoringAnalysisStatus) => {
      if (!id) return
      await updateScoringAnalysisStatus({
        variables: {
          input: {
            id,
            status
          }
        }
      })
    },
    [id, updateScoringAnalysisStatus]
  )

  return [changeStatus, loading] as const
}

export default useChangeStatus
