import { FC, useState } from 'react'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { ReactComponent as TickIcon } from '../../svg/icons/tick.svg'
import { ReactComponent as CopyIcon } from '../../svg/icons/copy.svg'
import { SourceChannelsStatus, useSourceChannelsQuery } from '../../graphql/schema'
import Modal from '../../components/Modal.tsx'
import ChannelForm from './ChannelForm.tsx'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import { sourceChannelsStatusDict } from '../../utils/dictionaries.ts'
import useCopyCustomerLink from '../../hooks/useCopyCustomerLink.ts'
import useCopyPartnerLink from '../../hooks/useCopyPartnerLink.ts'
import useCopyWidgetToken from '../../hooks/useCopyWidgetToken.ts'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'

export enum SourceChannelsKind {
  Customer = 'customerFormChannelStatus',
  Partner = 'partnerFormChannelStatus',
  Api = 'apiChannelStatus',
  Widget = 'widgetChannelStatus'
}

const Channels: FC<{ sourceId: number }> = ({ sourceId }) => {
  const { data } = useSourceChannelsQuery({ variables: { id: `${sourceId}` }, skip: !sourceId })
  const source = data?.source

  const [formOpenByChannelKind, setFormOpenByChannelKind] = useState<SourceChannelsKind>()

  const hasAccessSourceEdit = useAccessCheck('source.edit')
  const [copyCustomerLink, copyCustomerLinkDone] = useCopyCustomerLink(source?.customerFormPublicId)
  const [copyPartnerLink, copyPartnerLinkDone] = useCopyPartnerLink(source?.publicId)
  const [copyWidgetToken, copyWidgetTokenDone] = useCopyWidgetToken(source?.widgetPublicId)

  if (!source) return null

  return (
    <Card>
      <CardHeader>
        <CardTitle>Каналы передачи заявки</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <div className='rounded-lg bg-surface-primary shadow-card'>
          <table className='w-full'>
            <thead className='text-p100 font-medium text-labels-primary'>
              <tr className='group'>
                <td width={180} className='border-b-1 border-r border-separators-primary px-7 py-4'>
                  Тип канала
                </td>
                <td width={125} className='border-b-1 border-r border-separators-primary px-7 py-4'>
                  Статус
                </td>
                <td className='border-b-1 border-separators-primary px-7 py-4'>Передача инфо</td>
                <td className='border-b-1 border-separators-primary' />
              </tr>
            </thead>
            <tbody>
              <tr className='group'>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>Клиентская форма</td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>
                  {sourceChannelsStatusDict[source?.customerFormChannelStatus]}
                </td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4' />
                <td width={65} className='border-b-1 border-separators-primary px-4'>
                  <div className='flex items-center justify-center gap-6'>
                    <button className='text-labels-secondary hover:text-base-red' onClick={copyCustomerLink}>
                      {copyCustomerLinkDone ? (
                        <TickIcon width='18px' height='18px' title='Ссылка на клиентскую форму' />
                      ) : (
                        <CopyIcon width='18px' height='18px' title='Ссылка на клиентскую форму' />
                      )}
                    </button>
                    {hasAccessSourceEdit && (
                      <button
                        className='text-labels-secondary hover:text-base-red'
                        onClick={() => setFormOpenByChannelKind(SourceChannelsKind.Customer)}
                      >
                        <EditIcon height='18px' width='18px' title='Изменить' />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
              <tr className='group'>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>Партнёрская форма</td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>
                  {sourceChannelsStatusDict[source?.partnerFormChannelStatus]}
                </td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4' />
                <td width={65} className='border-b-1 border-separators-primary px-4'>
                  <div className='flex items-center justify-center gap-6'>
                    <button className='text-labels-secondary hover:text-base-red' onClick={copyPartnerLink}>
                      {copyPartnerLinkDone ? (
                        <TickIcon width='18px' height='18px' title='Ссылка на партнерскую форму' />
                      ) : (
                        <CopyIcon width='18px' height='18px' title='Ссылка на партнерскую форму' />
                      )}
                    </button>
                    {hasAccessSourceEdit && (
                      <button
                        className='text-labels-secondary hover:text-base-red'
                        onClick={() => setFormOpenByChannelKind(SourceChannelsKind.Partner)}
                      >
                        <EditIcon height='18px' width='18px' title='Изменить' />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
              <tr className='group'>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>Виджет</td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>
                  {sourceChannelsStatusDict[source?.widgetChannelStatus]}
                </td>
                <td className='border-b-1 border-r border-separators-primary px-7 py-4'>{source?.email || '–'}</td>
                <td width={65} className='border-b-1 border-separators-primary px-4'>
                  <div className='flex items-center justify-center gap-6'>
                    <button className='text-labels-secondary hover:text-base-red' onClick={copyWidgetToken}>
                      {copyWidgetTokenDone ? (
                        <TickIcon width='18px' height='18px' title='Токен для виджета' />
                      ) : (
                        <CopyIcon width='18px' height='18px' title='Токен для виджета' />
                      )}
                    </button>
                    {hasAccessSourceEdit && (
                      <button
                        className='text-labels-secondary hover:text-base-red'
                        onClick={() => setFormOpenByChannelKind(SourceChannelsKind.Widget)}
                      >
                        <EditIcon height='18px' width='18px' title='Изменить' />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
              <tr className='group'>
                <td className='border-r border-separators-primary px-7 py-4'>API</td>
                <td className='border-r border-separators-primary px-7 py-4'>
                  {sourceChannelsStatusDict[source?.apiChannelStatus]}
                </td>
                <td className='border-r border-separators-primary px-7 py-4' />
                <td width={65} className='px-4'>
                  <div className='flex items-center justify-end gap-6'>
                    {hasAccessSourceEdit && (
                      <button
                        className='text-labels-secondary hover:text-base-red'
                        onClick={() => setFormOpenByChannelKind(SourceChannelsKind.Api)}
                      >
                        <EditIcon height='18px' width='18px' title='Изменить' />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <Modal open={!!formOpenByChannelKind} setOpen={() => setFormOpenByChannelKind(undefined)}>
            <div className='z-10 rounded-xl bg-white-0'>
              <ChannelForm
                source={source}
                kind={formOpenByChannelKind}
                status={formOpenByChannelKind ? source[formOpenByChannelKind] : SourceChannelsStatus.NotIssued}
                onDone={() => setFormOpenByChannelKind(undefined)}
              />
            </div>
          </Modal>
        </div>
      </div>
    </Card>
  )
}

export default Channels
