import { FC, useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { ReactComponent as MoreIcon } from '../../../svg/icons/more.svg'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { companyAccreditationStatusDict } from '../../../utils/dictionaries'
import Document from '../../../components/DocumentManager/Document'
import {
  useAddDealSupplyMutation,
  useUpdateDealSupplyMutation,
  useSupplyFormInfoSuspenseQuery,
  useDocumentsSuspenseQuery,
  useDocumentsLazyQuery,
  DealSupplyMode,
  AccreditationStatus,
  DocumentTypeEntity,
  useSuppliersSuggestionsQuery
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import c from 'clsx'
import Highlighted from '../../../components/Highlighted'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import SubmitButton from '../../../components/SubmitButton'
import {
  Radio,
  RadioGroup,
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/react'
import { parseDecimalFromMasked } from '../../../utils/parseDecimal'
import Input from '../../../components/Input'
import MaskedNumberInput from '../../../components/Forms/Inputs/MaskedNumberInput'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import useThrottledState from '../../../hooks/useThrottledState.ts'
import { ISODateToCalendar } from '../../../utils/dateFormatter'
import RelativeDateInput from '../../../components/Forms/ControlledInputs/RelativeDateInput'
import { errorToString } from '../../../utils/errorToString.ts'
import { formatDecimal } from '../../../utils/formatNumber.ts'

interface SupplyFormProps {
  onDone?: () => void
  dealId: number
  supplyId?: number
}

interface SupplyFormValues {
  supplierId: string
  supplierBranchId: string
  supplier: string
  contractId?: number | null
  contractNumber?: string
  contractDate?: string
  specificationNumber?: string
  specificationDate?: string
  shippingDate: string
  factShippingDate: string
  shippingDays: string
  amount?: string
  currency?: string
  countWorkDays: boolean
}

const FRAME_FOLDER_NAME = 'Рамочные договоры'

const SupplyForm: FC<SupplyFormProps> = ({ onDone, dealId, supplyId }) => {
  const [queryTextSupplier, setQueryTextSupplier] = useState('')
  const [throttledQueryTextSupplier] = useThrottledState(queryTextSupplier, 500)

  const editMode = !!supplyId

  const [SupplyToDeal, { loading }] = useAddDealSupplyMutation()
  const [updateSupply, { loading: updateLoading }] = useUpdateDealSupplyMutation()

  const { data: suppliersData, refetch: loadSuggestions } = useSuppliersSuggestionsQuery({
    variables: { query: '' }
  })
  const suppliers = useNodes(suppliersData?.supplierCompanies?.edges)

  const { data } = useSupplyFormInfoSuspenseQuery({
    variables: {
      id: supplyId?.toString() as string
    },
    skip: !editMode
  })

  const [frameContractDocument, setFrameContractDocument] = useState<{ number?: string; date?: string } | null>({
    number: data?.dealSupply?.frameContractDocument?._id
      ? data?.dealSupply?.contractNumber || data?.dealSupply?.supplierCompany?.frameContractDocumentNumber
      : data?.dealSupply?.contractNumber,
    date: data?.dealSupply?.frameContractDocument?._id
      ? data?.dealSupply?.contractDate || data?.dealSupply?.supplierCompany?.frameContractDocumentDate
      : data?.dealSupply?.contractDate
  })

  const { register, handleSubmit, setValue, control, watch, setError, formState } = useForm<SupplyFormValues>({
    defaultValues: {
      supplierId: data?.dealSupply?.supplierCompany?._id.toString(),
      supplierBranchId: data?.dealSupply?.companyBranch?._id.toString(),
      contractId: data?.dealSupply?.frameContractDocument?._id || null,
      amount: data?.dealSupply?.amount ? formatDecimal(data.dealSupply.amount) : '',
      currency: data?.dealSupply?.currency || 'RUB',
      shippingDate: ISODateToCalendar(data?.dealSupply?.shippingDate),
      factShippingDate: ISODateToCalendar(data?.dealSupply?.factShippingDate),
      contractNumber: frameContractDocument?.number,
      contractDate: frameContractDocument?.date && ISODateToCalendar(frameContractDocument.date),
      specificationNumber: data?.dealSupply?.specificationNumber,
      specificationDate: data?.dealSupply?.specificationDate ? ISODateToCalendar(data.dealSupply.specificationDate) : ''
    }
  })

  register('supplierId', { required: 'Выберите поставщика' })
  const supplierId = watch('supplierId')

  // разные запросы для случаев создания и редактирования поставки
  // потому что suspense query автоматически рефетчится при изменении переменных
  // а компонент уходит в suspense, и это поведение нельзя отключить через transition
  const { data: frameDocsData } = useDocumentsSuspenseQuery({
    variables: {
      filter: {
        entityId: `${data?.dealSupply?.supplierCompany?._id}`,
        entityType: DocumentTypeEntity.SupplierCompany,
        type: FRAME_FOLDER_NAME
      }
    }
  })

  const [loadFrameDocs, { data: frameDocsDataLazy, loading: filesLoading }] = useDocumentsLazyQuery()

  const onSubmit: SubmitHandler<SupplyFormValues> = async (data) => {
    const mode = data.contractId ? DealSupplyMode.Frame : DealSupplyMode.Single

    const runOperation = async () => {
      if (editMode) {
        return updateSupply({
          variables: {
            input: {
              id: supplyId?.toString(),
              frameContractDocument: data.contractId ? data.contractId?.toString() : null,
              amount: data.amount ? Math.round(parseDecimalFromMasked(data.amount) * 100) : undefined,
              currency: data?.currency,
              mode,
              companyBranch: selectedSupplierBranch?._id.toString() || null,
              shippingDate: data.shippingDate ? new Date(data.shippingDate).toISOString() : null,
              factShippingDate: data.factShippingDate ? new Date(data.factShippingDate).toISOString() : null,
              contractNumber: data?.contractNumber || null,
              contractDate: data.contractDate ? new Date(data.contractDate).toISOString() : null,
              specificationNumber: data?.specificationNumber || null,
              specificationDate: data.specificationDate ? new Date(data.specificationDate).toISOString() : null
            }
          }
        })
      }
      return SupplyToDeal({
        variables: {
          input: {
            deal: dealId.toString(),
            supplierCompany: data.supplierId,
            amount: data.amount ? Math.round(parseDecimalFromMasked(data.amount) * 100) : undefined,
            currency: data?.currency,
            shippingDate: data.shippingDate ? new Date(data.shippingDate).toISOString() : undefined,
            mode,
            frameContractDocument: data.contractId ? data.contractId?.toString() : undefined,
            companyBranch: selectedSupplierBranch?._id.toString() || undefined,
            contractNumber: data?.contractNumber || null,
            contractDate: data.contractDate ? new Date(data.contractDate).toISOString() : null,
            specificationNumber: data?.specificationNumber || null,
            specificationDate: data.specificationDate ? new Date(data.specificationDate).toISOString() : null
          }
        }
      })
    }

    await runOperation()
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<{ supplierId: string }>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  useEffect(() => {
    if (!supplierId) return
    loadFrameDocs({
      variables: {
        filter: {
          entityId: supplierId,
          entityType: DocumentTypeEntity.SupplierCompany,
          type: FRAME_FOLDER_NAME
        }
      }
    })
  }, [supplierId, loadFrameDocs])

  const frameDocs = useNodes(frameDocsData?.documents?.edges)

  const frameDocsLazy = useNodes(frameDocsDataLazy?.documents?.edges)

  useEffect(() => {
    if (editMode) return
    if (frameDocs?.length) {
      setValue('contractId', frameDocs[0]?._id)
      setValue('contractNumber', frameContractDocument?.number)
      setValue('contractDate', frameContractDocument?.date ? ISODateToCalendar(frameContractDocument.date) : '')
    } else if (frameDocs?.length === 0) {
      setValue('contractId', null)
      setValue('contractNumber', '')
      setValue('contractDate', '')
    }
  }, [setValue, editMode, frameDocs, frameContractDocument])

  const [companyBranchesOpen, setCompanyBranchesOpen] = useState(false)

  const branchIdValue = watch('supplierBranchId')

  const selectedSupplier = suppliers?.find((s) => s._id.toString() === supplierId)
  const selectedSupplierBranch = selectedSupplier?.companyBranches?.edges?.find(
    (cb) => cb?.node?._id?.toString() === branchIdValue
  )?.node
  const companyBranches = useNodes(selectedSupplier?.companyBranches?.edges)

  const selectedSupplierName = selectedSupplier ? `${selectedSupplier?.company?.shortWithOpf}` : undefined
  const selectedBranchName = selectedSupplierBranch ? `${selectedSupplierBranch?.name}` : undefined

  useEffect(() => {
    const query = throttledQueryTextSupplier.split(',')[0]
    loadSuggestions({ query })
  }, [throttledQueryTextSupplier, loadSuggestions])

  return (
    <section className='overflow-x-hidden p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>{editMode ? 'Изменить поставку' : 'Добавить поставку'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!editMode && (
          <>
            <div className='relative mb-12 flex flex-col'>
              <div className='inp-label text-p350 mb-8'>Компания, ИП или ИНН</div>
              <Combobox
                value={selectedSupplier || null}
                onChange={(s) => {
                  console.log('onChange', s)
                  if (!s) return
                  if (companyBranchesOpen) {
                    console.log('setting branch')
                    setValue('supplierBranchId', s._id.toString())
                  } else {
                    console.log('setting supplier')
                    setValue('supplierId', s._id.toString() as string, { shouldValidate: true })
                    setFrameContractDocument({
                      number: s?.frameContractDocumentNumber,
                      date: s?.frameContractDocumentDate
                    })
                    setValue('supplierBranchId', '')
                  }
                }}
                by='_id'
              >
                <div className='relative max-w-full'>
                  <div className='group relative block rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100'>
                    <ComboboxInput
                      className='inp pointer-events-auto w-full border-none bg-transparent py-7 pl-10 pr-20 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
                      placeholder='Начните печатать название или ИНН'
                      onChange={(event) => {
                        const queryString = event.target.value
                        setQueryTextSupplier(queryString)
                      }}
                      displayValue={() => {
                        if (!selectedSupplierName) return ''
                        if (!selectedBranchName) return selectedSupplierName
                        return selectedSupplierName + ', ' + selectedBranchName
                      }}
                      autoComplete='off'
                    />
                    <ComboboxButton className='absolute inset-y-0 right-0 flex items-center pl-5 pr-7'>
                      <MoreIcon className='rotate-90 text-grayscale-250' />
                    </ComboboxButton>
                  </div>
                  <ComboboxOptions>
                    <div
                      className={c(
                        'overflow absolute z-50 mt-5 max-h-165 w-full overflow-y-auto rounded-xl bg-white-0 shadow-lg ring-1 ring-grayscale-400 transition-all scrollbar-hide empty:hidden',
                        companyBranchesOpen && 'pointer-events-none -translate-x-1/2 opacity-0'
                      )}
                    >
                      {suppliers.map((s) => {
                        const companyInfo = (
                          <div>
                            <div className='mb-1'>
                              <Highlighted
                                classMarkName='text-red-100 bg-transparent'
                                text={`${s?.company?.shortWithOpf}`}
                                highlight={queryTextSupplier}
                              />
                            </div>
                            <div>
                              <span className='group-enabled:text-grayscale-150'>
                                ИНН:&nbsp;
                                <Highlighted
                                  classMarkName='text-red-100 bg-transparent'
                                  text={`${s.company?.shortWithOpf}`}
                                  highlight={queryTextSupplier}
                                />
                              </span>
                              {s?.hasRetrobonus && (
                                <span className='ml-5 group-enabled:text-grayscale-150'>Ретробонус</span>
                              )}
                              <span>
                                {s.accreditationStatus && s.accreditationStatus !== AccreditationStatus.Accepted && (
                                  <>
                                    <WarningIcon className='ml-5 inline-block w-8 align-bottom' />
                                    <span className='ml-2'>
                                      {companyAccreditationStatusDict[s.accreditationStatus]}
                                    </span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        )
                        return !s.companyBranches?.edges?.length ? (
                          <ComboboxOption
                            value={s}
                            disabled={s.accreditationStatus !== AccreditationStatus.Accepted}
                            className='group flex w-full cursor-pointer items-center justify-between px-10 py-5 text-left hover:bg-grayscale-450 ui-disabled:cursor-not-allowed ui-disabled:text-grayscale-250'
                            key={s._id}
                          >
                            {companyInfo}
                          </ComboboxOption>
                        ) : (
                          <button
                            type='button'
                            className='group flex w-full cursor-pointer items-center justify-between px-10 py-5 text-left hover:bg-grayscale-450 ui-disabled:cursor-not-allowed ui-disabled:text-grayscale-250'
                            key={s._id}
                            onClick={() => {
                              setValue('supplierId', s._id.toString() as string, { shouldValidate: true })
                              setFrameContractDocument({
                                number: s.frameContractDocumentNumber,
                                date: s?.frameContractDocumentDate
                              })
                              setCompanyBranchesOpen(true)
                            }}
                          >
                            {companyInfo}

                            <div className='flex items-center text-grayscale-200'>
                              {s.companyBranches?.edges?.length}
                              <MoreIcon className='ml-4 text-grayscale-250' />
                            </div>
                          </button>
                        )
                      })}
                    </div>
                    <div
                      className={c(
                        'overflow absolute z-50 mt-5 max-h-165 w-full overflow-y-auto rounded-xl bg-white-0 shadow-lg ring-1 ring-grayscale-400 transition-all scrollbar-hide empty:hidden',
                        companyBranchesOpen ? 'translate-x-0' : 'pointer-events-none translate-x-1/2 opacity-0'
                      )}
                    >
                      <div
                        tabIndex={companyBranchesOpen ? 0 : -1}
                        className='flex cursor-pointer items-center border-b-1 border-grayscale-350 px-10 py-8 text-grayscale-200'
                        onClick={(e) => {
                          e.stopPropagation()
                          setValue('supplierId', '')
                          setValue('contractNumber', '')
                          setValue('contractDate', '')
                          setCompanyBranchesOpen(false)
                          setQueryTextSupplier('')
                        }}
                      >
                        <MoreIcon className='mr-4 rotate-180' />
                        <div className='text-grayscale-150'>Назад</div>
                      </div>
                      <div className='w-full overflow-y-auto py-4 scrollbar-hide'>
                        {companyBranches?.map((companyBranch) => (
                          <ComboboxOption
                            key={companyBranch._id}
                            value={companyBranch}
                            className={c(
                              'flex cursor-pointer items-center hover:bg-grayscale-450',
                              selectedSupplierBranch?._id === companyBranch?._id && 'bg-grayscale-400'
                            )}
                          >
                            <div className='group w-full px-10 py-5 text-left disabled:cursor-not-allowed disabled:text-grayscale-250'>
                              <div className='mb-1 group-enabled:text-grayscale-0'>{companyBranch.name}</div>
                              <span className='group-enabled:text-grayscale-150'>
                                КПП:&nbsp;
                                {companyBranch.kpp}
                              </span>
                            </div>
                          </ComboboxOption>
                        ))}
                      </div>
                    </div>
                  </ComboboxOptions>
                </div>
              </Combobox>
              {formState.errors.supplierId && (
                <div className='text-p450 pt-2 text-red-150'>{errorToString(formState.errors.supplierId)}</div>
              )}
            </div>
          </>
        )}
        {(!!frameDocs?.length || !!frameDocsLazy?.length) && !filesLoading && (
          <Controller
            name='contractId'
            control={control}
            render={({ field }) => (
              <div className='mb-12'>
                <div className='inp-label text-p350'>Рамочный договор</div>
                <RadioGroup
                  value={field.value}
                  onChange={(value) => {
                    field.onChange({ target: { value } })
                    if (!value) {
                      setValue('contractNumber', '')
                      setValue('contractDate', '')
                    } else {
                      setValue('contractNumber', frameContractDocument?.number)
                      setValue(
                        'contractDate',
                        frameContractDocument?.date ? ISODateToCalendar(frameContractDocument.date) : ''
                      )
                    }
                  }}
                >
                  {(editMode ? frameDocs : frameDocsLazy).map((doc) => (
                    <Radio key={doc._id} className='flex cursor-pointer items-center py-5' value={doc._id}>
                      <div className='h-12 w-12 flex-none rounded-full border-1 border-grayscale-300 transition-all ui-checked:border-[7px] ui-checked:border-base-red' />
                      <div className='min-w-0 flex-grow pl-6'>
                        <Document doc={doc} canDelete={false} />
                      </div>
                    </Radio>
                  ))}
                  <Radio className='flex cursor-pointer items-center py-5' value={null}>
                    <div className='h-12 w-12 rounded-full border-1 border-grayscale-300 transition-all ui-checked:border-[7px] ui-checked:border-base-red' />
                    <div className='px-6'>Без рамочного договора</div>
                  </Radio>
                </RadioGroup>
              </div>
            )}
          />
        )}
        <div className='mb-12 grid grid-cols-2 gap-16'>
          <Controller
            render={({ field, fieldState }) => (
              <Input
                label='Номер договора'
                type='text'
                placeholder='ДП000000-00'
                error={fieldState?.error?.message}
                {...field}
              />
            )}
            name='contractNumber'
            control={control}
          />
          <Controller
            render={({ field, fieldState }) => (
              <Input label='Дата договора' type='date' error={fieldState?.error?.message} {...field} />
            )}
            name='contractDate'
            control={control}
          />
        </div>
        <div className='mb-12 grid grid-cols-2 gap-16'>
          <Controller
            render={({ field, fieldState }) => (
              <Input label='Номер спецификации' type='text' error={fieldState?.error?.message} {...field} />
            )}
            name='specificationNumber'
            control={control}
          />
          <Controller
            render={({ field, fieldState }) => (
              <Input label='Дата спецификации' type='date' error={fieldState?.error?.message} {...field} />
            )}
            name='specificationDate'
            control={control}
          />
        </div>
        <div className='mb-12'>
          <Controller
            render={({ field, fieldState }) => (
              <MaskedNumberInput
                label='Стоимость поставки, ₽'
                inputMode='numeric'
                placeholder='450 000,00'
                error={fieldState?.error?.message}
                {...field}
              />
            )}
            name='amount'
            control={control}
          />
        </div>
        <div className='mb-12'>
          <Controller
            render={({ field }) => (
              <RadioButtonsGroup
                label='Валюта поставщика'
                onChange={(value) => {
                  field.onChange({ target: { value } })
                }}
                options={[
                  ['RUB - ₽', 'RUB'],
                  ['USD - $', 'USD'],
                  ['EUR - €', 'EUR'],
                  ['CNY - Ұ', 'CNY']
                ]}
                checkedValue={field.value}
              />
            )}
            name='currency'
            control={control}
          />
        </div>
        <div className='mb-12'>
          <RelativeDateInput label='Плановая дата отгрузки' name='shippingDate' control={control} />
        </div>
        {editMode && (
          <div className='mb-12'>
            <Input label='Фактическая дата отгрузки' type='date' {...register('factShippingDate')} />
          </div>
        )}

        <SubmitButton loading={loading || updateLoading || filesLoading}>
          {!editMode && <PlusIcon className='mr-5' />}
          {editMode ? 'Сохранить' : 'Добавить'}
        </SubmitButton>
      </form>
    </section>
  )
}

export default SupplyForm
