import { FC, useState } from 'react'
import { ReactComponent as ClipIcon } from '../../../svg/icons/paperclip.svg'
import { ReactComponent as TrashIcon } from '../../../svg/icons/trash.svg'
import { ReactComponent as SpecIcon } from '../../../svg/icons/deals.svg'
import SuspenseModal from '../../../components/SuspenseModal'
import SpecTemplateForm from './SpecTemplateForm'
import { SpecItem, useUpdateDealSupplySpecMutation, VatKind } from '../../../graphql/schema'
import { useDropzone } from 'react-dropzone'
import { showPopup } from '../../../components/Toaster/showPopup'
import { parseSpec } from './parseSpec'
import { getDocumentText, parseDocx } from '../../../utils/docx'
import ConfirmationForm from '../../../components/ConfirmationForm'
import Modal from '../../../components/Modal'
import { Card } from '../../../components/Card'
import Specification from '../../../components/Specification'

type ParsedSpecResult = {
  items: SpecItem[]
  vatRate: number
  vatKind?: VatKind
}

const parseSpecFromFile = async (files: File[], currency: string): Promise<ParsedSpecResult> => {
  const file = files[0]

  const documentText = await parseDocx(file)
    .then((zip) => getDocumentText(zip))
    .catch(() => {
      throw new Error('Не удалось открыть файл. Пересохраните документ в формате docx.')
    })

  if (!documentText) throw new Error('Не удалось открыть файл. Пересохраните документ в формате docx.')

  const parsedSpec = await parseSpec(documentText, currency)

  const spec = parsedSpec.items

  if (!spec.length) throw new Error('Таблица пустая')
  return { items: spec, vatRate: parsedSpec.vatRate, vatKind: parsedSpec.vatKind }
}

// pluralize word строка using Intl.PluralRules
const pluralRules = new Intl.PluralRules('ru-RU')
const pluralLines = (count: number) => {
  const plural = pluralRules.select(count)
  switch (plural) {
    case 'one':
      return 'строка'
    case 'few':
    case 'many':
      return 'строки'
    default:
      return 'строк'
  }
}

const checkSpecEntry = (s: SpecItem): boolean => {
  for (const key of Object.keys(s)) {
    if (s[key as keyof SpecItem] === undefined) return false
  }

  return true
}

interface SpecProps {
  supplyId: number
  currency: string
  editable?: boolean
  spec?: SpecItem[]
}

const Spec: FC<SpecProps> = ({ supplyId, currency, spec, editable = true }) => {
  const [formOpen, setFormOpen] = useState(false)
  const [specOpen, setSpecOpen] = useState(false)
  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [updateSpec] = useUpdateDealSupplySpecMutation()

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    // accept docx files only
    accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
    multiple: false,
    noClick: true,
    disabled: !editable,

    onDrop: async (files) => {
      try {
        const spec = await parseSpecFromFile(files, currency)
        console.log('Распознанная спецификация', spec)

        const validSpecs = spec.items.filter(checkSpecEntry) as SpecItem[]
        const invalidCount = spec.items.length - validSpecs.length

        if (invalidCount) {
          showPopup({
            title: 'Проблемы при распознавании',
            subtitle: `В таблице найдено ${invalidCount} ${pluralLines(
              invalidCount
            )} с незаполненными полями, они исключены из результата. Убедитесь, что все позиции распознались верно`,
            autoCloseTimeout: 10000
          })
        }

        await updateSpec({
          variables: {
            input: {
              id: supplyId.toString(),
              nomenclature: validSpecs,
              vatRate: spec.vatRate,
              vatKind: spec.vatKind
            }
          }
        })
        setSpecOpen(true)
      } catch (e: unknown) {
        if (e instanceof Error) {
          showPopup({ title: 'Ошибка распознавания', subtitle: e.message })
        }
      }
    }
  })

  return (
    <>
      {spec ? (
        <div className='flex w-full items-stretch justify-start rounded-xl border-1 border-grayscale-400 ui-active:ring-2 ui-active:ring-base-red'>
          <button onClick={() => setSpecOpen(true)} className='flex flex-grow items-center px-8 py-12'>
            <SpecIcon className='flex-none text-labels-primary' />
            <div className='pl-6 text-left'>Открыть номенклатуру</div>
          </button>
          {editable && (
            <button onClick={() => setDeletionFormOpen(true)} className='flex-none px-8 text-labels-tertiary'>
              <TrashIcon width='20px' title='Удалить' />
            </button>
          )}
        </div>
      ) : (
        <div
          className='flex w-full items-center justify-start rounded-xl border-1 border-grayscale-400 px-8 py-6 ui-active:ring-2 ui-active:ring-red-50'
          data-headlessui-state={isDragActive && 'active'}
          {...getRootProps()}
        >
          <ClipIcon className='flex-none' />
          <div className='pl-6 text-left'>
            <input {...getInputProps()} />
            <button className='block' onClick={open}>
              Загрузить заполненный шаблон
            </button>
            <button className='block text-base-red' onClick={() => setFormOpen(true)}>
              Создать шаблон спецификации
            </button>
          </div>
        </div>
      )}
      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SpecTemplateForm
            supplyId={supplyId}
            onDone={() => {
              setFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={specOpen} setOpen={setSpecOpen}>
        <div className='z-10 max-w-[1200px] rounded-xl bg-white-0 px-12 pb-12 pt-10'>
          <h1 className='mb-10 text-title-l font-medium'>Номенклатура</h1>
          <Card className='p-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <Specification items={spec} currency={currency} />
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление номенклатуры'
            onDone={() => {
              setDeletionFormOpen(false)
              updateSpec({
                variables: {
                  input: {
                    id: supplyId.toString(),
                    nomenclature: null
                  }
                }
              })
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите сбросить номенклатуру?
          </ConfirmationForm>
        </div>
      </Modal>
    </>
  )
}

export default Spec
