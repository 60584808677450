import { FC } from 'react'
import { SourceChannelsQuery, SourceChannelsStatus, useUpdateSourceChannelsMutation } from '../../graphql/schema'
import { SourceChannelsKind } from './Channels.tsx'
import { RadioGroup } from '@headlessui/react'
import { sourceChannelsKindDict } from '../../utils/dictionaries.ts'
import c from 'clsx'
import { EMAIL_PATTERN } from '../../utils/constants.ts'
import Input from '../../components/Input.tsx'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../components/SubmitButton'
import Checkbox from '../../components/Forms/Checkbox.tsx'

interface BonusesFormProps {
  source: SourceChannelsQuery['source']
  status: SourceChannelsStatus
  kind?: SourceChannelsKind
  onDone: () => void
}

const SOURCE_CHANNEL_STATUSES = [
  ['Не выдано', SourceChannelsStatus.NotIssued],
  ['В работе', SourceChannelsStatus.InWork],
  ['Тестируется', SourceChannelsStatus.Test],
  ['Выдано', SourceChannelsStatus.Issued]
]

type Inputs = {
  status?: SourceChannelsStatus
  sendByEmail?: boolean
  email?: string
}

const ChannelForm: FC<BonusesFormProps> = ({ source, kind, status, onDone }) => {
  const isWidget = kind === SourceChannelsKind.Widget

  const form = useForm<Inputs>({
    defaultValues: {
      status,
      email: source?.email,
      sendByEmail: !!source?.email
    }
  })
  const { register, handleSubmit, formState, setValue, watch } = form

  const statusFromForm = watch('status')
  const sendByEmail = watch('sendByEmail')

  const [updateSourceChannel, { loading }] = useUpdateSourceChannelsMutation()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!source || !kind) return

    updateSourceChannel({
      variables: {
        input: {
          id: source._id.toString(),
          [kind]: data.status,
          email: isWidget ? (sendByEmail ? data?.email : null) : undefined
        }
      }
    }).then(() => {
      if (onDone) onDone()
    })
  }

  const canChangeEmail =
    statusFromForm && [SourceChannelsStatus.Test, SourceChannelsStatus.Issued].includes(statusFromForm)

  return (
    <section className='w-[345px] py-10'>
      <h1 className='px-10 font-display text-h200'>Статус канала</h1>
      {kind && <div className='mt-2 px-10 text-p200 text-labels-secondary'>{sourceChannelsKindDict[kind]}</div>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <RadioGroup
          className='text-p350 mt-8 px-10'
          value={statusFromForm}
          onChange={(value) => setValue('status', value)}
        >
          {SOURCE_CHANNEL_STATUSES.map((option, i) => (
            <RadioGroup.Option className='flex cursor-pointer items-center py-5' value={option[1]} key={i}>
              {({ checked }) => (
                <>
                  <div
                    className={c(
                      'h-12 w-12 rounded-full border-1 border-grayscale-300',
                      checked && 'border-[7px] !border-red-100'
                    )}
                  />
                  <div className='rounded-full px-6'>{option[0]}</div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
        {kind === SourceChannelsKind.Widget && canChangeEmail && (
          <div className='mx-4 border-t border-separators-primary px-6'>
            <Checkbox
              className='mt-8'
              label='Отправлять на email'
              checked={sendByEmail}
              onChange={(checked: boolean) => setValue('sendByEmail', checked)}
            />
            {sendByEmail && (
              <div className='mt-8'>
                <Input
                  type='text'
                  placeholder='mail@example.com'
                  {...register('email', {
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: 'Некорректный E-mail'
                    }
                  })}
                  error={formState.errors?.email}
                />
              </div>
            )}
          </div>
        )}
        <div className='px-10 pt-14'>
          <SubmitButton disabled={loading} loading={loading}>
            Сохранить
          </SubmitButton>
        </div>
      </form>
    </section>
  )
}

export default ChannelForm
