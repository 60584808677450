import { FC, useState } from 'react'
import {
  Questionnaire,
  QuestionnaireEducation,
  QuestionnaireInput,
  QuestionnaireMaritalStatus,
  useUpdateVerificationMutation
} from '../../../graphql/schema'
import { SubmitHandler, useForm } from 'react-hook-form'
import Input from '../../../components/Input'
import { ReactComponent as EditIcon } from '../../../svg/icons/edit.svg'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../../../components/SubmitButton'
import RadioButtonsGroup from '../../../components/RadioButtonsGroup'
import MaskedInput from '../../../components/MaskedInput'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import Select from '../../../components/Select'
import { educationDict, maritalStatusDict } from '../../../utils/verification'
import parseDecimal from '../../../utils/parseDecimal'

const EditQuestionnaireForm: FC<{ uuid: string; questionnaire?: Questionnaire; onDone: () => void }> = ({
  uuid,
  questionnaire,
  onDone
}) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<QuestionnaireInput>({
    defaultValues: {
      isGuarantee: questionnaire?.isGuarantee,
      income: questionnaire?.income,
      education: questionnaire?.education,
      maritalStatus: questionnaire?.maritalStatus,
      infantsCount: questionnaire?.infantsCount
    }
  })
  const [updateVerification, { loading }] = useUpdateVerificationMutation()

  const [isGuarantee, setIsGuarantee] = useState(questionnaire?.isGuarantee ? 'true' : 'false')
  const [isExecutive, setIsExecutive] = useState(questionnaire?.isExecutive ? 'true' : 'false')
  const [isRelativeToExecutive, setIsRelativeToExecutive] = useState(
    questionnaire?.isRelativeToExecutive ? 'true' : 'false'
  )

  const educationStatuses: QuestionnaireEducation[] = Object.keys(educationDict) as QuestionnaireEducation[]
  const educationStatus = watch('education') as QuestionnaireEducation

  const maritalStatuses: QuestionnaireMaritalStatus[] = Object.keys(maritalStatusDict) as QuestionnaireMaritalStatus[]
  const maritalStatus = watch('maritalStatus') as QuestionnaireMaritalStatus

  const onSubmit: SubmitHandler<QuestionnaireInput> = async (data) => {
    if (!uuid) return

    await updateVerification({
      variables: {
        uuid,
        input: {
          questionnaire: {
            ...data,
            isGuarantee: isGuarantee === 'true',
            income: Math.round(parseDecimal(data?.income?.toString()?.replace(/\s/g, '') || '')),
            infantsCount: Number(data.infantsCount),
            isExecutive: isExecutive === 'true',
            isRelativeToExecutive: isRelativeToExecutive === 'true',
            email: questionnaire?.email
          }
        }
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<QuestionnaireInput>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='w-[448px] p-12 lg:w-[912px]'>
      <h1 className='mb-12 font-display text-h200'>Редактирование опросника</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 grid grid-cols-1 gap-20 lg:grid-cols-2'>
          <div className='relative flex flex-col gap-8'>
            <RadioButtonsGroup
              onChange={(value) => setIsGuarantee(value)}
              options={[
                ['Да', 'true'],
                ['Нет', 'false']
              ]}
              checkedValue={isGuarantee}
              label='Согласие поручителя'
            />
            {isGuarantee === 'true' && (
              <>
                <MaskedInput
                  label='Доходы'
                  placeholder='150000'
                  inputMode='decimal'
                  mask='number'
                  {...register('income')}
                  error={errors.income}
                />
                <Select
                  label='Образование'
                  type='text'
                  editable={false}
                  autoComplete='off'
                  {...register('education')}
                  value={educationStatus ? educationDict[educationStatus] : ''}
                >
                  {educationStatuses.map((s) => (
                    <li
                      key={s}
                      onClick={() => {
                        setValue('education', s)
                      }}
                      className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                    >
                      <div className='flex items-center justify-between'>
                        <div className='mb-1 text-grayscale-0'>{educationDict[s]}</div>
                        {getValues('education') === s.toString() && (
                          <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                            <TickIcon />
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </Select>
                <Select
                  label='Семейное положение'
                  type='text'
                  editable={false}
                  autoComplete='off'
                  {...register('maritalStatus')}
                  value={maritalStatus ? maritalStatusDict[maritalStatus] : ''}
                >
                  {maritalStatuses.map((s) => (
                    <li
                      key={s}
                      onClick={() => {
                        setValue('maritalStatus', s)
                      }}
                      className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
                    >
                      <div className='flex items-center justify-between'>
                        <div className='mb-1 text-grayscale-0'>{maritalStatusDict[s]}</div>
                        {getValues('maritalStatus') === s.toString() && (
                          <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                            <TickIcon />
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                </Select>
                <Input
                  label='Несовершеннолетних детей'
                  type='text'
                  {...register('infantsCount')}
                  error={errors.infantsCount}
                />
              </>
            )}
          </div>

          <div className='relative flex flex-col gap-8'>
            <RadioButtonsGroup
              onChange={(value) => setIsExecutive(value)}
              options={[
                ['Да', 'true'],
                ['Нет', 'false']
              ]}
              checkedValue={isExecutive}
              label='Должностное лицо'
            />
            <RadioButtonsGroup
              onChange={(value) => setIsRelativeToExecutive(value)}
              options={[
                ['Да', 'true'],
                ['Нет', 'false']
              ]}
              checkedValue={isRelativeToExecutive}
              label='Родственник должностного лица'
            />
          </div>
        </div>

        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditQuestionnaireForm
