import { FC, useState } from 'react'
import { ReactComponent as FileDownload } from '../../../svg/icons/fileDownload.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as ChevronDownIcon } from '../../../svg/ui/chevron-down.svg'
import { DocumentNodeFragment, DocumentTypeEntity, useDocumentsQuery } from '../../../graphql/schema.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import FileIcon from '../../../components/FileIcon.tsx'
import { shortDateTimeFormatter } from '../../../utils/dateFormatter.ts'
import downloadAndSaveFile from '../../../utils/downloadFile.ts'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { env } from '../../../env.ts'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'
import c from 'clsx'

const Document: FC<{ doc: DocumentNodeFragment }> = ({ doc }) => {
  const [loading, setLoading] = useState(false)
  const docName = doc.originalName.replace('.txt', '.xlsx')

  const download = async (doc: DocumentNodeFragment) => {
    setLoading(true)
    try {
      const url = new URL(`/bank_statements/download_xlsx_by_document_id/${doc._id}`, env.REACT_APP_RISKS_API_URL)
      await downloadAndSaveFile(url.toString(), docName)
    } catch (e) {
      showPopup({ title: 'Ошибка', subtitle: 'Не удалось скачать файл. Попробуйте позже' })
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <figure className='flex items-center'>
      <FileIcon className='cursor-pointer' name={'xlsx'} />
      <div className='mx-6 min-w-0 text-p100'>
        <div className='overflow-hidden text-ellipsis whitespace-nowrap font-medium' title={docName}>
          {docName}
        </div>
        <div className='flex text-p200 text-labels-secondary'>
          {doc.user && (doc.user?.surname || doc.user?.name) && (
            <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
              {doc.user?.surname + ' ' + doc.user?.name},
            </div>
          )}
          <div className='ml-3 flex-none'>{shortDateTimeFormatter.format(new Date(doc.created))}</div>
        </div>
      </div>
      {loading ? (
        <LoadingIcon className='ml-auto flex-none animate-spin text-labels-tertiary' />
      ) : (
        <div
          className='ml-auto flex-none cursor-pointer text-labels-tertiary hover:text-base-red'
          onClick={(e) => e.stopPropagation()}
        >
          <FileDownload onClick={() => download(doc)} />
        </div>
      )}
    </figure>
  )
}

interface BankStatementsProps {
  companyId?: number
}

const BankStatements: FC<BankStatementsProps> = ({ companyId }) => {
  const [showAll, setShowAll] = useState(false)

  const { data: documentsData } = useDocumentsQuery({
    variables: {
      filter: {
        entityType: DocumentTypeEntity.Company,
        type: 'Банковские выписки',
        entityId: `${companyId}`
      }
    },
    skip: !companyId
  })
  const documents = useNodes(documentsData?.documents?.edges).filter((doc) => doc.mimeType === 'text/plain')

  return (
    <Card>
      <CardHeader>
        <CardTitle>Банковские выписки</CardTitle>
      </CardHeader>

      {!!documents.length && (
        <div className='px-5 pb-5'>
          <div className='relative rounded-md bg-surface-primary p-5 shadow-card'>
            <div className={c(!showAll && 'max-h-[112px] overflow-hidden')}>
              {documents?.map((doc) => (
                <div
                  key={doc._id}
                  className={c(
                    'border-b-1 border-grayscale-450 py-5 first:pt-0',
                    documents.length <= 2 && 'last:border-b-0 last:pb-0'
                  )}
                >
                  <Document doc={doc} />
                </div>
              ))}
            </div>
            {documents.length > 2 && (
              <button
                type='button'
                className='flex w-full items-center gap-5 pb-4 pl-8 pt-8 text-labels-tertiary'
                onClick={() => setShowAll((prev) => !prev)}
              >
                Показать все{' '}
                <ChevronDownIcon width={10} className={c('transition-transform', showAll && '-rotate-180')} />
              </button>
            )}
          </div>
        </div>
      )}
    </Card>
  )
}

export default BankStatements
