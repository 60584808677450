import { FC, useLayoutEffect, useRef } from 'react'
import { Portal } from '@headlessui/react'
import { timeline, AnimationControls } from 'motion'
import { makeVar, useReactiveVar } from '@apollo/client'

interface FizzNode {
  id: number
  text: string
  x: number
  y: number
}

const fizzs = makeVar<FizzNode[]>([])

let id = 0

const showFizz = (text: string, x: number, y: number) => {
  fizzs([
    ...fizzs(),
    {
      id: id++,
      text: text,
      x: x,
      y: y
    }
  ])
}

const deleteFizz = (id: number) => {
  fizzs(fizzs().filter((fizz) => fizz.id !== id))
}

const Fizz = ({ text, id, x, y }: FizzNode) => {
  const node = useRef<HTMLDivElement>(null)
  const animationControls = useRef<AnimationControls>()

  useLayoutEffect(() => {
    if (!node.current) return
    if (animationControls.current) return

    const controls = timeline([
      [node.current, { transform: [null, 'translateY(-100%) translateX(-50%)'] }, { duration: 1 }],
      [node.current, { opacity: [null, 0] }, { duration: 0.3, at: 0.7 }]
    ])

    controls.finished.then(() => {
      deleteFizz(id)
    })

    animationControls.current = controls
  }, [])

  return (
    <div
      ref={node}
      className='pointer-events-none absolute z-[55] -translate-x-1/2 rounded-md bg-surface-primary px-5 py-2 text-sm shadow-card'
      style={{ top: y + window.scrollY - 30, left: x + window.scrollX }}
    >
      {text}
    </div>
  )
}

const FizzRoot: FC = () => {
  const fizzNodes = useReactiveVar(fizzs)
  return (
    <Portal>
      {fizzNodes.map((fizz) => (
        <Fizz key={fizz.id} text={fizz.text} id={fizz.id} x={fizz.x} y={fizz.y} />
      ))}
    </Portal>
  )
}

export { showFizz }
export default FizzRoot
