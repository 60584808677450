import { FC, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as PlusIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as WarningIcon } from '../../../svg/ui/warning.svg'
import { RetroBonusKind, useDealForCommissionQuery, useDealRetrobonusesQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import Modal from '../../../components/Modal'
import CommissionRewardForm from './CommissionRewardForm'
import CommissionReward from './CommissionReward'
import useAccessCheck from '../../../hooks/useAccessCheck'
import CommissionRetrobonusForm from './CommissionRetrobonusForm'
import CommissionRetrobonus from './CommissionRetrobonus'
import dealFormOpenState from '../state'
import { Card } from '../../../components/Card.tsx'

const Commission: FC = () => {
  const { id } = useParams<'id'>()

  const { data, refetch } = useDealForCommissionQuery({
    variables: { id: `${id}` },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const deal = data?.deal
  const source = deal?.application?.source
  const dealSupplies = useNodes(deal?.dealSupplies?.edges)
  const agentCommissionRewards = useNodes(deal?.agentCommissionRewards?.edges)

  const { data: dealRetrobonusesData, refetch: refetchDealRetrobonuses } = useDealRetrobonusesQuery({
    variables: {
      deal: `${id}`
    },
    skip: !id,
    fetchPolicy: 'cache-and-network'
  })
  const dealRetrobonuses = useNodes(dealRetrobonusesData?.dealRetrobonuses?.edges)

  const hasAccessRewardAdd = useAccessCheck('deal.agent_commission.reward.add')
  const hasAccessRetrobonusAdd = useAccessCheck('deal.agent_commission.retrobonus.add')

  const [commissionRewardFormOpen, setCommissionRewardFormOpen] = useState(false)

  const [commissionRetrobonusFormOpen, setCommissionRetrobonusFormOpen] = useState(false)

  const hasRewards = !!agentCommissionRewards?.length
  const hasDealSupplies = !!dealSupplies?.filter(
    (dealSupply) =>
      dealSupply?.supplierCompany?.hasRetrobonus &&
      !dealRetrobonuses?.map((s) => s.dealSupply._id)?.includes(dealSupply._id)
  )?.length

  if (!data) return null

  return (
    <div className='flex flex-col gap-8'>
      <div>
        <h2 className='mb-5 font-display text-h200'>Агентское вознаграждение</h2>
        <Card>
          <div className='grid grid-cols-3 gap-6 p-5'>
            {hasRewards ? (
              agentCommissionRewards.map((reward) => (
                <CommissionReward
                  key={reward?._id}
                  rewardId={reward?._id}
                  dealId={deal?._id}
                  onDelete={() => refetch()}
                />
              ))
            ) : (
              <div className='col-span-1'>
                <div className='rounded-md bg-surface-primary pb-10 shadow-card'>
                  <div className='p-10'>
                    {source?.agentContractCompany?.shortWithOpf ? (
                      <>
                        <Link className='hover:text-base-red' to={`/sources/${source?._id}/bonuses`}>
                          <h2 className='font-display text-h200'>{source?.agentContractCompany?.shortWithOpf}</h2>
                        </Link>
                        <div className='mt-3'>
                          Источник <span className='text-labels-secondary'>{source?.name}</span>
                        </div>
                      </>
                    ) : (
                      <Link className='group hover:text-base-red' to={`/sources/${source?._id}/bonuses`}>
                        Источник <span className='text-labels-secondary group-hover:text-base-red'>{source?.name}</span>
                      </Link>
                    )}
                  </div>
                  {hasAccessRewardAdd && (
                    <div className='px-10'>
                      <button
                        onClick={() => setCommissionRewardFormOpen(true)}
                        className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-grayscale-250 hover:text-grayscale-200 disabled:hover:text-grayscale-250'
                      >
                        <PlusIcon />
                        Добавить комиссию
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>

      <div>
        <h2 className='mb-5 font-display text-h200'>Ретробонусы</h2>
        <Card>
          <div className='grid grid-cols-3 gap-6 p-5'>
            {deal?.retroBonusKind === RetroBonusKind.WithRetrobonus ? (
              <>
                {dealRetrobonuses?.map((dealRetrobonus, index) => (
                  <CommissionRetrobonus
                    key={dealRetrobonus?._id}
                    dealId={deal?._id as number}
                    retrobonusId={dealRetrobonus?._id}
                    retrobonusNumber={index + 1}
                    dealSupplyId={dealRetrobonus?.dealSupply?._id}
                    supplierCompanyId={dealRetrobonus?.dealSupply?.supplierCompany?._id}
                    supplierCompanyName={dealRetrobonus?.dealSupply?.supplierCompany?.company?.shortWithOpf}
                    rate={dealRetrobonus?.rate}
                    amount={dealRetrobonus?.amount}
                    status={dealRetrobonus?.status}
                    onDelete={() => {
                      refetchDealRetrobonuses()
                    }}
                  />
                ))}
                {hasAccessRetrobonusAdd && (
                  <div>
                    <button
                      disabled={!hasDealSupplies}
                      className='col-span-1 flex w-full cursor-pointer flex-col items-center justify-center rounded-md border-1 border-dashed border-grayscale-300 py-25 text-grayscale-200 disabled:cursor-not-allowed disabled:text-grayscale-250'
                      onClick={() => setCommissionRetrobonusFormOpen(true)}
                    >
                      {hasDealSupplies ? (
                        <>
                          <PlusIcon className='mb-7 h-15 w-15' />
                          Добавить ретробонус
                        </>
                      ) : (
                        <>
                          <WarningIcon className='mb-7 h-15 w-15' />
                          <span>В сделке нет поставщиков с ретробонусом</span>
                        </>
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className='col-span-3'>
                <div className='max-w-fit rounded-md bg-surface-primary px-12 py-8 shadow-card'>
                  Ищешь ретробонусы?{' '}
                  <button className='text-red-150 hover:text-red-100/70' onClick={() => dealFormOpenState(true)}>
                    Добавь
                  </button>{' '}
                  признак “Ретробонус” в сделке
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>

      <Modal open={commissionRewardFormOpen} setOpen={setCommissionRewardFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CommissionRewardForm
            sourceId={`${source?._id}`}
            sourceName={source?.name}
            dealId={`${id}`}
            dealAmount={deal?.amount / 100}
            commissionRewardRate={source?.commissionRewardRate}
            onDone={() => {
              setCommissionRewardFormOpen(false)
              refetch()
            }}
          />
        </div>
      </Modal>
      <Modal open={commissionRetrobonusFormOpen} setOpen={setCommissionRetrobonusFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CommissionRetrobonusForm
            dealId={`${deal?._id}`}
            onDone={() => {
              setCommissionRetrobonusFormOpen(false)
              refetchDealRetrobonuses()
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default Commission
