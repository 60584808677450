import { FC, Suspense } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { ScoringAnalysisStatus } from '../../../graphql/schema.tsx'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { ReactComponent as ChevronIcon } from './../../../svg/icons/chevron-fat.svg'
import { ReactComponent as InProgressIcon } from './../../../svg/icons/appScoringStatusInProgress.svg'
import { ReactComponent as PauseIcon } from './../../../svg/icons/appScoringStatusPause.svg'
import { ReactComponent as DoneIcon } from './../../../svg/icons/appScoringStatusDone.svg'
import { scoringStatusDict } from '../../../utils/dictionaries.ts'
import c from 'clsx'

const listStatuses: ScoringAnalysisStatus[] = Object.keys(scoringStatusDict) as ScoringAnalysisStatus[]

const statusIcons = {
  [ScoringAnalysisStatus.InProgress]: <InProgressIcon />,
  [ScoringAnalysisStatus.WaitingForAdditionalInfo]: <PauseIcon />,
  [ScoringAnalysisStatus.Done]: <DoneIcon />
}

interface ScoringStatusSwitcherProps {
  status?: string
  loading?: boolean
  onChangeStatus: (status: string) => void
}

interface ScoringStatusSwitcherInnerProps {
  status?: string
  changeStatus: (status: string) => void
}

const ScoringStatusSwitcherInner: FC<ScoringStatusSwitcherInnerProps> = ({ status, changeStatus }) => (
  <>
    {listStatuses?.map((s) => (
      <MenuItem key={s}>
        <div
          onClick={() => changeStatus(s)}
          className={c(
            'flex cursor-pointer items-center gap-4 px-8 py-5 text-grayscale-150 first:rounded-t-xl first:pt-9 last:rounded-b-xl last:pb-9 hover:bg-grayscale-400',
            s === status && 'pointer-events-none text-red-100'
          )}
        >
          {statusIcons[s]}
          {scoringStatusDict[s]}
        </div>
      </MenuItem>
    ))}
  </>
)

// wrap ScoringStatusSwitcherInner with suspense
const ScoringStatusSwitcher: FC<ScoringStatusSwitcherProps> = ({ status, loading, onChangeStatus }) => (
  <div className='relative'>
    <Menu>
      <MenuButton
        disabled={loading}
        className='flex h-16 items-center gap-4 rounded-md bg-surface-primary px-5 py-3 text-grayscale-150 shadow-card hover:bg-grayscale-450'
        onClick={(e) => e.stopPropagation()}
      >
        {status && statusIcons[status as ScoringAnalysisStatus]}
        {status && scoringStatusDict[status as ScoringAnalysisStatus]}
        {loading ? (
          <LoadingIcon className='w-7 animate-spin text-red-100' />
        ) : (
          <ChevronIcon className='transition-transform duration-200 ui-open:-rotate-180' />
        )}
      </MenuButton>

      <Suspense
        fallback={
          <MenuItems
            unmount={true}
            className='absolute mt-4 w-full rounded-xl border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
          >
            <div className='py-10'>
              <LoadingIcon className='mx-auto animate-spin text-red-100' />
            </div>
          </MenuItems>
        }
      >
        <Transition
          as='div'
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <MenuItems
            unmount={true}
            className='absolute z-10 mt-4 w-80 rounded-xl border-[1px] border-grayscale-400 bg-white-0'
          >
            <ScoringStatusSwitcherInner status={status} changeStatus={onChangeStatus} />
          </MenuItems>
        </Transition>
      </Suspense>
    </Menu>
  </div>
)

export default ScoringStatusSwitcher
