import { makeVar, ReactiveVar, InMemoryCache } from '@apollo/client'
import { StrictTypedTypePolicies, TaskConnection } from './schema'
import { relayStylePagination } from '@apollo/client/utilities'

/**
 * Updates localStorage on reactive var update
 */
const persistVar = (key: string, rVar: ReactiveVar<any>) => {
  const value = rVar()

  const onChange = (nextValue: any) => {
    if (nextValue) {
      localStorage.setItem(key, nextValue)
    } else {
      localStorage.removeItem(key)
    }
    rVar.onNextChange(onChange)
  }

  rVar.onNextChange(onChange)
  rVar(value)
}

export const jwtVar = makeVar<string>(localStorage.getItem('jwt') || '')
persistVar('jwt', jwtVar)
export const refreshTokenVar = makeVar<string>(localStorage.getItem('refreshToken') || '')
persistVar('refreshToken', refreshTokenVar)

const typePolicies: StrictTypedTypePolicies = {
  ReconciliationConnection: {
    merge: true
  },
  SupplierCompanyConnection: {
    merge: true
  },
  CustomerCompanyConnection: {
    merge: true
  },
  ApplicationConnection: {
    merge: true
  },
  BankOperationConnection: {
    merge: true
  },
  ContactConnection: {
    merge: true
  },
  DealConnection: {
    merge: true
  },
  SourceConnection: {
    merge: true
  },
  TaskConnection: {
    merge: true
  },
  DocumentConnection: {
    merge: true
  },
  BankDetailConnection: {
    merge: true
  },
  CompanyConnection: {
    merge: true
  },
  AtsConversationConnection: {
    merge: true
  },
  Query: {
    fields: {
      supplierCompanies: relayStylePagination(['inn']),
      contacts: relayStylePagination(['fio', 'phone', 'email', 'verificationStatus']),
      applications: relayStylePagination(['customerCompany_name', 'customerCompany_inn', 'source', 'id']),
      bankOperations: relayStylePagination(['filters', ['customerAccountId']]),
      customerCompanies: relayStylePagination(['name', 'inn']),
      companies: relayStylePagination(['name', 'inn']),
      deals: relayStylePagination(['dealSupplies_supplierCompany_id', 'application_source_id', 'customerCompany']),
      sources: relayStylePagination(['name']),
      documents: {
        keyArgs: ['filter', ['entityId', 'entityType', 'type']]
      },
      dealRetrobonuses: relayStylePagination(['deal', 'deal_list']),
      agentCommissionRewards: relayStylePagination(['deal', 'deal_list']),
      tasks: {
        keyArgs: ['input', ['userId', 'authorId', 'targetId', 'targetType', 'states']],
        merge(existing: TaskConnection, incoming: TaskConnection, { readField, args }) {
          if (!args?.after) {
            return incoming
          }
          const mergedTaskEdges = existing?.edges?.length ? existing?.edges.slice(0) : []
          const existingTaskIdSet = new Set(mergedTaskEdges.map((edge) => readField('id', edge?.node)))
          if (incoming?.edges?.length) {
            incoming.edges.forEach(
              (edge) => !existingTaskIdSet.has(readField('id', edge?.node)) && mergedTaskEdges.push(edge)
            )
          }

          return { ...incoming, edges: mergedTaskEdges }
        }
      },
      fundingSources: relayStylePagination(false),
      fundingTranches: relayStylePagination(['filters', ['dealId', 'fundingSourceId']]),
      transactions: relayStylePagination(['filters', ['dealId', 'customerAccountId']]),
      atsConversations: relayStylePagination(['filters', ['phones']]),

      // check if we have user in cache before fetching
      user: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'User',
            _id: args?.id || variables?.id
          })
          return ref
        }
      },
      application: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'Application',
            _id: args?.id || variables?.id
          })
          return ref
        }
      },
      deal: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'Deal',
            _id: args?.id || variables?.id
          })
          return ref
        }
      },
      reconciliation: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'Reconciliation',
            _id: args?.id || variables?.id
          })
          return ref
        }
      },
      // contact: {
      //   merge: true,
      //   read: (_, { args, variables, toReference }) => {
      //     const ref = toReference({
      //       __typename: 'Contact',
      //       _id: args?.id || variables?.id
      //     })
      //     return ref
      //   }
      // },
      contactInfo: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'ContactInfo',
            _id: args?.id || variables?.id
          })
          return ref
        }
      },
      dealSupply: {
        merge: true,
        read: (_, { args, variables, toReference }) => {
          const ref = toReference({
            __typename: 'DealSupply',
            _id: args?.id || variables?.id
          })
          return ref
        }
      }
    }
  },
  Contact: {
    fields: {
      applications: {
        keyArgs: false,
        merge: true
      }
    }
  },
  Application: {
    fields: {
      leasingSubjectCategories: {
        merge: true
      }
    }
  },
  CustomerCompany: {
    fields: {
      applications: relayStylePagination(false),
      deals: relayStylePagination(false)
      // bankDetails: relayStylePagination(false)
    }
  },
  Source: {
    fields: {
      applications: relayStylePagination(false)
    }
  },
  Document: {
    fields: {
      loading: {
        merge: true,
        read: (existing) => existing || false
      }
    }
  },
  DealSupply: {
    merge: true,
    fields: {
      // merge shipments for deal supply refetch
      shipments: {
        merge: true
      }
    }
  }
}

const cache = new InMemoryCache({ typePolicies })
export default cache
