import { FC } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  TagEntity,
  useAddTagToCompanyMutation,
  useCompanyByIdQuery,
  useRemoveTagFromCompanyMutation
} from '../../graphql/schema'
import CopyInn from '../../components/CopyInn'
import BoNalogLink from '../../components/BoNalogLink'
import {
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader'
import { EntityPage } from '../../components/EntityPage/EntityPage'
import Tags from '../../components/Tags'
import useNodes from '../../hooks/useNodes'

const Company: FC = () => {
  const { id } = useParams<'id'>()

  const { data, loading, refetch } = useCompanyByIdQuery({ variables: { id: `${id}` }, skip: !id })

  const company = data?.company

  const tags = useNodes(company?.tags?.edges)

  const [addTagToCompany] = useAddTagToCompanyMutation()
  const [removeTagFromCompany] = useRemoveTagFromCompanyMutation()

  const addTag = async (tagId: number) => {
    await addTagToCompany({
      variables: {
        input: {
          company: `${id}`,
          tag: `${tagId}`
        }
      }
    })
  }

  const removeTag = async (tagId: number) => {
    await removeTagFromCompany({
      variables: {
        input: {
          company: `${id}`,
          tag: `${tagId}`
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        removeTagFromCompany: {
          __typename: 'removeTagFromCompanyPayload',
          company: {
            __typename: 'Company',
            _id: parseInt(`${id}`),
            tags: {
              __typename: 'TagConnection',
              edges: tags
                .filter((tag) => tag._id !== tagId)
                .map((t) => ({
                  __typename: 'TagEdge',
                  cursor: '',
                  node: t
                }))
            }
          }
        }
      }
    })
  }

  if (!company) return null

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='mb-6 flex items-start justify-between'>
          <div>
            <EntityInfo>Компания</EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName value={company?.shortWithOpf} loading={loading} />
              <CopyInn inn={company?.inn} />
              {!tags.length && (
                <div className='ml-5'>
                  <Tags entityType={TagEntity.Company} tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />
                </div>
              )}
            </div>
            {!!tags.length && (
              <div className='mb-12'>
                <Tags entityType={TagEntity.Company} tags={tags} onAddTag={addTag} onRemoveTag={removeTag} />
              </div>
            )}
          </div>
          <div className='flex flex-none gap-x-4'>
            <BoNalogLink inn={company?.inn} />
          </div>
        </div>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Скоринг' to='scoring' />
          <EntityNavItem title='Документы' to='docs' />
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch]} />
      </EntityPage>
    </div>
  )
}

export default Company
