import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  TaskTargetType,
  useCompanyByIdQuery,
  useRefreshFromDadataCompanyMutation,
  useUpdateCompanyMutation,
  VatKind
} from '../../graphql/schema.tsx'
import { Address, CompanyData } from '../../types/dadata.ts'
import CompanyInfo from '../../components/CompanyInfo.tsx'
import Requisites from '../../components/Requisites/index.tsx'
import useNodes from '../../hooks/useNodes.ts'
import { Card, CardHeader, CardTitle } from '../../components/Card.tsx'
import { isIP } from '../../utils/contractUtils.ts'
import usePersonAddress from '../../hooks/usePersonAddress.ts'
import RadioButtonsGroup from '../../components/RadioButtonsGroup.tsx'
import Relations from './Relations.tsx'
import Tasks from '../../components/Tasks'

const Info: FC = () => {
  const { id } = useParams<'id'>()
  const { data, loading, refetch } = useCompanyByIdQuery({
    variables: { id: id?.toString() || '' },
    skip: !id
  })
  const company = data?.company
  const requisites = useNodes(company?.bankDetails?.edges) || []

  const dadata: CompanyData = company?.dadata ? company?.dadata.data : {}
  const address: Address | undefined = dadata?.address

  const IP: boolean = isIP(dadata)

  const IPfactAddress = usePersonAddress(company?.inn || '')

  const [refreshDadataMutation, { loading: loadingRefreshDadata }] = useRefreshFromDadataCompanyMutation()
  const [updateCompanyVAT] = useUpdateCompanyMutation()

  async function refreshDadata() {
    if (!company?.inn) return

    await refreshDadataMutation({ variables: { input: { inn: company.inn } } })
    await refetch()
  }

  const onVATChange = useCallback(
    async (vatKind: string) => {
      if (!company?._id) return

      await updateCompanyVAT({
        variables: {
          input: {
            id: `${company._id}`,
            vatKind: vatKind as VatKind
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCompany: {
            __typename: 'updateCompanyPayload',
            company: {
              __typename: 'Company',
              _id: company?._id,
              vatKind: vatKind as VatKind
            }
          }
        }
      })
    },
    [company?._id, updateCompanyVAT]
  )

  if (!company) return null

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-1 flex flex-col gap-6'>
        <CompanyInfo
          dadata={dadata}
          companyName={company?.dadata?.value}
          refreshDadata={refreshDadata}
          loading={loadingRefreshDadata || loading}
          lastUpdateName={
            company?.lastDadataRequestUser?.surname &&
            company?.lastDadataRequestUser?.name &&
            company.lastDadataRequestUser.surname + ' ' + company.lastDadataRequestUser.name
          }
          lastUpdateDate={company?.lastDadataRequestDate}
        />

        <Card>
          <CardHeader>
            <CardTitle>Адреса</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='w-70 rounded-l-xl p-6'>Юридический адрес</td>
                    <td className='rounded-r-xl p-6'>{IP ? IPfactAddress : address?.unrestricted_value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Tasks targetId={company?._id} targetType={TaskTargetType.Company} />

        <Relations />

        <Requisites targetId={company?._id} targetType='company' requisites={requisites} onDone={() => refetch()} />

        <Card>
          <CardHeader>
            <CardTitle>НДС</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary px-4 py-10 shadow-card'>
              <RadioButtonsGroup
                onChange={onVATChange}
                options={[
                  ['ОСНО', VatKind.Osno],
                  ['УСН', VatKind.Usn]
                ]}
                checkedValue={company?.vatKind}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default Info
