// https://www.diadoc.ru/docs/forms/upd/structure/148
import { NomenclatureType, SpecItem } from '../graphql/schema'
import JSZip from 'jszip'

interface CompanyInfo {
  inn?: string
  name?: string
  address?: string
}

interface ContractBase {
  name?: string
  number?: string
  date?: Date
}

export interface UPDInfo {
  documentType: ShipmentDocumentType
  documentDate?: Date
  documentId?: string

  totalCents?: number
  totalVATCents?: number

  /** Дата приёмки */
  date?: Date
  seller?: CompanyInfo
  sender?: CompanyInfo
  buyer?: CompanyInfo
  receiver?: CompanyInfo
  items: Partial<SpecItem>[]
  /** Основание передачи */
  base: ContractBase[]
}

const decoder = new TextDecoder('cp866') // cp866 is used for document names in our zip files for some reason

export enum ShipmentDocumentType {
  /** счет-фактура */
  INVOICE = 'СЧФ',
  /** универсальный передаточный документ */
  UPD = 'СЧФДОП',
  /** первичный документ, например, накладная или акт */
  NOMENCLATURE = 'ДОП'
}
export function convertDocumentTypeToNomenclatureType(type?: ShipmentDocumentType) {
  switch (type) {
    case ShipmentDocumentType.UPD:
      return NomenclatureType.Upd
    case ShipmentDocumentType.NOMENCLATURE:
      return NomenclatureType.Nomenclature
    case ShipmentDocumentType.INVOICE:
      return NomenclatureType.Invoice
    default:
      throw new Error(`Unsupported Type: ${type}`)
  }
}

function isShipmentDocumentType(value: string): value is ShipmentDocumentType {
  return Object.values(ShipmentDocumentType).includes(value as ShipmentDocumentType)
}

const getDocumentType = (xml: Document): string | undefined => {
  const info = xml.getElementsByTagName('Документ')[0]
  if (!info) return

  return info.getAttribute('Функция') || undefined
}

const getAddress = (element?: Element) => {
  if (!element) return
  // Адрес почему-то встречается в двух видах:
  // <АдрРФ Индекс="248017" КодРегион="40" Город="Калуга г" Улица="Азаровская ул., дом 18, пом.2, каб.19, этаж 3" />

  // <АдрИнф КодСтр="643" АдрТекст="117485, г. Москва, ул. Бутлерова, д. 7, эт. 2, пом. 34, ряд 2А, место 16" />
  // <АдрРФ Дом="28" Индекс="199406" Кварт="5" КодРегион="78" Корпус="к. 2" Улица="Беринга ул"/>

  const addrInfoText = element.getElementsByTagName('АдрИнф')[0]?.getAttribute('АдрТекст')
  if (addrInfoText) return addrInfoText

  const addrRF = element.getElementsByTagName('АдрРФ')[0]
  if (addrRF) {
    return [
      addrRF.getAttribute('Индекс'),
      addrRF.getAttribute('Город'),
      addrRF.getAttribute('НаселПункт'),
      addrRF.getAttribute('Улица'),
      addrRF.getAttribute('Дом'),
      addrRF.getAttribute('Корпус'),
      addrRF.getAttribute('Кварт')
    ]
      .filter(Boolean)
      .join(', ')
  }
}

const getParticipantInfo = (element: Element): CompanyInfo | undefined => {
  const company = element.getElementsByTagName('СвЮЛУч')[0]
  const individual = element.getElementsByTagName('СвИП')[0]
  const result: CompanyInfo = {}
  if (company) {
    result.inn = parseStringValue(company.getAttribute('ИННЮЛ'))
    result.name = parseStringValue(company.getAttribute('НаимОрг'))
  } else if (individual) {
    result.inn = parseStringValue(individual.getAttribute('ИННФЛ'))
    const fio = individual.getElementsByTagName('ФИО')[0]
    result.name = ['ИП', fio.getAttribute('Фамилия'), fio.getAttribute('Имя'), fio.getAttribute('Отчество')]
      .filter(Boolean)
      .join(' ')
  } else {
    return
  }
  result.address = getAddress(element.getElementsByTagName('Адрес')[0])
  return result
}

const getVAT = (element: Element) => {
  const tag = element.getElementsByTagName('СумНал')[0]
  const rawValue = tag.textContent
  if (rawValue) return Math.round((parseFloat(rawValue) || 0) * 100)
}

const getUnitName = (element: Element) => {
  const tag = element.getElementsByTagName('ДопСведТов')[0]
  if (!tag) return
  return tag.getAttribute('НаимЕдИзм') || element.getAttribute('НаимЕдИзм')
}

const parseCentsValue = (string: string | null | undefined) => {
  if (!string) return
  return Math.round(parseFloat(string) * 100)
}
const parseFloatValue = (string: string | null | undefined) => {
  if (!string) return
  return parseFloat(string)
}

const parseStringValue = (string: string | null | undefined) => {
  if (!string) return
  return string
}

const parseDateValue = (string: string | null | undefined) => {
  if (!string) return
  const [month, day, year] = string.split('.')
  return new Date(`${year}-${day}-${month}`)
}

const getInvoiceItems = (element: Element) => {
  const items = element.getElementsByTagName('СведТов')
  return Array.from(items).map((item) => {
    const totalPriceCents = parseCentsValue(item.getAttribute('СтТовУчНал'))
    const count = parseFloatValue(item.getAttribute('КолТов'))
    const pricePerUnitCentsWithoutVat = parseCentsValue(item.getAttribute('ЦенаТов'))

    let pricePerUnitCents: number | undefined = undefined
    if (totalPriceCents && count) {
      pricePerUnitCents = Math.round(totalPriceCents / count)
    }

    const result: Partial<SpecItem> = {
      VATCents: getVAT(item),
      count,
      name: item.getAttribute('НаимТов') || undefined,
      pricePerUnitCents,
      pricePerUnitCentsWithoutVat,
      totalPriceCents,
      unit: getUnitName(item) || undefined
    }
    return result
  })
}

// примеры оснований из разных документов:
// <ОснПер НаимОсн="Договор " НомОсн="ДП240312-02 " ДатаОсн="12.03.2024" />
// <ОснПер РеквНаимДок="Договор" РеквНомерДок="Г031224" РеквДатаДок="03.12.2024" />
const getBases = (element?: Element): ContractBase[] => {
  if (!element) return []
  const bases = element.getElementsByTagName('ОснПер')
  // <ОснПер НаимОсн="Спецификация 65 от 26.08.24 к Договору поставки" НомОсн="ДП230227-01" ДатаОсн="27.02.2023"/>
  return Array.from(bases).map((base) => {
    return {
      name: parseStringValue(base.getAttribute('НаимОсн')) || parseStringValue(base.getAttribute('РеквНаимДок')),
      number: parseStringValue(base.getAttribute('НомОсн')) || parseStringValue(base.getAttribute('РеквНомерДок')),
      date: parseDateValue(base.getAttribute('ДатаОсн')) || parseDateValue(base.getAttribute('РеквДатаДок'))
    }
  })
}

const getTotal = (element: Element) => {
  const tag = element.getElementsByTagName('ВсегоОпл')[0]
  if (!tag) return
  return parseCentsValue(tag.getAttribute('СтТовУчНалВсего'))
}

const getTotalVAT = (element: Element) => {
  const total = element.getElementsByTagName('ВсегоОпл')[0]
  const tag = total.getElementsByTagName('СумНал')[0]
  if (!tag) return
  return parseCentsValue(tag.textContent)
}

// Документ с КНД 1115132 содержит дату приёмки и ссылается на файл счета-фактуры или УПД
const isSignatureDocument = (doc: Document) => {
  // КНД может храниться в разных местах
  const optionA = doc.getElementsByTagName('ИнфПок')[0]?.getAttribute('КНД') === '1115132'
  const optionB = doc.getElementsByTagName('Документ')[0]?.getAttribute('КНД') === '1115132'

  return optionA || optionB
}

// Документ о передаче товара с КНД 1175011 содержит дату приёмки (для ТОРГ-12)
const isTransferDocument = (doc: Document) => {
  return doc.getElementsByTagName('Документ')[0]?.getAttribute('КНД') === '1175011'
}

const getDocName = (doc: Document) => {
  // Название может находиться в разных тэгах
  const elementA = doc.getElementsByTagName('ДокПодтвОтгр')[0]
  const elementB = doc.getElementsByTagName('ДокПодтвОтгрНом')[0]

  let optionA = parseStringValue(elementA?.getAttribute('НаимДокОтгр'))
  let optionB = parseStringValue(elementB?.getAttribute('РеквНаимДок'))

  if (elementA?.getAttribute('НомДокОтгр')) {
    optionA = optionA + ` №${elementA.getAttribute('НомДокОтгр')}`
  }
  if (elementB?.getAttribute('РеквНомерДок')) {
    optionB = optionB + ` №${elementB.getAttribute('РеквНомерДок')}`
  }

  if (elementA?.getAttribute('ДатаДокОтгр')) {
    optionA = optionA + ` от ${elementA.getAttribute('ДатаДокОтгр')}`
  }
  if (elementB?.getAttribute('РеквДатаДок')) {
    optionB = optionB + ` от ${elementB.getAttribute('РеквДатаДок')}`
  }

  return optionA || optionB
}

const getUPDInfo = (xml: Document): UPDInfo | undefined => {
  const info = xml.getElementsByTagName('СвСчФакт')[0]
  if (!info) return
  const documentType = getDocumentType(xml) as ShipmentDocumentType
  const documentDate = parseDateValue(info.getAttribute('ДатаСчФ') || info.getAttribute('ДатаДок'))
  const documentId = parseStringValue(info.getAttribute('НомерСчФ') || info.getAttribute('НомерДок'))

  const seller = getParticipantInfo(info.getElementsByTagName('СвПрод')[0])
  const sender = getParticipantInfo(info.getElementsByTagName('ГрузОт')[0])
  const buyer = getParticipantInfo(info.getElementsByTagName('СвПокуп')[0])
  const receiver = getParticipantInfo(info.getElementsByTagName('ГрузПолуч')[0])

  const nomenclature = xml.getElementsByTagName('ТаблСчФакт')[0]
  const items = getInvoiceItems(nomenclature)
  const totalCents = getTotal(nomenclature)
  const totalVATCents = getTotalVAT(nomenclature)

  const baseInfo = xml.getElementsByTagName('СвПродПер')[0]
  const base = getBases(baseInfo)

  return {
    documentType,
    documentDate,
    documentId,
    // date берется из подписанного документа
    totalCents,
    totalVATCents,
    seller,
    sender,
    buyer,
    receiver,
    items,
    base
  }
}

/** some XML files come in exotic encodings like windows-1251 */
async function decode(file: ArrayBuffer) {
  // Read the first 128 bytes to get the initial content of the file
  const arrayBuffer = await file.slice(0, 128)
  const content = new TextDecoder('utf-8').decode(arrayBuffer)

  // Extract the first line (split by line breaks)
  const firstLine = content.split(/\r?\n/)[0]
  const encodingMatch = firstLine.match(/encoding=["']([\w-]+)["']/i)

  if (!encodingMatch || !encodingMatch[1]) return

  const encoding = encodingMatch[1].toLowerCase()

  return new TextDecoder(encoding).decode(file)
}

async function updFromZip(file: File): Promise<{ upd: UPDInfo; files: File[] } | undefined> {
  const zip = await JSZip.loadAsync(file, {
    decodeFileName: (bytes) => {
      let byteArray

      if (Array.isArray(bytes)) {
        // bytes is a string[], each element a single character
        byteArray = new Uint8Array(bytes.map((ch) => ch.charCodeAt(0)))
      } else if (bytes instanceof Uint8Array) {
        // already Uint8Array
        byteArray = bytes
      } else if (Buffer.isBuffer(bytes)) {
        // Node.js Buffer, convert to Uint8Array
        byteArray = new Uint8Array(bytes)
      } else {
        throw new Error('Unsupported filename byte type.')
      }

      return decoder.decode(byteArray)
    }
  })

  const xmls = Object.values(zip.files).filter((file) => file.name.endsWith('.xml'))
  const pdfs = Object.values(zip.files).filter((file) => file.name.endsWith('.pdf'))
  const signatures: Document[] = []
  const toUpload: File[] = []
  let transfer: Document | undefined

  const files: Partial<Record<ShipmentDocumentType, { doc: Document; file: JSZip.JSZipObject }>> = {}

  // check all XML files for UPD and INVOICE/NOMENCLATURE
  await Promise.all(
    xmls.map(async (xml) => {
      const content = await xml.async('arraybuffer')
      const text = await decode(content)
      if (!text) return
      const doc = new DOMParser().parseFromString(text, 'text/xml')

      // save signature documents to get shipment date later
      if (isSignatureDocument(doc)) {
        signatures.push(doc)
        return
      }

      if (!transfer && isTransferDocument(doc)) {
        transfer = doc
      }

      const docType = getDocumentType(doc)

      if (!docType) return

      // if doctype not in enum
      if (!isShipmentDocumentType(docType)) {
        console.warn('Unknown document type:', docType, xml.name)
        return
      }

      if (files[docType]) {
        console.warn('Multiple files found for', docType, xml.name)
        return
      }

      // save useful XML files for later upload
      toUpload.push(new File([content], xml.name))

      files[docType] = { doc, file: xml }
    })
  )

  let parsedInfo: UPDInfo | undefined
  let fileName: string | undefined
  let pdfFileName: string | undefined
  // parse UPD or INVOICE/NOMENCLATURE for preview
  if (files[ShipmentDocumentType.UPD]) {
    parsedInfo = getUPDInfo(files[ShipmentDocumentType.UPD].doc)
    fileName = files[ShipmentDocumentType.UPD].file.name
    pdfFileName = getDocName(files[ShipmentDocumentType.UPD].doc)
  } else if (files[ShipmentDocumentType.NOMENCLATURE]) {
    parsedInfo = getUPDInfo(files[ShipmentDocumentType.NOMENCLATURE].doc)
    fileName = files[ShipmentDocumentType.NOMENCLATURE].file.name
    pdfFileName = getDocName(files[ShipmentDocumentType.NOMENCLATURE].doc)
  } else if (files[ShipmentDocumentType.INVOICE]) {
    parsedInfo = getUPDInfo(files[ShipmentDocumentType.INVOICE].doc)
    fileName = files[ShipmentDocumentType.INVOICE].file.name
    pdfFileName = getDocName(files[ShipmentDocumentType.INVOICE].doc)
  }

  if (!parsedInfo) return

  // Ищем дату приёмки в подписанных документах
  // regex to extract the filename without the extension and path
  const fileId = fileName?.match(/[^/]+(?=\.\w+$)/)?.[0]
  if (fileId) {
    // looking for a signature document that references the main file
    const signature = signatures.find((doc) => {
      return doc.getElementsByTagName('ИдИнфПрод')[0]?.getAttribute('ИдФайлИнфПр')?.includes(fileId)
    })
    if (signature) {
      const date = parseDateValue(signature.getElementsByTagName('СвПрин')[0]?.getAttribute('ДатаПрин'))
      if (date) {
        parsedInfo.date = date
      }
    } else if (transfer) {
      const date = parseDateValue(transfer.getElementsByTagName('ГрузПолучил')[0]?.getAttribute('ДатаПолуч'))
      if (date) {
        parsedInfo.date = date
      }
    }
  }

  // save all PDF files for later upload
  await Promise.all(
    pdfs.map(async (pdf) => {
      const content = await pdf.async('blob')

      let name = pdf.name.split('/').pop() || 'unnamed.pdf'
      if (pdfFileName && fileId && name.includes(fileId)) {
        name = `${pdfFileName}.pdf`
      }

      toUpload.push(new File([content], name))
    })
  )

  return {
    upd: parsedInfo,
    files: toUpload
  }
}

export { getDocumentType, getUPDInfo, decode, isShipmentDocumentType, updFromZip }
