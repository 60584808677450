import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as EditIcon } from '../../../../svg/icons/edit.svg'
import SubmitButton from '../../../../components/SubmitButton'
import { UserRoles, useUpdateLegalCollectionMutation } from '../../../../graphql/schema.tsx'
import ManagerInput from '../../../../components/Forms/Inputs/ManagerInput.tsx'

type Inputs = {
  legalUserId: number
}

interface LegalCollectionUserFormProps {
  collectionId?: string
  userId?: number
  onDone: () => void
}

const LegalCollectionUserForm: FC<LegalCollectionUserFormProps> = ({ collectionId, userId, onDone }) => {
  const [updateCollection, { loading }] = useUpdateLegalCollectionMutation()

  const { handleSubmit, control, formState } = useForm<Inputs>({
    defaultValues: {
      legalUserId: userId
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!collectionId || !data.legalUserId) return
    await updateCollection({
      variables: {
        input: {
          id: collectionId,
          legalUserId: `${data.legalUserId}`
        }
      }
    })
    onDone()
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Смена ответственного</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Controller
            name='legalUserId'
            control={control}
            render={({ field }) => (
              <ManagerInput
                selected={field.value ? [field.value] : []}
                onChange={(selected) =>
                  selected.length ? field.onChange(selected[selected.length - 1]) : field.onChange(null)
                }
                label='Менеджер'
                placeholder='Выберите менеджера'
                error={formState.errors?.legalUserId}
                role={UserRoles.RoleLegal}
              />
            )}
          />
        </div>
        <SubmitButton loading={loading}>
          <EditIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default LegalCollectionUserForm
