import { useMemo } from 'react'
import { makeVar, useReactiveVar } from '@apollo/client'
import { CompaniesListQueryVariables } from '../../graphql/schema'

const companyQueryVar = makeVar<string>('')

export const useCompanyFilter = () => {
  const query = useReactiveVar(companyQueryVar)

  const variables = useMemo<CompaniesListQueryVariables>((): CompaniesListQueryVariables => {
    return {
      query
    }
  }, [query])

  return {
    variables,
    query,
    setQuery: companyQueryVar
  }
}
