import { FC, useCallback, useMemo, useState } from 'react'
import { dateFormatter } from '../../../utils/dateFormatter.ts'
import { formatDecimal, formatMoneyDecimal } from '../../../utils/formatNumber.ts'
import {
  CustomerAccountKind,
  DealInsuranceKind,
  DealStatus,
  Kind,
  PaymentKind,
  PaymentsQuery,
  PaymentState,
  PaymentTargetType,
  useCancelPaymentTransactionsMutation,
  useCreatePaymentMutation,
  useCustomerAccountQuery,
  useDealByIdQuery,
  useDealCalculationsQuery,
  useDealPaymentSchedulesQuery,
  useDealPenaltyAmountLazyQuery,
  useDeletePaymentMutation,
  usePaymentsQuery,
  usePayPaymentMutation,
  UserRoles,
  useDeletePaymentScheduleMutation,
  useCreateBasePaymentsMutation
} from '../../../graphql/schema.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import Modal from '../../../components/Modal.tsx'
import PaymentForm from './PaymentForm.tsx'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { NodeType } from '../../../types'
import ConfirmationForm from '../../../components/ConfirmationForm.tsx'
import { REGULAR_VAT_RATE } from '../../../utils/constants.ts'
import PaymentActions, { PaymentActionsIcon, PaymentActionType } from './PaymentActions.tsx'
import { ReactComponent as AddIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as CompareIcon } from '../../../svg/ui/compare.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import Dropdown from '../../../components/Dropdown'
import SuspenseModal from '../../../components/SuspenseModal.tsx'
import TransactionsDateForm from './TransactionsDateForm.tsx'
import PenaltyForm from './PenaltyForm.tsx'
import PaymentSchedulesCompare from './PaymentSchedulesCompare.tsx'
import usePaymentsAccessCheck from './usePaymentsAccessCheck.ts'
import parseDecimal from '../../../utils/parseDecimal.ts'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'
import { Card, CardAccentButton, CardHeader, CardMenu, CardTitle } from '../../../components/Card.tsx'
import ScheduleForm from './ScheduleForm.tsx'
import Tooltip from '../../../components/Tooltip.tsx'
import { BASE_PAYMENT_KINDS, LEASING_PAYMENT_KINDS } from '../../../utils/payment.ts'
import PaymentScheduleTitleTooltip from './PaymentScheduleTitleTooltip.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import PaymentScheduleDateForm from './PaymentScheduleDateForm.tsx'
import PaymentScheduleSaveBtn from './PaymentScheduleSaveBtn.tsx'

interface PaymentsProps {
  dealId: number
}

export type Payment = NodeType<PaymentsQuery['payments']> & { penaltyPayments?: Payment[] }

const Payments: FC<PaymentsProps> = ({ dealId }) => {
  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.roles.includes(UserRoles.RoleAdmin)
  const isQCMS = currentUser?.roles.includes(UserRoles.RoleQualityControlSupportManager)
  const [scheduleToCompareId, setScheduleToCompareId] = useState<string | undefined>()

  const { data: dealData } = useDealByIdQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const deal = dealData?.deal

  const {
    canPaymentsManage,
    canServicePaymentsCreate,
    canDebtTransferServicePaymentsCreate,
    canPenaltyForTerminationPaymentsCreate,
    canCompensationPaymentsCreate,
    canInsurancePaymentsCreate,
    canPenaltyPaymentsCreate,
    canPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate,
    canPayPayment,
    canFeePaymentsCreate
  } = usePaymentsAccessCheck(deal?.status)

  const { data: paymentSchedulesData, refetch: refetchPaymentSchedules } = useDealPaymentSchedulesQuery({
    variables: { dealId: dealId.toString() }
  })
  const [deletePaymentSchedule] = useDeletePaymentScheduleMutation()
  const canDeletePaymentSchedule = useAccessCheck('deal.payment_schedule.delete')

  const paymentSchedules = paymentSchedulesData?.paymentSchedules || []
  const actualSchedule = paymentSchedules[0]

  const { data: mainCustomerAccountData, refetch: refetchMainCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Main },
    skip: !deal?.customerCompany?._id
  })
  const mainCustomerAccount = mainCustomerAccountData?.customerAccount

  const { data: penaltyCustomerAccountData, refetch: refetchPenaltyCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Penalty },
    skip: !deal?.customerCompany?._id
  })
  const penaltyCustomerAccount = penaltyCustomerAccountData?.customerAccount

  const insuranceRate = deal?.insuranceAmount ? Math.round((deal?.insuranceAmount / deal?.amount) * 10000) / 100 : 0

  const { data: dealCalculationsData } = useDealCalculationsQuery({
    variables: {
      dealParams: {
        amount: deal?.amount / 100 || 0,
        advanceRate: deal?.advanceRate || 0,
        interestRate: deal?.interestRate || 0,
        durationMonths: deal?.durationMonths || 0,
        comissionRate: deal?.comissionRate || 0,
        insuranceRate: deal?.insuranceKind === DealInsuranceKind.WithoutInsurance ? 0 : insuranceRate,
        vatRate: deal?.kind === Kind.Medicine ? 0 : REGULAR_VAT_RATE,
        advancePaymentDate: deal?.advancePaymentDate || undefined
      }
    },
    skip:
      !deal ||
      deal.amount === null ||
      deal.advanceRate === null ||
      deal.durationMonths === null ||
      deal.comissionRate === null ||
      deal.advancePaymentDate === null
  })
  const dealCalculations = dealCalculationsData?.dealCalculations

  const {
    data: basePaymentsData,
    refetch: refetchBasePayments,
    loading: basePaymentsLoading,
    error: basePaymentsError
  } = usePaymentsQuery({
    variables: {
      kinds: BASE_PAYMENT_KINDS,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const basePayments = useNodes(basePaymentsData?.payments.edges)
  const flatBasePayments = useMemo(
    () => basePayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [basePayments]
  )

  const kinds: PaymentKind[] = useMemo(() => {
    if (canPaymentsManage) {
      const baseKinds = [PaymentKind.Commission].filter(
        (kind) => !basePayments.find((payment) => payment.kind === kind)
      )

      const canCreateManyPaymentsKinds = [
        PaymentKind.Service,
        PaymentKind.Compensation,
        PaymentKind.DebtTransferService,
        PaymentKind.PenaltyForTermination,
        PaymentKind.Insurance,
        PaymentKind.Fee
      ]

      return !baseKinds.length ? canCreateManyPaymentsKinds : [...baseKinds, ...canCreateManyPaymentsKinds]
    }

    const payments = []
    if (canServicePaymentsCreate) {
      payments.push(PaymentKind.Service)
    }
    if (canCompensationPaymentsCreate) {
      payments.push(PaymentKind.Compensation)
    }
    if (canDebtTransferServicePaymentsCreate) {
      payments.push(PaymentKind.DebtTransferService)
    }
    if (canPenaltyForTerminationPaymentsCreate) {
      payments.push(PaymentKind.PenaltyForTermination)
    }
    if (canInsurancePaymentsCreate) {
      payments.push(PaymentKind.Insurance)
    }
    if (canFeePaymentsCreate) {
      payments.push(PaymentKind.Fee)
    }
    return payments
  }, [
    canPaymentsManage,
    canServicePaymentsCreate,
    canDebtTransferServicePaymentsCreate,
    canPenaltyForTerminationPaymentsCreate,
    canCompensationPaymentsCreate,
    canInsurancePaymentsCreate,
    basePayments,
    canFeePaymentsCreate
  ])

  const {
    data: paymentsScheduleData,
    refetch: refetchPaymentsSchedule,
    loading: paymentsScheduleLoading,
    error: paymentsScheduleError
  } = usePaymentsQuery({
    variables: {
      kinds: LEASING_PAYMENT_KINDS,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })
  const paymentsSchedule = useNodes(paymentsScheduleData?.payments.edges)
  const flatPaymentsSchedule = useMemo(
    () => paymentsSchedule.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [paymentsSchedule]
  )
  const leasingPayments = paymentsSchedule.filter((p) => p.kind === PaymentKind.Leasing)

  const [createBasePayments, { loading: creatingBasePaymentsLoading }] = useCreateBasePaymentsMutation()

  // для создания пеней автоматически, без открытия формы
  const [createPayment] = useCreatePaymentMutation()

  const [penaltyForDeal] = useDealPenaltyAmountLazyQuery()

  const [deletePayment] = useDeletePaymentMutation()

  const [payPaymentMutation] = usePayPaymentMutation()

  const [deletionFormOpenByPaymentId, setDeletionFormOpenByPaymentId] = useState<string>()
  const [basePaymentFormOpen, setBasePaymentFormOpen] = useState(false)
  const [penaltyFormOpenByPaymentId, setPenaltyFormOpenByPaymentId] = useState<string>()
  const [paymentScheduleDeletionFormOpenById, setPaymentScheduleDeletionFormOpenById] = useState<string>()
  const [editTransactionsFormOpenByPaymentId, setEditTransactionsFormOpenByPaymentId] = useState<string>()
  const [penaltyCalculationLoading, setPenaltyCalculationLoading] = useState(false)
  const [scheduleFormOpen, setScheduleFormOpen] = useState(false)
  const [editPaymentScheduleDateFormOpenById, setEditPaymentScheduleDateFormOpenById] = useState<string>()

  const readyPaymentsCreate = Boolean(
    deal?.contractNumber &&
      deal?.contractDate &&
      deal?.advancePaymentDate &&
      deal?.supplyingStatus &&
      deal?.amount >= 0 &&
      deal?.durationMonths >= 0 &&
      deal?.advanceRate >= 0 &&
      deal?.interestRate >= 0 &&
      deal?.comissionRate >= 0 &&
      ((deal?.insuranceKind === DealInsuranceKind.WithInsurance && deal.insuranceAmount >= 0) ||
        deal?.insuranceKind === DealInsuranceKind.WithoutInsurance) &&
      deal?.vatRate >= 0 &&
      deal?.leasingSubjectCategories?.edges?.length &&
      deal?.status &&
      deal?.kind &&
      deal?.user
  )

  const statusAllowsPaymentsScheduleCreation =
    deal?.status === DealStatus.Signing || // на этом статусе можно создавать всем
    deal?.status === DealStatus.Activation || // на этом только админам
    deal?.status === DealStatus.PaymentReception || // на этом только админам
    deal?.status === DealStatus.Terminated || // на этом только админам
    deal?.status === DealStatus.Closed // на этом только админам

  const userAllowedToCreatePaymentsSchedule =
    (deal?.status === DealStatus.Signing && (isAdmin || isQCMS || !paymentsSchedule.length)) || // на этом статусе можно создавать всем, если нет платежей
    (deal?.status === DealStatus.Activation && (isAdmin || isQCMS)) || // на этом только админам и качеству
    (deal?.status === DealStatus.PaymentReception && (isAdmin || isQCMS)) || // на этом только админам и качеству
    (deal?.status === DealStatus.Terminated && isAdmin) || // на этом только админам
    (deal?.status === DealStatus.Closed && isAdmin) // на этом только админам

  const [cancelPaymentTransactions] = useCancelPaymentTransactionsMutation({
    onCompleted: () => {
      refetchMainCustomerAccount()
      refetchPenaltyCustomerAccount()
    }
  })

  const cancelPaymentTransactionsCallback = useCallback(
    async (paymentId: string) => {
      await cancelPaymentTransactions({
        variables: {
          input: {
            paymentId
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cancelPaymentTransactions: {
            __typename: 'CancelPaymentTransactionsPayload',
            payment: {
              __typename: 'Payment',
              state: PaymentState.Pending,
              id: paymentId
            }
          }
        }
      })
    },
    [cancelPaymentTransactions]
  )

  const payPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: mainCustomerAccount?.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchBasePayments(), refetchPaymentsSchedule(), refetchMainCustomerAccount()])
    },
    [
      mainCustomerAccount?.id,
      payPaymentMutation,
      refetchBasePayments,
      refetchMainCustomerAccount,
      refetchPaymentsSchedule
    ]
  )

  const payPenaltyPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !penaltyCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: penaltyCustomerAccount.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchPaymentsSchedule(), refetchPenaltyCustomerAccount()])
    },
    [penaltyCustomerAccount?.id, payPaymentMutation, refetchPenaltyCustomerAccount, refetchPaymentsSchedule]
  )

  const recountPenalty = useCallback(
    async (payment: { id: string; targetId: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      try {
        setPenaltyCalculationLoading(true)

        const { data: newPenaltyAmount } = await penaltyForDeal({ variables: { paymentId: payment.targetId } })

        await deletePayment({
          variables: {
            input: {
              id: payment.id
            }
          }
        })

        await createPayment({
          variables: {
            input: {
              amount: parseDecimal((newPenaltyAmount?.penaltyAmountForPayment.amount || '').toString()),
              date: new Date(),
              kind: PaymentKind.Penalty,
              targetId: payment.targetId,
              targetType: PaymentTargetType.Payment
            }
          }
        })

        await Promise.allSettled([refetchBasePayments(), refetchPaymentsSchedule()])
      } catch (e) {
        console.error(e)
      } finally {
        setPenaltyCalculationLoading(false)
      }
    },
    [
      mainCustomerAccount?.id,
      penaltyForDeal,
      deletePayment,
      createPayment,
      refetchBasePayments,
      refetchPaymentsSchedule
    ]
  )

  const canPaySchedulePaymentId = paymentsSchedule.find(
    (payment) => payment.kind !== PaymentKind.Penalty && payment.state !== PaymentState.Paid
  )?.id

  const basePaymentsConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment-base',
      columns: [
        { title: 'Назначение', value: (p) => paymentKindDict[p.kind] },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)), numeric: true },
        {
          title: 'Итого, ₽',
          value: (p) => (
            <div className='flex w-full justify-between gap-x-16'>
              {formatDecimal(p.amount * 100)}
              <PaymentActionsIcon payment={p} />
            </div>
          ),
          numeric: true
        },
        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage || canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p.penaltyPayments.length)}
                      canPaymentsManage={canPaymentsManage}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment}
                      canPaymentDelete={true}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p.id)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
    }),
    [canPaymentsManage, canPaymentsEditTransactionsDate, canPayPayment, cancelPaymentTransactionsCallback, payPayment]
  )

  const paymentsScheduleConfig = useMemo<GridViewConfig<Payment>>(
    () => ({
      grid: 'grid-cols-payment',
      columns: [
        {
          title: 'Назначение',
          value: (p) => {
            if (p.kind !== PaymentKind.Leasing) {
              return paymentKindDict[p.kind]
            }
            return (
              <>
                Лизинговый №
                <span className='font-mono'>{leasingPayments.findIndex((payment) => payment.id === p.id) + 1}</span>
              </>
            )
          }
        },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)), numeric: true },
        {
          title: 'Итого, ₽',
          value: (p) => (
            <div className='flex w-full justify-between gap-x-16'>
              {p.fee || p.body ? (
                <Tooltip target={<div>{formatDecimal(p.amount * 100)}</div>}>
                  {typeof p.body === 'number' && <p>Тело: {formatMoneyDecimal(p.body)}</p>}
                  {typeof p.fee === 'number' && <p>Проценты: {formatMoneyDecimal(p.fee)}</p>}
                </Tooltip>
              ) : (
                <div>{formatDecimal(p.amount * 100)}</div>
              )}
            </div>
          ),
          numeric: true
        },
        {
          title: 'Выкупная стоимость, ₽',
          value: (p) => {
            return (
              <div className='flex w-full justify-between gap-x-16'>
                {p.redemption ? formatDecimal(p.redemption * 100) : '-'}
                <PaymentActionsIcon payment={p} />
              </div>
            )
          },
          numeric: true
        },
        {
          value: (p) => {
            return (
              <div className='flex items-center justify-end gap-8'>
                {(canPaymentsManage ||
                  canPenaltyPaymentsCreate ||
                  canPenaltyPaymentsDelete ||
                  canPaymentsEditTransactionsDate) && (
                  <Dropdown className='empty:hidden'>
                    <PaymentActions
                      payment={p}
                      hasPenalties={Boolean(p?.penaltyPayments?.length)}
                      canPaymentsManage={canPaymentsManage}
                      canPenaltyCreate={canPenaltyPaymentsCreate}
                      canPenaltyDelete={canPenaltyPaymentsDelete}
                      canEditTransactionsDate={canPaymentsEditTransactionsDate}
                      canPayPayment={canPayPayment && p.id === canPaySchedulePaymentId}
                      triggerAction={async (type) => {
                        switch (type) {
                          case PaymentActionType.CancelPaymentTransactions:
                            await cancelPaymentTransactionsCallback(p.id)
                            break
                          case PaymentActionType.EditPaymentTransactions:
                            setEditTransactionsFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenPenaltyModal:
                            setPenaltyFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.OpenDeletingEntityModal:
                            setDeletionFormOpenByPaymentId(p.id)
                            break
                          case PaymentActionType.PayPenaltyPayment:
                            await payPenaltyPayment(p)
                            break
                          case PaymentActionType.PayPayment:
                            await payPayment(p)
                            break
                          case PaymentActionType.RecountPenalty:
                            await recountPenalty(p)
                            break
                        }
                      }}
                    />
                  </Dropdown>
                )}
              </div>
            )
          }
        }
      ]
    }),
    [
      leasingPayments,
      canPaymentsManage,
      canPenaltyPaymentsCreate,
      canPenaltyPaymentsDelete,
      canPaymentsEditTransactionsDate,
      canPayPayment,
      canPaySchedulePaymentId,
      cancelPaymentTransactionsCallback,
      payPenaltyPayment,
      payPayment,
      recountPenalty
    ]
  )

  async function handleCreateBasePayments() {
    await createBasePayments({
      variables: {
        input: {
          dealId: `${dealId}`
        }
      }
    })
    refetchBasePayments()
  }

  if (!deal) return null

  return (
    <div className='flex flex-col gap-6'>
      {/* Если таблица платежей в процессе загрузки, или она загружена и имеет хотя бы один платеж, выводится таблица */}
      <Card>
        <CardHeader>
          <CardTitle>Платежи</CardTitle>

          {!basePaymentsLoading && (
            <CardMenu>
              {basePayments.length === 0 && deal?.status !== DealStatus.Signing ? (
                <div className='text-labels-tertiary'>На данном этапе нельзя создать платежи</div>
              ) : !readyPaymentsCreate ? (
                <div className='text-labels-tertiary'>Недостаточно данных для создания платежей</div>
              ) : (
                (canPaymentsManage || canServicePaymentsCreate) &&
                (basePayments.length === 0 ? (
                  <CardAccentButton disabled={creatingBasePaymentsLoading} onClick={handleCreateBasePayments}>
                    <AddIcon />
                    Создать платежи
                  </CardAccentButton>
                ) : (
                  <CardAccentButton onClick={() => setBasePaymentFormOpen(true)}>
                    <AddIcon />
                    Добавить платеж
                  </CardAccentButton>
                ))
              )}
            </CardMenu>
          )}
        </CardHeader>
        {flatBasePayments?.length > 0 && (
          <div className='px-5 pb-5'>
            <GridView
              data={flatBasePayments}
              config={basePaymentsConfig}
              loading={basePaymentsLoading}
              error={basePaymentsError?.message}
            />
          </div>
        )}
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>
            {!actualSchedule ? (
              'График платежей'
            ) : (
              <PaymentScheduleTitleTooltip
                target={
                  <button
                    type='button'
                    className='group flex items-center'
                    onClick={() => setEditPaymentScheduleDateFormOpenById(actualSchedule.id)}
                  >
                    <div>
                      График платежей № {paymentSchedules.length}{' '}
                      {actualSchedule.documentDate
                        ? `от ${dateFormatter.format(new Date(actualSchedule.documentDate))}`
                        : '(Дата не указана)'}
                    </div>
                    <EditIcon
                      className='invisible ml-4 text-labels-secondary group-hover:visible'
                      title='Редактировать дату документа'
                    />
                  </button>
                }
                userId={actualSchedule.createdByUserId}
                date={actualSchedule.createdAt}
              />
            )}
          </CardTitle>

          <CardMenu>
            {!!flatPaymentsSchedule?.length && <PaymentScheduleSaveBtn className='mr-8' dealId={dealId.toString()} />}
            {userAllowedToCreatePaymentsSchedule &&
              (!statusAllowsPaymentsScheduleCreation ? (
                <div className='text-labels-tertiary'>На данном этапе нельзя создать график</div>
              ) : !readyPaymentsCreate ? (
                <div className='text-labels-tertiary'>Недостаточно данных для создания графика</div>
              ) : (
                <>
                  <CardAccentButton onClick={() => setScheduleFormOpen(true)}>
                    <AddIcon />
                    Создать {!!paymentsSchedule?.length && 'новый'} график
                  </CardAccentButton>
                </>
              ))}
          </CardMenu>
        </CardHeader>
        <div className='pb-5 empty:pb-0'>
          {paymentsSchedule?.length > 0 && (
            <div className='px-5'>
              <GridView
                data={flatPaymentsSchedule}
                config={paymentsScheduleConfig}
                loading={paymentsScheduleLoading || penaltyCalculationLoading}
                error={paymentsScheduleError?.message}
              />
            </div>
          )}
          {paymentSchedules.length > 1 && (
            <div>
              <CardHeader>
                <CardTitle>Архивные графики</CardTitle>
              </CardHeader>
              <div className='px-5'>
                {paymentSchedules.map((p, i) => {
                  if (i === 0) return null
                  return (
                    <div
                      key={p.id}
                      className='flex h-full w-full cursor-pointer items-center bg-surface-primary px-7 text-left text-labels-secondary shadow-card first:rounded-t-md last:rounded-b-md hover:shadow-card-hover active:bg-surface-secondary disabled:cursor-not-allowed disabled:text-labels-tertiary'
                    >
                      <button
                        type='button'
                        className='flex w-full items-center py-4 text-left hover:text-labels-primary'
                        onClick={() => setScheduleToCompareId(p.id)}
                      >
                        <PaymentScheduleTitleTooltip
                          target={`График № ${paymentSchedules.length - i} ${p.documentDate ? `от ${dateFormatter.format(new Date(p.documentDate))}` : '(Дата не указана)'}`}
                          userId={p.createdByUserId}
                          date={p.createdAt}
                        />
                        <CompareIcon className='ml-auto' />
                      </button>
                      <div className='ml-8 flex h-[36px] items-center border-l-1 border-separators-secondary pl-7'>
                        <Dropdown>
                          <>
                            <button
                              type='button'
                              className='flex items-center py-5 pl-5 pr-10 text-labels-tertiary hover:text-base-red'
                              onClick={() => setEditPaymentScheduleDateFormOpenById(p.id)}
                            >
                              <EditIcon className='mr-8' title='Редактировать дату документа' />
                              <div className='whitespace-nowrap'>Редактировать дату документа</div>
                            </button>
                            {canDeletePaymentSchedule && (
                              <button
                                type='button'
                                className='flex w-full items-center py-5 pl-5 pr-10 text-labels-tertiary hover:text-base-red'
                                onClick={() => setPaymentScheduleDeletionFormOpenById(p.id)}
                              >
                                <TrashIcon className='mr-8' />
                                <div className='whitespace-nowrap'>Удалить версию</div>
                              </button>
                            )}
                          </>
                        </Dropdown>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </Card>

      <Modal open={basePaymentFormOpen} setOpen={setBasePaymentFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentForm
            dealId={dealId}
            onDone={() => {
              refetchBasePayments()
              setBasePaymentFormOpen(false)
            }}
            kinds={kinds}
            advanceAmount={dealCalculations?.advanceAmount}
            commissionAmount={dealCalculations?.comissionAmount}
            insuranceAmount={parseFloat(deal.insuranceAmount)}
          />
        </div>
      </Modal>
      <SuspenseModal open={!!penaltyFormOpenByPaymentId} setOpen={() => setPenaltyFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PenaltyForm
            paymentId={penaltyFormOpenByPaymentId as string}
            onDone={() => {
              refetchPaymentsSchedule()
              setPenaltyFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={!!deletionFormOpenByPaymentId} setOpen={() => setDeletionFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление платежа'
            onDone={async () => {
              await deletePayment({ variables: { input: { id: deletionFormOpenByPaymentId as string } } })
              await Promise.allSettled([refetchBasePayments(), refetchPaymentsSchedule()])
              setDeletionFormOpenByPaymentId(undefined)
            }}
            onDismiss={() => setDeletionFormOpenByPaymentId(undefined)}
          >
            Вы действительно хотите удалить платеж?
          </ConfirmationForm>
        </div>
      </Modal>
      <Modal
        open={!!paymentScheduleDeletionFormOpenById}
        setOpen={() => setPaymentScheduleDeletionFormOpenById(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <ConfirmationForm
            title='Удаление графика'
            onDone={async () => {
              if (paymentScheduleDeletionFormOpenById) {
                await deletePaymentSchedule({
                  variables: {
                    input: {
                      id: paymentScheduleDeletionFormOpenById
                    }
                  }
                })
                refetchPaymentSchedules()
              }
              setPaymentScheduleDeletionFormOpenById(undefined)
            }}
            onDismiss={() => setPaymentScheduleDeletionFormOpenById(undefined)}
          >
            Вы действительно хотите удалить график?
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal
        open={!!editTransactionsFormOpenByPaymentId}
        setOpen={() => setEditTransactionsFormOpenByPaymentId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <TransactionsDateForm
            paymentId={editTransactionsFormOpenByPaymentId as string}
            onDone={() => {
              refetchBasePayments()
              refetchPaymentsSchedule()
              setEditTransactionsFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={scheduleFormOpen} setOpen={setScheduleFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ScheduleForm
            dealId={deal._id}
            onDone={() => {
              setScheduleFormOpen(false)
              refetchPaymentsSchedule()
              refetchPaymentSchedules()
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!scheduleToCompareId} setOpen={() => setScheduleToCompareId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentSchedulesCompare
            scheduleAId={scheduleToCompareId as string}
            scheduleAIndex={paymentSchedules.length - paymentSchedules.findIndex((p) => p.id === scheduleToCompareId)}
            scheduleBId={actualSchedule?.id}
            scheduleBIndex={paymentSchedules.length}
            actualScheduleId={actualSchedule?.id}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal
        open={!!editPaymentScheduleDateFormOpenById}
        setOpen={() => setEditPaymentScheduleDateFormOpenById(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <PaymentScheduleDateForm
            paymentScheduleId={editPaymentScheduleDateFormOpenById as string}
            onDone={() => {
              refetchPaymentSchedules()
              setEditPaymentScheduleDateFormOpenById(undefined)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Payments
