import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import useGetContactFioAndPhone from '../../useGetContactFioAndPhone'

export interface ApplicationChangedEventProps extends BaseHistoryEventProps {
  data?: {
    source?: {
      after: string
      before: string
    }
    contacts?: {
      after: number[]
      before: number[]
    }
    leasingSubjectCategories?: {
      after: string[]
      before: string[]
    }
    customer?: {
      after: string
      before: string
    }
  }
}

const ApplicationChangedEvent: FC<ApplicationChangedEventProps> = ({ actedAt, data, withLine, initiatedByUserId }) => {
  return (
    <>
      {data?.contacts && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>удалил(а) контакт</span>
            </div>
            <span>
              {data?.contacts?.before
                .filter((contactId) => !data.contacts?.after?.includes(contactId))
                .map((contactId) => useGetContactFioAndPhone(contactId))
                .join(', ')}
            </span>
          </div>
        </div>
      )}
      {data?.source && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>изменил(а) источник</span>
            </div>
            <span>
              {data.source?.before} → {data.source?.after}
            </span>
          </div>
        </div>
      )}
      {data?.leasingSubjectCategories && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>изменил(а) категории лизинга</span>
            </div>
            <span>
              {data.leasingSubjectCategories.before.join(', ')} → {data.leasingSubjectCategories.after.join(', ')}
            </span>
          </div>
        </div>
      )}
      {data?.customer && (
        <div className='flex gap-6'>
          <EventActedAt date={actedAt} withLine={withLine} />

          <div>
            <div className='flex items-start gap-2'>
              <EventInitiatedBy userId={initiatedByUserId} />
              <span className='text-labels-tertiary'>изменил(а) компанию</span>
            </div>
            <span>
              {data.customer?.before} → {data.customer?.after}
            </span>
          </div>
        </div>
      )}
    </>
  )
}

export default ApplicationChangedEvent
