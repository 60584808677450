import { FC, ReactNode, Children } from 'react'
import c from 'clsx'
import { ReactComponent as FileUploadIcon } from '../../svg/icons/fileUpload.svg'
import { DocumentNodeFragment } from '../../graphql/schema'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import Document from './Document'

interface FolderProps {
  onUpload?: (files: File[]) => Promise<void>
  onDelete?: () => void
  files?: DocumentNodeFragment[]
  children?: ReactNode
  limit?: number
  canDelete?: boolean
  canUpload?: boolean
  loading?: boolean
}

const Folder: FC<FolderProps> = ({
  onUpload,
  onDelete,
  files,
  limit = 0,
  canDelete = true,
  loading = false,
  children,
  ...props
}) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: onUpload,
    multiple: limit !== 1,
    noClick: true,
    maxFiles: limit
  })

  const canUpload = props.canUpload && !!onUpload
  const filesCount = (files?.length || 0) + Children.count(children)

  return (
    <div {...getRootProps()} className='flex flex-col gap-12'>
      <div className='empty:hidden'>
        {files?.map((doc) => (
          <div key={doc._id} className='border-b-1 border-grayscale-450 py-10 first:pt-0 last:border-b-0 last:pb-0'>
            <Document doc={doc} canDelete={canDelete} onDelete={onDelete} />
          </div>
        ))}
        {Children.map(children, (child, i) => (
          <div key={`file-${i}`} className='border-b-1 border-grayscale-450 py-10 first:pt-0 last:border-b-0 last:pb-0'>
            {child}
          </div>
        ))}
      </div>
      {/* show button when there is no limit or file amount does not exceed it */}
      {(!limit || (files?.length || 0) < limit) && canUpload ? (
        <div
          onClick={open}
          className={c(
            isDragActive && 'border-opacity-0 ring-2 ring-red-50',
            'flex h-25 w-full cursor-pointer items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 text-grayscale-250 hover:text-grayscale-200'
          )}
        >
          <FileUploadIcon />
          Добавить файлы
          <label className='cursor-pointer'>
            <input
              {...getInputProps()}
              className='absolute inset-0 h-full w-full cursor-pointer opacity-0'
              type='file'
            />
          </label>
        </div>
      ) : (
        !filesCount && (
          <div className='flex h-25 w-full items-center justify-center gap-5 rounded-xl border-1 border-dashed border-grayscale-300 text-grayscale-250'>
            {loading ? (
              <LoadingIcon className='mr-7 flex-none animate-spin' width='20px' height='20px' />
            ) : (
              'Нет файлов'
            )}
          </div>
        )
      )}
    </div>
  )
}

export default Folder
