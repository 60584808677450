import { FC } from 'react'
import Folder from './Folder'
import useDocumentTypes from './useDocumentTypes'
import { DocumentTypeEntity, useDocumentsQuery } from '../../graphql/schema'
import useNodes from '../../hooks/useNodes'
import useUploadDocuments from './useUploadDocuments'

interface EntityFolderProps {
  entityId: number | string
  entityType: DocumentTypeEntity
  folderName: string
  limit?: number
  canDelete?: boolean
  canUpload?: boolean
  onChange?: () => void
}

const EntityFolder: FC<EntityFolderProps> = ({
  entityId,
  entityType,
  folderName,
  limit = 0,
  canDelete = true,
  canUpload = true,
  onChange
}) => {
  const docTypes = useDocumentTypes(entityType)
  const docType = docTypes?.find((t) => t?.type === folderName)

  const { data } = useDocumentsQuery({
    variables: {
      filter: {
        entityType,
        type: folderName,
        entityId: `${entityId}`
      }
    },
    skip: !docType || !entityId
  })

  const documents = useNodes(data?.documents?.edges)

  const uploadDocuments = useUploadDocuments({
    folderName,
    entityType,
    entityId: `${entityId}`
  })

  if (docTypes !== undefined && !docType) return <div>Папка "{folderName}" не найдена</div>

  return (
    <Folder
      files={documents}
      onUpload={async (files) => {
        await uploadDocuments(files)
        onChange?.()
      }}
      onDelete={onChange}
      limit={limit}
      canDelete={canDelete}
      canUpload={canUpload}
    />
  )
}

export default EntityFolder
