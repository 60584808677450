import { FC, Suspense } from 'react'
import { PaymentCollectionProcessStatus, PaymentCollectionProcessStatus as Status } from '../../../graphql/schema'
import { collectionStatusDict } from '../../../utils/dictionaries'
import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import CollectionStatus from '../../../components/CollectionStatus'
import useChangeCollectionStatus from './useChangeCollectionStatus'
import { ReactComponent as LoadingIcon } from './../../../svg/icons/loading.svg'
import { ReactComponent as MoreIcon } from './../../../svg/icons/more.svg'
import c from 'clsx'

const listStatuses: Status[] = Object.keys(collectionStatusDict) as Status[]

interface CollectionStatusSwitcherProps {
  collectionProcessId: string
  status: PaymentCollectionProcessStatus
  onChangeStatus: (status: Status) => void
  disabled?: boolean
}

interface CollectionStatusSwitcherInnerProps {
  status: Status
  changeStatus: (status: Status) => Promise<void>
  onChangeStatus: (status: Status) => void
}

const CollectionStatusSwitcherInner: FC<CollectionStatusSwitcherInnerProps> = ({
  status,
  changeStatus,
  onChangeStatus
}) => {
  return (
    <>
      {listStatuses?.map((s) => {
        return (
          <Menu.Item key={s}>
            <div
              onClick={() => {
                changeStatus(s)
                onChangeStatus(s)
              }}
              className={c(
                'flex cursor-pointer items-center gap-8 px-8 py-5 first:rounded-t-md last:rounded-b-md hover:bg-surface-tertiary',
                s === status ? 'text-base-red' : 'text-labels-secondary'
              )}
            >
              <CollectionStatus status={s} />
              {collectionStatusDict[s]}
            </div>
          </Menu.Item>
        )
      })}
    </>
  )
}

const CollectionStatusSwitcher: FC<CollectionStatusSwitcherProps> = ({
  status,
  collectionProcessId,
  onChangeStatus,
  disabled
}) => {
  const [changeStatus, loading] = useChangeCollectionStatus(collectionProcessId)

  return (
    <div className='relative'>
      <Menu>
        <MenuButton
          disabled={disabled}
          className='group flex w-full items-center rounded-md border-1 border-grayscale-400 bg-white-0 px-4 py-2 font-medium text-labels-secondary'
        >
          <CollectionStatus status={status} />
          <div className='ml-6'>{collectionStatusDict[status]}</div>
          <div className='ml-4'>
            {loading ? (
              <LoadingIcon className='h-7 w-7 animate-spin text-base-red' />
            ) : (
              <MoreIcon className='pointer-events-none h-7 w-7 rotate-90 text-labels-secondary group-data-open:-rotate-90' />
            )}
          </div>
        </MenuButton>

        <Suspense
          fallback={
            <MenuItems
              unmount={true}
              className='absolute mt-4 w-full rounded-md border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
            >
              <div className='py-10'>
                <LoadingIcon className='mx-auto animate-spin text-base-red' />
              </div>
            </MenuItems>
          }
        >
          <Transition
            as='div'
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <MenuItems
              unmount={true}
              className='absolute z-50 mt-4 w-fit rounded-md border-[1px] border-grayscale-400 bg-white-0 text-p200 font-medium'
            >
              <CollectionStatusSwitcherInner
                status={status}
                changeStatus={changeStatus}
                onChangeStatus={onChangeStatus}
              />
            </MenuItems>
          </Transition>
        </Suspense>
      </Menu>
    </div>
  )
}

export default CollectionStatusSwitcher
