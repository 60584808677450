import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { parseDocx, getDocumentText, renderDocxTemplate, saveDocx } from '../../../utils/docx'
import { PledgeKind, useSpecDealSupplySuspenseQuery, VatKind } from '../../../graphql/schema'
import { downloadFile, saveFile } from '../../../utils/downloadFile'
import { dashedDateFormat, dateFormatter } from '../../../utils/dateFormatter'
import SubmitButton from '../../../components/SubmitButton'
import Input from '../../../components/Input'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { getCleanName } from '../../../utils/dadata'
import { Suggestion, CompanyData } from '../../../types/dadata'
import getInclineFIO from '../../../utils/getInclineFIO.ts'
import { LEADER_STATUSES_GENITIVE_MAP } from '../models/constants.ts'
import { capitalizeFirst, capitalizeFirstInWords, getCompanyForm, isIP } from '../../../utils/contractUtils.ts'
import { Signatory } from '../models'
import SignatorySelectInput, {
  KimSignatory,
  signatorySignatureDict
} from '../../../components/SignatorySelectInput.tsx'
import useNodes from '../../../hooks/useNodes.ts'
import ContactInfoSelectInput, { ContactInfo } from '../../../components/Forms/Inputs/ContactInfoSelectInput.tsx'
import useCustomerContactInfos from '../../../hooks/useCustomerContactInfos.ts'
import usePersonAddress from '../../../hooks/usePersonAddress.ts'

let specTemplate: Blob | undefined

interface ContractDetails {
  contractNumber: string
  contractDate: string
  specId: string
  specDate: string
  supplierOPF: string
  supplierName: string
  supplierPersonRole: string
  supplierGenitiveTitle: string
  supplierSignature: string
  leasingContractNumber: string
  leasingContractDate: string
  customerOPF: string
  customerCompanyName: string
  customerRequisites: string
  customerAddress: string
  customerPersonName: string
  customerPhone: string
  customerEmail: string
  feraPerson: string
  feraPhone: string
  feraEmail: string
  paymentConditions: string
  supplierShortOPF: string
  currency: string
  feraGenitiveTitle: string
  feraPersonRole: string
  feraSignature: string
  supplierContactName: string
  supplierContactPhone: string
  supplierContactEmail: string
  vatKindDesc: string
}

const renderSpecTemplate = async (vars: ContractDetails, msp?: boolean) => {
  if (!specTemplate) {
    specTemplate = await downloadFile(`/templates/spec${msp ? 'MSP' : ''}.docx`)
  }

  const zip = await parseDocx(specTemplate)
  const document = await getDocumentText(zip)

  if (!document) throw new Error('Не удалось загрузить шаблон')

  const renderedTemplate = renderDocxTemplate(document, vars)

  return await saveDocx(zip, renderedTemplate)
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const vatKindDescDict: Record<string, string> = {
  [VatKind.Usn]: 'НДС не облагается на основании статей 346.12 и 346.13 главы 26.2 НК РФ',
  [VatKind.Medicine]: 'Реализация мед. оборудования не облагается НДС на основании пп.1 п. 2 ст. 149 НК РФ',
  [VatKind.Regular]: '',
  [VatKind.Osno]: ''
}

interface SpecTemplateFormInputs {
  contractNumber: string
  contractDate: string
  specId: string
  specDate: string
  supplierPersonRole: string
  supplierPersonName: string
  supplierBasis: string
  customerContactInfo: ContactInfo
  customerAddress: string
  paymentConditions: string
  leaderStatusGenitiveWithName: string
  supplierOPF: string
  supplierShortOPF: string
  signatory: Signatory
  supplierRequisites: ContactInfo
}

interface SpecTemplateFormProps {
  onDone: () => void
  supplyId: number
}

const SpecTemplateForm: FC<SpecTemplateFormProps> = ({ onDone, supplyId }) => {
  const [loading, setLoading] = useState(false)
  const { data: supplyData } = useSpecDealSupplySuspenseQuery({ variables: { id: `${supplyId}` } })
  const dealSupply = supplyData?.dealSupply
  const supplierCompany = dealSupply?.supplierCompany

  const supplierCompanyDaData = supplierCompany?.company?.dadata as unknown as Suggestion<CompanyData>
  const supplierIP: boolean = isIP(supplierCompanyDaData?.data)

  let leaderStatus =
    LEADER_STATUSES_GENITIVE_MAP.get(supplierCompanyDaData?.data?.management?.post?.toLowerCase()) ||
    supplierCompanyDaData?.data?.management?.post?.toLowerCase() ||
    ''
  leaderStatus = capitalizeFirst(leaderStatus)

  const deal = dealSupply?.deal
  const customerCompany = deal?.customerCompany
  const customerCompanyDaData = customerCompany?.company?.dadata as unknown as Suggestion<CompanyData>
  const customerIP: boolean = isIP(customerCompanyDaData?.data)

  const supplierContactInfos = useNodes(dealSupply?.supplierCompany.contactInfos?.edges)

  const customerContactInfos = useCustomerContactInfos([deal?._id as number], customerCompany?._id.toString())

  const IPfactAddress = usePersonAddress(deal?.customerCompany.company?.inn || '')

  const { register, handleSubmit, formState, setError, control, setValue } = useForm<SpecTemplateFormInputs>({
    defaultValues: {
      contractNumber: dealSupply?.contractNumber || '',
      contractDate: dealSupply?.contractDate ? dealSupply.contractDate.split('T').shift() : '',
      specId: dealSupply?.specificationNumber || '',
      specDate: dealSupply?.specificationDate
        ? new Date(dealSupply.specificationDate).toISOString().split('T').shift()
        : '',
      supplierPersonRole: capitalizeFirstLetter(supplierCompanyDaData?.data?.management?.post?.toLowerCase() || ''),
      supplierPersonName: supplierIP
        ? capitalizeFirstInWords(supplierCompanyDaData?.data?.name?.full)
        : supplierCompanyDaData?.data?.management?.name,
      customerAddress: customerIP ? IPfactAddress : customerCompanyDaData.data.address?.value,
      paymentConditions: '',
      leaderStatusGenitiveWithName: supplierCompanyDaData?.data?.management?.name
        ? (
            leaderStatus +
            ' ' +
            getInclineFIO(supplierCompanyDaData?.data?.management?.name) +
            ', действующего на основании Устава'
          ).trim()
        : '',
      supplierOPF: supplierIP
        ? 'Индивидуальный предприниматель'
        : getCompanyForm(
            dealSupply?.supplierCompany?.company?.fullWithOpf || '',
            dealSupply?.supplierCompany?.company?.name || '',
            true
          ),
      supplierShortOPF: supplierIP
        ? 'ИП'
        : getCompanyForm(
            dealSupply?.supplierCompany?.company?.shortWithOpf || '',
            dealSupply?.supplierCompany?.company?.name || ''
          ),
      signatory: KimSignatory
    }
  })

  useEffect(() => {
    if (IPfactAddress && customerIP) {
      setValue('customerAddress', IPfactAddress)
    }
  }, [IPfactAddress, setValue, customerIP])

  const onSubmit: SubmitHandler<SpecTemplateFormInputs> = async (data) => {
    setLoading(true)
    if (!dealSupply) throw new Error('Не удалось загрузить поставку')
    const deal = dealSupply.deal
    const customerCompany = deal.customerCompany
    const manager = deal.user

    const specDate = new Date(data.specDate)

    const customerCompanyDaData = customerCompany?.company?.dadata as unknown as Suggestion<CompanyData>

    try {
      // we need this two promises to run in parallel
      // const nameSuggestionsPromise = getCleanName(data.supplierPersonName)
      // const supplierGenitiveTitlePromise = toCase(
      //   'genitive',
      //   `${data.supplierPersonRole} ${data.supplierPersonName}, действующий ${data.supplierBasis}`
      // )

      // wait for both promises to resolve
      // await Promise.all([nameSuggestionsPromise, supplierGenitiveTitlePromise])

      // use await again just to get the results. Don't worry, they are already resolved
      // const nameSuggestions = await nameSuggestionsPromise
      // let supplierGenitiveTitle = await supplierGenitiveTitlePromise

      const nameSuggestions = await getCleanName(data.supplierPersonName)

      if (nameSuggestions.length === 0) {
        setError('supplierPersonName', { message: 'Не удалось сократить ФИО для подписи' })
        return
      }
      const nameData = nameSuggestions[0].data

      // if title string ends with a dot, remove it
      // if (supplierGenitiveTitle.endsWith('.')) {
      //   supplierGenitiveTitle = supplierGenitiveTitle.slice(0, -1)
      // }

      const ogrn = customerIP ? 'ОГРНИП' : 'ОГРН'

      let requisites = `ИНН ${customerCompany.company?.inn}, ${ogrn} ${customerCompanyDaData?.data?.ogrn || '<ОГРН>'}`
      if (customerCompany.company?.kpp) requisites += `, КПП ${customerCompany.company?.kpp}`

      if (!nameData.name || !nameData.surname || !nameData.patronymic) {
        // check which parts of the name are missing and display error that corresponds to the missing part
        const missingParts = []
        if (!nameData.surname) missingParts.push('фамилия')
        if (!nameData.name) missingParts.push('имя')
        if (!nameData.patronymic) missingParts.push('отчество')
        setError('supplierPersonName', { message: `Отсутствует ${missingParts.join(', ')}` })
        return
      }

      const contractDate = new Date(data.contractDate)

      const docx = await renderSpecTemplate(
        {
          contractNumber: data.contractNumber,
          contractDate: data.contractDate ? dateFormatter.format(contractDate) : '',
          specId: data.specId,
          specDate: data.specDate ? dateFormatter.format(specDate) : '',
          supplierOPF: data.supplierOPF || '',
          supplierName: dealSupply.supplierCompany?.company?.name || '',
          supplierPersonRole: data.supplierPersonRole,
          supplierSignature: `${nameData.name[0]}. ${nameData.patronymic[0]}. ${nameData.surname}`,
          supplierGenitiveTitle: !supplierIP ? `, в лице ${data.leaderStatusGenitiveWithName}` : '',
          leasingContractNumber: 'ДЛ' + deal.contractNumber,
          leasingContractDate: deal.contractDate ? dateFormatter.format(new Date(deal.contractDate)) : '',
          customerOPF: getCompanyForm(customerCompany.company?.shortWithOpf || '', customerCompany.company?.name || ''),
          customerCompanyName: customerCompany.company?.name || '',
          customerRequisites: requisites,
          customerAddress: customerIP
            ? `адрес регистрации: ${data?.customerAddress || ''}`
            : `юр. адрес: ${data?.customerAddress || ''}`,
          customerPersonName: data?.customerContactInfo?.fio || '',
          customerPhone: data?.customerContactInfo?.phone || '',
          customerEmail: data?.customerContactInfo?.email || '',
          feraPerson: `${manager?.surname} ${manager?.name}`,
          feraPhone: manager?.phone || '',
          feraEmail: manager?.email || '',
          paymentConditions: data.paymentConditions,
          supplierShortOPF: data.supplierShortOPF || '',
          currency: dealSupply.currency || 'RUB',
          feraGenitiveTitle: data.signatory.statusGenitiveWithName,
          feraPersonRole: data.signatory.status,
          feraSignature: signatorySignatureDict[data.signatory.fio],
          supplierContactName: data.supplierRequisites?.fio || '-',
          supplierContactPhone: data.supplierRequisites?.phone || '-',
          supplierContactEmail: data.supplierRequisites?.email || '-',
          vatKindDesc: deal?.kind ? vatKindDescDict[deal.kind] : ''
        },
        (deal?.pledgeKind === PledgeKind.WithPledge && deal?.pledgeHolder?.key === 'mspbank') ||
          (deal?.leaseObjectPledgeKind === PledgeKind.WithPledge && deal?.leaseObjectPledgeHolder?.key === 'mspbank')
      )

      const [day, month, year] = dateFormatter.format(specDate).split('.')

      saveFile(
        docx,
        `${year.substring(2)}${month}${day} Спецификация №${data.specId} от ${dashedDateFormat(specDate)} к ${
          data.contractNumber
        } от ${dashedDateFormat(contractDate)}.docx`
      )

      onDone()
    } catch (e: unknown) {
      if (e instanceof Error) {
        showPopup({ title: 'Ошибка сохранения', subtitle: e.message })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <section className='p-12 md:w-[900px]'>
      <h1 className='mb-12 font-display text-h200'>Шаблон спецификации</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-13 grid grid-cols-2 gap-x-22 gap-y-13'>
          <Input
            label='Условия оплаты'
            placeholder='100% предоплата'
            type='text'
            {...register('paymentConditions', { required: true })}
            error={formState.errors?.paymentConditions}
          />
          <Controller
            name='signatory'
            control={control}
            render={({ field }) => (
              <SignatorySelectInput
                selected={field.value}
                onChange={(selected) => field.onChange(selected)}
                label='Подписант Фера'
                error={formState.errors?.signatory?.message}
              />
            )}
          />
          <hr className='col-span-2 col-start-1 border-grayscale-400'></hr>
          <div className='grid grid-cols-1 gap-y-13'>
            <div>
              <Input
                label='Юридический адрес клиента'
                type='text'
                autoComplete='off'
                placeholder='105005, г. Москва, Бакунинская улица, дом 4-6, строение 1'
                {...register('customerAddress', { required: true })}
              />
            </div>
            <Controller
              name='customerContactInfo'
              control={control}
              render={({ field }) => (
                <ContactInfoSelectInput
                  selected={field.value}
                  onChange={(selected) => field.onChange(selected)}
                  error={formState.errors?.signatory?.message}
                  label='Контактное лицо клиента'
                  contactInfos={customerContactInfos}
                />
              )}
            />
            <Controller
              name='supplierRequisites'
              control={control}
              render={({ field }) => (
                <ContactInfoSelectInput
                  selected={field.value}
                  onChange={(selected) => field.onChange(selected)}
                  error={formState.errors?.signatory?.message}
                  label='Контактное лицо поставщика'
                  contactInfos={supplierContactInfos}
                />
              )}
            />
          </div>
          {supplierIP ? (
            <div className='flex flex-col gap-13'>
              <Input
                label='Наименование формы общества'
                placeholder='Индивидуальный предприниматель'
                type='text'
                {...register('supplierOPF', { required: true })}
                error={formState.errors?.supplierOPF}
              />
              <Input
                label='Короткое наименование формы общества'
                placeholder='ИП'
                type='text'
                {...register('supplierShortOPF', { required: true })}
                error={formState.errors?.supplierShortOPF}
              />
              <Input
                label='Полное наименование компании'
                placeholder='Петров Петр Петрович'
                type='text'
                {...register('supplierPersonName', { required: true })}
                error={formState.errors?.supplierPersonName}
              />
            </div>
          ) : (
            <div className='grid grid-cols-1 gap-y-13'>
              <Input
                label='ФИО подписанта поставщика'
                placeholder='Петров Петр Петрович'
                type='text'
                {...register('supplierPersonName', { required: true })}
                error={formState.errors?.supplierPersonName}
              />
              <Input
                label='Должность подписанта поставщика'
                placeholder='Генеральный директор'
                type='text'
                {...register('supplierPersonRole', { required: true })}
                error={formState.errors?.supplierPersonRole}
              />
              {/*<Input*/}
              {/*  label='На каком основании действует подписант'*/}
              {/*  placeholder='на основании устава'*/}
              {/*  type='text'*/}
              {/*  {...register('supplierBasis', { required: true })}*/}
              {/*  error={formState.errors?.supplierBasis}*/}
              {/*/>*/}
              <Input
                label='Кто отвечает за компанию'
                placeholder='действующий на основании Устава'
                type='text'
                {...register('leaderStatusGenitiveWithName', { required: true })}
                error={formState.errors?.leaderStatusGenitiveWithName}
              />
            </div>
          )}
        </div>
        <SubmitButton loading={loading}>Сохранить шаблон</SubmitButton>
      </form>
    </section>
  )
}

export default SpecTemplateForm
