import { FC } from 'react'
import {
  useBoVerificationsQuery,
  useFindOrCreateBoVerificationMutation,
  useUpdateVerificationResidencePermitMutation,
  VerificationState
} from '../../../graphql/schema.tsx'
import Switch from '../../../components/Switch.tsx'
import { Card, CardHeader, CardTitle } from '../../../components/Card.tsx'
import { GridTable, GridTableCell, GridTableRow } from '../../../components/Table.tsx'

interface ResidencePermitProps {
  contactId: string
}

const ResidencePermit: FC<ResidencePermitProps> = ({ contactId }) => {
  const { data, loading, refetch } = useBoVerificationsQuery({
    variables: { contactId: parseInt(`${contactId}`) },
    skip: !contactId
  })
  const boVerification =
    data?.boVerifications.filter(
      (v) =>
        v.state &&
        [VerificationState.Completed, VerificationState.Rejected, VerificationState.Accepted].includes(v.state)
    )[0] || data?.boVerifications[0]

  const [updateVerificationResidencePermit] = useUpdateVerificationResidencePermitMutation()
  const [addBoVerification, { loading: creatingBoVerificationLoading }] = useFindOrCreateBoVerificationMutation()

  const onResidencePermitChange = async (checked: boolean) => {
    if (loading) return

    let id = boVerification?.id
    let uuid = boVerification?.uuid
    if (!uuid) {
      const { data } = await addBoVerification({
        variables: {
          input: {
            contactId: parseInt(contactId)
          }
        }
      })
      id = data?.findOrCreateBoVerification?.id
      uuid = data?.findOrCreateBoVerification?.uuid
    }

    if (!id || !uuid) return
    await updateVerificationResidencePermit({
      variables: {
        uuid,
        input: {
          residencePermit: checked
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateVerification: {
          __typename: 'Verification',
          id,
          residencePermit: checked
        }
      }
    })

    refetch()
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Вид на жительство</CardTitle>
      </CardHeader>
      <div className='px-5 pb-5'>
        <div className='rounded-md bg-surface-primary shadow-card'>
          <GridTable className='grid-cols-2'>
            <GridTableRow>
              <GridTableCell className='text-labels-secondary'>Иностранный гражданин с ВНЖ</GridTableCell>
              <GridTableCell className='flex items-center justify-end pr-10'>
                <Switch
                  disabled={loading || creatingBoVerificationLoading}
                  checked={boVerification?.residencePermit || false}
                  onChange={(checked: boolean) => onResidencePermitChange(checked)}
                />
              </GridTableCell>
            </GridTableRow>
          </GridTable>
        </div>
      </div>
    </Card>
  )
}

export default ResidencePermit
