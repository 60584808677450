import * as React from 'react'
import Input from '../../components/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ReactComponent as PlusIcon } from '../../svg/icons/plus.svg'
import {
  UpdateContactMutationVariables,
  useUpdateContactMutation,
  useContactFormContactSuspenseQuery
} from '../../graphql/schema'
import { EMAIL_PATTERN, PHONE_MASK } from '../../utils/constants'
import MaskedInput from '../../components/MaskedInput'
import { handleBackendErrorsToForm } from '../../utils/backendErrorUtils'
import { ApolloError } from '@apollo/client'
import SubmitButton from '../SubmitButton'

type Inputs = Omit<Stringify<UpdateContactMutationVariables['input']>, 'id'>
const preparePhoneForApi = (phone?: string) => (phone?.length ? '+' + phone.replace(/\D/g, '') : '')
interface ContactFormProps {
  onDone?: (contactId?: number) => void | Promise<void>
  id: number
}

const EditContactForm: React.FC<ContactFormProps> = ({ onDone, id }) => {
  const { data } = useContactFormContactSuspenseQuery({ variables: { id: id.toString() } })
  const entity = data?.contact

  const [updateContact, { loading }] = useUpdateContactMutation()
  const { register, handleSubmit, formState, setError } = useForm<Inputs>({
    defaultValues: {
      fio: entity?.fio || '',
      phone: entity?.phone || '',
      email: entity?.email || ''
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!entity) return

    await updateContact({
      variables: {
        input: {
          ...data,
          id: entity._id.toString(),
          phone: preparePhoneForApi(data.phone)
        }
      }
    })
      .then((result) => {
        if (onDone) onDone(result.data?.updateContact?.contact?._id)
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<Inputs>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='md: w-[448px] p-12'>
      <h1 className='mb-12 font-display text-h200'>Редактировать контакт</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12 flex flex-col gap-8'>
          <MaskedInput
            label='Телефон'
            inputMode='tel'
            type='text'
            mask={PHONE_MASK}
            placeholder='+7 987 654 32 10'
            {...register('phone', {
              validate: (value) => {
                const digits = value?.replace(/\D/g, '') || ''
                return digits.length === 11 || 'Введите номер телефона полностью'
              }
            })}
            error={formState.errors.phone}
          />
          <Input label='ФИО' type='text' {...register('fio', { required: true })} error={formState.errors?.fio} />
          <Input
            label='Email'
            type='text'
            placeholder='mail@example.com'
            {...register('email', {
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Некорректный E-mail'
              }
            })}
            error={formState.errors?.email}
          />
        </div>
        <SubmitButton loading={loading}>
          <PlusIcon className='mr-5' />
          Изменить
        </SubmitButton>
      </form>
    </section>
  )
}

export default EditContactForm
