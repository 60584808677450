import { FC, useCallback, useEffect, useState } from 'react'
import { ReactComponent as ChevronDownIcon } from '../../svg/ui/chevron-down.svg'
import { ReactComponent as AppScoringRisksUserEditIcon } from '../../svg/icons/appScoringRisksUserEdit.svg'
import {
  CompanyAnalysisStatus,
  ScoringAnalysisTargetType,
  TaskTargetType,
  useApplicationForScoringLazyQuery,
  useCompanyAnalysisQuery,
  useScoringAnalysisLazyQuery,
  useUpdateCompanyAnalysisMutation,
  useUserByIdQuery
} from '../../graphql/schema.tsx'
import ScoringStatusSwitcher from '../Application/Scoring/StatusSwitcher.tsx'
import Avatar from '../../components/Avatar'
import getFullName from '../../utils/getFullName.ts'
import { dateTimeFormatter } from '../../utils/dateFormatter.ts'
import c from 'clsx'
import ScoringRisksUserForm from '../Application/Scoring/ScoringRisksUserForm.tsx'
import SuspenseModal from '../../components/SuspenseModal.tsx'
import ScoringInput from '../Application/Scoring/ScoringInput.tsx'
import ScoringQuestion from '../Application/Scoring/ScoringQuestion.tsx'
import {
  SCORING_GOVERNMENT_CONTRACTS_QUESTIONS,
  SCORING_LITIGATION_AND_LEASING_QUESTIONS,
  SCORING_STATUTORY_QUESTIONS,
  SCORING_TAX_QUESTIONS
} from '../../utils/constants.ts'
import useScoringConclusion from '../Application/Scoring/useScoringConclusion.ts'
import { Card, CardAccentButton, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import WaitingForAdditionalInfoForm from '../Application/Scoring/WaitingForAdditionalInfoForm.tsx'

interface CompanyAnalysisCardProps {
  companyIP: boolean
  open: boolean
  setOpen: () => void
  companyAnalysisId: string
}

const CompanyAnalysisCard: FC<CompanyAnalysisCardProps> = ({ companyIP, open, setOpen, companyAnalysisId }) => {
  const [updateCompanyAnalysis, { loading: updateCompanyAnalysisLoading }] = useUpdateCompanyAnalysisMutation()
  const [getScoringAnalysis, { loading: scoringAnalysisLoading }] = useScoringAnalysisLazyQuery()
  const [getApplication, { loading: applicationLoading }] = useApplicationForScoringLazyQuery()

  const [editRisksUserId, setEditRisksUserId] = useState<number>()
  const [questionnaireData, setQuestionnaireData] = useState()
  const [taskFormOpenByTarget, setTaskFormOpenByTarget] = useState<{
    targetId: number
    targetType: string
    managerId?: number
  }>()

  const { data: companyAnalysisData } = useCompanyAnalysisQuery({
    variables: {
      id: parseInt(companyAnalysisId)
    },
    skip: !companyAnalysisId
  })
  const companyAnalysis = companyAnalysisData?.companyAnalysis

  useEffect(() => {
    if (companyAnalysis?.questionnaireData) {
      setQuestionnaireData(companyAnalysis.questionnaireData)
    }
  }, [companyAnalysis?.questionnaireData])

  const { data: userData } = useUserByIdQuery({
    variables: { id: companyAnalysis?.risksUserId?.toString() as string },
    skip: !companyAnalysis?.risksUserId
  })
  const risksUser = userData?.user

  const handleChangeStatus = async (status: CompanyAnalysisStatus) => {
    if (!companyAnalysis?.id) return

    if (status === CompanyAnalysisStatus.WaitingForAdditionalInfo) {
      const { data: scoringAnalysisData } = await getScoringAnalysis({
        variables: {
          input: {
            companyAnalysisId: parseInt(companyAnalysis.id)
          }
        }
      })
      const scoringAnalysis = scoringAnalysisData?.scoringAnalysis

      if (scoringAnalysis?.targetId && scoringAnalysis?.targetType === ScoringAnalysisTargetType.Application) {
        const { data } = await getApplication({
          variables: {
            id: `${scoringAnalysis.targetId}`
          }
        })
        const application = data?.application
        setTaskFormOpenByTarget({
          targetId: scoringAnalysis.targetId,
          targetType: scoringAnalysis.targetType,
          managerId: application?.user?._id
        })
        return
      } else {
        setTaskFormOpenByTarget({
          targetId: companyAnalysis.companyId,
          targetType: TaskTargetType.Company
        })
      }
    }

    await updateCompanyAnalysis({
      variables: {
        id: parseInt(companyAnalysis.id),
        input: {
          status
        }
      }
    })
  }

  const [conclusion, setConclusion, generateConclusion] = useScoringConclusion(companyAnalysis?.questionnaireConclusion)

  const onConclusionAccept = useCallback(
    async (value: string) => {
      if (!companyAnalysis?.id) return

      await updateCompanyAnalysis({
        variables: {
          id: parseInt(companyAnalysis.id),
          input: {
            questionnaireConclusion: value
          }
        }
      })
    },
    [companyAnalysis?.id, updateCompanyAnalysis]
  )

  const handleDataSave = async (data: any) => {
    if (!companyAnalysis?.id) return

    await updateCompanyAnalysis({
      variables: {
        id: parseInt(companyAnalysis?.id),
        input: {
          questionnaireData: data
        }
      }
    })
    const generatedConclusion = generateConclusion(data)
    await onConclusionAccept(generatedConclusion)
  }

  const questionnaireChaptersFirst = [
    { value: 'Уставные', questions: SCORING_STATUTORY_QUESTIONS },
    { value: 'Госконтракты', questions: SCORING_GOVERNMENT_CONTRACTS_QUESTIONS }
  ]
  const questionnaireChaptersSecond = [
    {
      value: 'Налоговые',
      questions: companyIP
        ? SCORING_TAX_QUESTIONS.filter(
            (q) =>
              ![
                'Указать среднесписочное количество сотрудников организации?',
                'Специальный налоговый режим не применяется?'
              ].includes(q.value)
          )
        : SCORING_TAX_QUESTIONS
    },
    { value: 'Судебные и лизинговые', questions: SCORING_LITIGATION_AND_LEASING_QUESTIONS }
  ]

  const processExpired = companyAnalysis?.expiresAt && new Date(companyAnalysis.expiresAt) < new Date()
  const loading = updateCompanyAnalysisLoading || scoringAnalysisLoading || applicationLoading

  return (
    <Card>
      <CardHeader>
        <CardTitle onClick={setOpen} className='cursor-pointer'>
          {processExpired ? 'Истекший' : 'Актуальный'} cкоринг
        </CardTitle>
        <span className='mr-8 font-normal text-labels-tertiary'>
          {companyAnalysis?.createdDate && dateTimeFormatter.format(new Date(companyAnalysis.createdDate))}
        </span>
        <CardMenu>
          <CardAccentButton
            className='py-3'
            onClick={() => {
              setEditRisksUserId(companyAnalysis?.risksUserId)
            }}
          >
            <Avatar userId={companyAnalysis?.risksUserId} height='h-10' width='w-10' />
            {getFullName(risksUser)}
            <AppScoringRisksUserEditIcon />
          </CardAccentButton>
        </CardMenu>

        <CardMenu className='ml-auto'>
          <ScoringStatusSwitcher
            status={companyAnalysis?.status}
            loading={loading}
            onChangeStatus={(status) => handleChangeStatus(status as CompanyAnalysisStatus)}
          />

          <CardIconButton onClick={setOpen} className='p-4'>
            <ChevronDownIcon className={c('transition-transform', open && '-rotate-180')} />
          </CardIconButton>
        </CardMenu>
      </CardHeader>

      {open && (
        <div className='flex flex-col gap-6 px-5 pb-5'>
          <div className='flex gap-12 rounded-md bg-surface-primary p-10 shadow-card'>
            <h2 className='font-display text-h200 font-medium'>Вывод:</h2>

            <div className='w-full'>
              <ScoringInput
                autoComplete='off'
                onChange={(e) => setConclusion(e.currentTarget.value)}
                onAccept={() => onConclusionAccept(conclusion)}
                value={conclusion}
              />
            </div>
          </div>

          <div className='grid grid-cols-3 gap-6'>
            <div className='col-span-1 flex flex-col gap-6'>
              {questionnaireChaptersFirst.map((chapter) => (
                <div key={chapter.value}>
                  <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                    <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                    <div className='mt-10 flex flex-col gap-10'>
                      {chapter.questions.map((question) => (
                        <ScoringQuestion
                          key={chapter.value + question.value}
                          chapter={chapter.value}
                          question={question}
                          questionnaireData={questionnaireData}
                          children={question?.children}
                          onDataChange={(data) => setQuestionnaireData(data)}
                          onDataSave={handleDataSave}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {questionnaireChaptersSecond.map((chapter) => (
              <div key={chapter.value} className='col-span-1'>
                <div className='rounded-md bg-surface-primary p-10 shadow-card'>
                  <h2 className='font-display text-h200 font-medium'>{chapter.value}</h2>

                  <div className='mt-10 flex flex-col gap-10'>
                    {chapter.questions.map((question) => (
                      <ScoringQuestion
                        key={chapter.value + question.value}
                        chapter={chapter.value}
                        question={question}
                        questionnaireData={questionnaireData}
                        children={question?.children}
                        onDataChange={(data) => setQuestionnaireData(data)}
                        onDataSave={handleDataSave}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <SuspenseModal open={!!editRisksUserId} setOpen={() => setEditRisksUserId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <ScoringRisksUserForm
            userId={companyAnalysis?.risksUserId}
            loading={loading}
            onDone={async (risksUserId) => {
              if (!companyAnalysis?.id) return
              await updateCompanyAnalysis({
                variables: {
                  id: parseInt(companyAnalysis.id),
                  input: {
                    risksUserId
                  }
                }
              })
              setEditRisksUserId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!taskFormOpenByTarget} setOpen={() => setTaskFormOpenByTarget(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <WaitingForAdditionalInfoForm
            targetId={taskFormOpenByTarget?.targetId}
            targetType={taskFormOpenByTarget?.targetType as TaskTargetType}
            childTargetId={parseInt(`${companyAnalysis?.id}`)}
            childTargetType={TaskTargetType.CompanyAnalysis}
            managerId={taskFormOpenByTarget?.managerId}
            onDone={async () => {
              setTaskFormOpenByTarget(undefined)
              if (!companyAnalysis?.id) return
              await updateCompanyAnalysis({
                variables: {
                  id: parseInt(companyAnalysis.id),
                  input: {
                    status: CompanyAnalysisStatus.WaitingForAdditionalInfo
                  }
                }
              })
            }}
          />
        </div>
      </SuspenseModal>
    </Card>
  )
}

export default CompanyAnalysisCard
