import { Kind, VatKind } from '../graphql/schema'
import convertKindToVatKind from './convertKindToVatKind'

interface supply {
  vatKind?: VatKind
  vatRate?: number
  supplierCompany?: {
    vatKind?: VatKind
  }
}

export default function checkSuppliesVat(supplies: supply[], dealVatKind?: Kind, dealVatRate?: number) {
  if (supplies.length === 0) {
    return { suppliesVatRateMatches: true, dealAndSuppliesVatKindMatches: false, suppliersVatKindMatches: true }
  }

  const referenceSupply = supplies[0]

  // проверка на совпадение ставки НДС между поставками
  const suppliesVatRateMatches = supplies.every((supply) => supply.vatRate === referenceSupply.vatRate)

  // проверка на совпадение типов НДС между поставками
  const suppliesVatKindMatches = supplies.every((dealSupply) => {
    const currentVatKind = dealSupply.vatKind ?? dealSupply.supplierCompany?.vatKind
    return currentVatKind === (referenceSupply.vatKind ?? referenceSupply.supplierCompany?.vatKind)
  })

  // проверка на ставку НДС в сделке 20%. если она 20 - то там нужно только удостовериться в том, что у всех поставок тоже 20
  const isDealVatTwenty = dealVatRate === 20

  if (isDealVatTwenty) {
    if (dealVatKind === Kind.Usn) {
      return {
        suppliesVatRateMatches,
        dealAndSuppliesVatKindMatches: referenceSupply.vatKind === VatKind.Usn,
        suppliesVatKindMatches
      }
    }
    return {
      suppliesVatRateMatches,
      dealAndSuppliesVatKindMatches: referenceSupply.vatRate === 20,
      suppliesVatKindMatches
    }
  }

  // проверка на совпадение типов НДС в поставках и сделке
  // если у поставки нет типа, берем тип из поставщика
  const dealAndSuppliesVatKindMatches =
    dealVatKind &&
    (referenceSupply.vatKind
      ? referenceSupply.vatKind === convertKindToVatKind(dealVatKind)
      : referenceSupply.supplierCompany?.vatKind === convertKindToVatKind(dealVatKind))

  return { suppliesVatRateMatches, dealAndSuppliesVatKindMatches, suppliesVatKindMatches }
}
