import { FC, useEffect, useState } from 'react'
import useThrottledState from '../../../hooks/useThrottledState'
import {
  useAddSupplierCompanyToApplicationMutation,
  useAddSupplierMutation,
  useSupplierCompaniesByApplicationIdQuery,
  useSuppliersSuggestionsLazyQuery
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import { ReactComponent as PlusIcon } from '../../../svg/icons/plus.svg'
import SubmitButton from '../../../components/SubmitButton'
import { useCompanySuggestions } from '../../../hooks/useSuggestions.ts'
import getCompanyName from '../../../utils/getCompanyName.ts'
import { CompanyData } from '../../../types/dadata.ts'
import Input from '../../../components/Input.tsx'
import Suggestions from '../../../components/Suggestions.tsx'

interface AddSupplierFormProps {
  onDone?: () => void
  applicationId: number
}

interface AddSupplierFormInput {
  company: string
}

const SupplierForm: FC<AddSupplierFormProps> = ({ applicationId, onDone }) => {
  const [company, setCompany] = useState<CompanyData>()
  const [suppliersSuggestionsQuery, { loading: suppliersSuggestionsLoading }] = useSuppliersSuggestionsLazyQuery()

  const { data: supplierCompaniesData } = useSupplierCompaniesByApplicationIdQuery({
    variables: { id: `${applicationId}` },
    skip: !applicationId
  })
  const selectedApplicationSuppliersInn = useNodes(supplierCompaniesData?.application?.supplierCompanies?.edges).map(
    (s) => s.company?.inn
  )

  const [addSupplierToApplication, { loading: addSupplierToApplicationLoading }] =
    useAddSupplierCompanyToApplicationMutation()
  const [addSupplier, { loading: addSupplierLoading }] = useAddSupplierMutation()
  const loading = addSupplierToApplicationLoading || addSupplierLoading || suppliersSuggestionsLoading

  const { handleSubmit, watch, setValue, setError, register, formState } = useForm<AddSupplierFormInput>()

  const companyQuery = watch('company')
  const [throttledCompanyQuery] = useThrottledState(companyQuery, 500)
  const suggestions = useCompanySuggestions(throttledCompanyQuery)?.filter(
    (s) => !selectedApplicationSuppliersInn.includes(s.inn)
  )

  // при выборе компании устанавливаем её имя в поле компании
  useEffect(() => {
    if (!company) return
    setValue('company', getCompanyName(company), {
      shouldValidate: true
    })
  }, [company, setValue])

  const onSubmit: SubmitHandler<AddSupplierFormInput> = async () => {
    if (loading || !company?.inn) return

    const addSupplierOperation = async (inn: string) => {
      const { data } = await addSupplier({
        variables: {
          input: {
            inn
          }
        }
      })
      return `${data?.addSupplierCompany?.supplierCompany?._id}`
    }

    const runOperation = async () => {
      const { data: suppliersSuggestionsData } = await suppliersSuggestionsQuery({ variables: { query: company.inn } })
      const existSupplier = suppliersSuggestionsData?.supplierCompanies?.edges?.[0]?.node

      let supplierId
      if (existSupplier) {
        supplierId = `${existSupplier._id}`
      } else {
        supplierId = await addSupplierOperation(company.inn)
      }

      return addSupplierToApplication({
        variables: {
          input: {
            application: applicationId.toString(),
            supplierCompany: supplierId
          }
        }
      })
    }

    await runOperation()
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        handleBackendErrorsToForm<AddSupplierFormInput>(err, (fieldPath, textError) => {
          setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
        })
      })
  }

  return (
    <section className='overflow-x-clip p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>Добавить поставщика</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='relative mb-12 flex flex-col gap-8'>
          <Suggestions<CompanyData>
            suggestions={suggestions?.map((s) => ({
              key: s._id,
              title: getCompanyName(s),
              subtitle: `${s.inn} ${s.address?.value || ''}`,
              payload: s,
              lineThrough: !!s.state.liquidation_date
            }))}
            select={(suggestion) => setCompany(suggestion.payload)}
          >
            <Input
              label='Компания, ИП или ИНН'
              type='text'
              autoComplete='off'
              {...register('company', {
                required: true,
                validate: {
                  // требуем выбора из предложенных вариантов только если апи подсказок работает
                  fromSuggested: (value) => {
                    return value !== getCompanyName(company) ? 'Требуется выбрать один из вариантов' : true
                  }
                }
              })}
              error={formState.errors.company}
            />
          </Suggestions>
        </div>
        <div className='mt-12'>
          <SubmitButton loading={loading}>
            <PlusIcon className='mr-5' />
            Добавить
          </SubmitButton>
        </div>
      </form>
    </section>
  )
}

export default SupplierForm
