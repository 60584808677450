import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDealSuppliesQuery } from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import SupplyForm from './SupplyForm'
import Supply from './Supply'
import Columns from '../../../components/Columns'
import SuspenseModal from '../../../components/SuspenseModal'
import { Card } from '../../../components/Card.tsx'
import AddItem from '../../../components/AddItem.tsx'

const Supplies: FC = () => {
  const { id } = useParams<'id'>()

  const { data, refetch } = useDealSuppliesQuery({ variables: { id: id as string }, skip: !id })

  const deal = data?.deal

  const dealSupplies = useNodes(deal?.dealSupplies?.edges)

  const [formOpen, setFormOpen] = useState(false)

  if (!deal) return null

  return (
    <div>
      <div className='grid grid-cols-2 gap-6'>
        <Card className='col-span-full'>
          <AddItem onClick={() => setFormOpen(true)}>Новая поставка</AddItem>
        </Card>
        <Columns n={2}>
          {(dealSupplies || []).reverse().map((supply) => (
            <div key={supply?._id} className='mb-5'>
              <Supply supply={supply} dealId={parseInt(id as string)} customerId={deal?.customerCompany?._id} />
            </div>
          ))}
        </Columns>
      </div>

      <SuspenseModal open={formOpen} setOpen={setFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SupplyForm
            dealId={deal._id}
            onDone={() => {
              setFormOpen(false)
              refetch()
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Supplies
