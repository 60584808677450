import { FC } from 'react'
import {
  DocumentTypeEntity,
  NomenclatureType,
  SpecItem,
  useShipmentForEditFormSuspenseQuery,
  useUpdateShipmentMutation
} from '../../../graphql/schema'
import { convertDocumentTypeToNomenclatureType } from '../../../utils/UPD'
import type { UPDInfo } from '../../../utils/UPD'
import { GridTable, GridTableCell, GridTableRow } from '../../../components/Table'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { Card, CardHeader, CardTitle } from '../../../components/Card'
import { formatDecimal } from '../../../utils/formatNumber'
import { dateFormatter, getCalendarFormatDate, ISODateToCalendar } from '../../../utils/dateFormatter'
import SubmitButton from '../../../components/SubmitButton'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { parseDecimalFromMasked } from '../../../utils/parseDecimal'
import { IMaskInput, ReactMaskOpts } from 'react-imask'
import useUploadDocuments from '../../../components/DocumentManager/useUploadDocuments'
import { SHIPMENT_SPECS_FOLDER_NAME } from '../../../config'
import Specification from '../../../components/Specification'
import Tooltip from '../../../components/Tooltip'

interface ShipmentFormProps {
  supplyId?: number
  shipmentId?: number
  supplierINN?: string
  receiverINN?: string
  buyerINN?: string
  UPDInfo?: UPDInfo
  filesToUpload?: File[]
  onDone: () => void
  // TODO: add supplier and customer INNs to check against UPD
}

interface ShipmentFormValues {
  date: string
  amount: string
}

const numberMaskOptions: ReactMaskOpts = {
  mask: Number,
  thousandsSeparator: ' ',
  scale: 2
}

const ShipmentForm: FC<ShipmentFormProps> = ({
  shipmentId,
  onDone,
  supplierINN,
  receiverINN,
  buyerINN,
  UPDInfo,
  filesToUpload
}) => {
  const { data: existingShipmentData } = useShipmentForEditFormSuspenseQuery({
    variables: { id: shipmentId?.toString() as string },
    fetchPolicy: 'network-only'
  })
  const [updateShipment] = useUpdateShipmentMutation()
  const existingShipment = existingShipmentData?.shipment

  const uploadDocuments = useUploadDocuments({
    folderName: SHIPMENT_SPECS_FOLDER_NAME,
    entityType: DocumentTypeEntity.Shipment
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useForm<ShipmentFormValues>({
    defaultValues: {
      date: UPDInfo?.date
        ? ISODateToCalendar(UPDInfo.date.toISOString())
        : existingShipment?.date
          ? getCalendarFormatDate(new Date(existingShipment.date))
          : '',
      amount: UPDInfo?.totalCents
        ? formatDecimal(UPDInfo.totalCents)
        : existingShipment?.amount
          ? formatDecimal(existingShipment.amount)
          : ''
    }
  })

  const onSubmit: SubmitHandler<ShipmentFormValues> = async (data) => {
    if (!shipmentId) return

    const date = data.date ? new Date(data.date).toISOString() : undefined
    const amount = data.amount ? Math.round(parseDecimalFromMasked(data.amount) * 100) : undefined
    const nomenclatureType = UPDInfo?.documentType
      ? convertDocumentTypeToNomenclatureType(UPDInfo?.documentType)
      : undefined
    const nomenclatureDate = UPDInfo?.documentDate ? new Date(UPDInfo.documentDate).toISOString() : undefined

    await updateShipment({
      variables: {
        input: {
          id: shipmentId?.toString() as string,
          date,
          amount,
          nomenclatureType:
            existingShipment?.nomenclatureType === NomenclatureType.Invoice &&
            nomenclatureType === NomenclatureType.Nomenclature
              ? NomenclatureType.Upd
              : nomenclatureType,
          nomenclatureDate,
          nomenclatureNumber: UPDInfo?.documentId || undefined,
          nomenclature: (UPDInfo?.items as SpecItem[]) || undefined
        }
      }
    })

    if (filesToUpload?.length) {
      await uploadDocuments(filesToUpload, { entityId: shipmentId.toString() })
    }

    onDone?.()
  }

  const currency = 'RUB'

  const sellerIsDifferent = !!supplierINN && !!UPDInfo?.seller?.inn && UPDInfo.seller.inn !== supplierINN
  const buyerIsDifferent = !!buyerINN && !!UPDInfo?.buyer?.inn && UPDInfo?.buyer?.inn !== buyerINN
  const receiverIsDifferent = !!receiverINN && !!UPDInfo?.receiver?.inn && UPDInfo?.receiver?.inn !== receiverINN

  const wrongINNs = buyerIsDifferent || receiverIsDifferent

  return (
    <section className='min-w-[600px] max-w-[1200px] px-12 pb-12 pt-10'>
      <h1 className='mb-10 text-title-l font-medium'>Изменить отгрузку</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className='mb-10'>
          <CardHeader>
            <CardTitle>
              Документ отгрузки
              {UPDInfo?.documentId && ` №${UPDInfo.documentId}`}
              {UPDInfo?.documentDate && ` от ${dateFormatter.format(UPDInfo.documentDate)}`}
            </CardTitle>
          </CardHeader>
          <div className='grid gap-5 px-5 pb-5'>
            {UPDInfo && (
              <div className='rounded-md bg-surface-primary shadow-card'>
                <GridTable className='grid-cols-[auto_1fr]'>
                  <GridTableRow>
                    <GridTableCell className='text-labels-secondary'>Продавец</GridTableCell>
                    <GridTableCell>
                      <div className='flex items-center gap-x-5'>
                        {UPDInfo.seller?.name}
                        {sellerIsDifferent && (
                          <div className='relative'>
                            <Tooltip target={<WarningIcon className='text-base-red' />}>
                              ИНН отличается
                              <div>В поставке: {supplierINN}</div>
                              <div>В отгрузке: {UPDInfo?.seller?.inn}</div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className='text-body-s text-labels-secondary'>{UPDInfo.seller?.address}</div>
                    </GridTableCell>
                  </GridTableRow>
                  <GridTableRow>
                    <GridTableCell className='text-labels-secondary'>Покупатель</GridTableCell>
                    <GridTableCell>
                      <div className='flex items-center gap-x-5'>
                        {UPDInfo.buyer?.name}
                        {buyerIsDifferent && (
                          <div className='relative'>
                            <Tooltip target={<WarningIcon className='text-base-red' />}>
                              ИНН отличается
                              <div>В поставке: {buyerINN}</div>
                              <div>В отгрузке: {UPDInfo?.buyer?.inn}</div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className='text-body-s text-labels-secondary'>{UPDInfo.buyer?.address}</div>
                    </GridTableCell>
                  </GridTableRow>
                  <GridTableRow>
                    <GridTableCell className='text-labels-secondary'>Грузополучатель</GridTableCell>
                    <GridTableCell>
                      <div className='flex items-center gap-x-5'>
                        {UPDInfo.receiver?.name}
                        {receiverIsDifferent && (
                          <div className='relative'>
                            <Tooltip target={<WarningIcon className='text-base-red' />}>
                              ИНН отличается
                              <div>В поставке: {receiverINN}</div>
                              <div>В отгрузке: {UPDInfo?.receiver?.inn}</div>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                      <div className='text-body-s text-labels-secondary'>{UPDInfo.receiver?.address}</div>
                    </GridTableCell>
                  </GridTableRow>
                </GridTable>
              </div>
            )}
            <div className='rounded-md bg-surface-primary shadow-card'>
              <GridTable className='grid-cols-[auto_1fr]'>
                <GridTableRow>
                  <GridTableCell className='text-labels-secondary'>Дата приёмки</GridTableCell>

                  <div className='flex'>
                    <input
                      {...register('date')}
                      type='date'
                      data-error={errors.date ? 'true' : undefined}
                      className='flex-1 rounded-tr-md border-none bg-transparent ring-inset focus:border-none focus:ring-0 data-error:bg-tr-red'
                    />
                  </div>
                </GridTableRow>
                <GridTableRow>
                  <GridTableCell className='text-labels-secondary'>Сумма</GridTableCell>
                  <div className='flex'>
                    <Controller
                      control={control}
                      name='amount'
                      rules={{ required: true }}
                      render={({ field, formState: { errors } }) => (
                        <IMaskInput
                          value={field.value}
                          onAccept={(v) => field.onChange(v)}
                          {...numberMaskOptions}
                          type='text'
                          data-error={errors.amount ? 'true' : undefined}
                          placeholder='100 000,00'
                          className='flex-1 rounded-br-md border-none border-base-red bg-transparent ring-inset focus:border-none focus:ring-0 data-error:border-l-1 data-error:bg-tr-red'
                        />
                      )}
                    />
                  </div>
                </GridTableRow>
              </GridTable>
            </div>
            {UPDInfo && (
              <>
                <div className='grid grid-cols-[1fr_auto_auto] gap-y-5'>
                  {UPDInfo.base.map((b) => (
                    <GridTable
                      key={b.number}
                      className='col-span-full grid-cols-subgrid rounded-md bg-surface-primary shadow-card'
                    >
                      <GridTableRow>
                        <GridTableCell className='col-span-full text-labels-secondary'>Основание</GridTableCell>
                      </GridTableRow>
                      <GridTableRow>
                        <GridTableCell>{b.name}</GridTableCell>
                        <GridTableCell>{b.number}</GridTableCell>
                        <GridTableCell>{dateFormatter.format(b.date)}</GridTableCell>
                      </GridTableRow>
                    </GridTable>
                  ))}
                </div>

                <div className='rounded-md bg-surface-primary shadow-card'>
                  <Specification
                    items={UPDInfo.items as SpecItem[]}
                    currency={currency}
                    expectedTotalCents={UPDInfo.totalCents}
                    expectedTotalVATCents={UPDInfo.totalVATCents}
                    withPricePerUnitCentsWithoutVatCell={true}
                  />
                </div>
              </>
            )}
          </div>
        </Card>
        <SubmitButton disabled={UPDInfo && wrongINNs} loading={isSubmitting}>
          Сохранить отгрузку
        </SubmitButton>
      </form>
    </section>
  )
}

export default ShipmentForm
