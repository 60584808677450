import { FC, useEffect, useMemo } from 'react'
import { ReactComponent as SearchIcon } from '../../svg/icons/search.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { useAtsConversationsQuery } from '../../graphql/schema.tsx'
import { Card, CardHeader, CardTitle } from '../Card.tsx'
import useNodes from '../../hooks/useNodes.ts'
import useScrollPagination from '../../hooks/useScrollPagination.ts'
import { useCallHistoryFilter } from './useCallHistoryFilter.tsx'
import useUpdateEffect from '../../hooks/useUpdateEffect.ts'
import Call from './Call.tsx'

const dateFormatter = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric'
})
const dateFormatterWithYear = new Intl.DateTimeFormat('ru-RU', {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
})

interface CallsProps {
  phones: string[]
}

const Calls: FC<CallsProps> = ({ phones }) => {
  const { setFilterState, query, setQuery, variables } = useCallHistoryFilter()

  const {
    data: atsConversationsData,
    fetchMore,
    refetch
  } = useAtsConversationsQuery({
    fetchPolicy: 'cache-and-network',
    variables,
    skip: !variables?.phones?.length
  })
  const calls = useNodes(atsConversationsData?.atsConversations?.edges)

  const groupedCalls = useMemo(
    () =>
      calls?.reduce(
        (acc, call) => {
          const lastCallsGroup = acc[acc.length - 1]

          if (
            lastCallsGroup &&
            lastCallsGroup.date.setHours(0, 0, 0, 0) === new Date(call?.start).setHours(0, 0, 0, 0)
          ) {
            lastCallsGroup.data?.push(call)
          } else {
            acc.push({
              date: new Date(call?.start),
              data: [call]
            })
          }

          return acc
        },
        [] as { date: Date; data?: typeof calls }[]
      ),
    [calls]
  )

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    atsConversationsData?.atsConversations?.pageInfo
  )

  useEffect(() => {
    setFilterState({
      ...setFilterState(),
      phones
    })
  }, [phones, setFilterState])
  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  return (
    <div className='flex' ref={triggerRef}>
      <Card className='w-full'>
        <CardHeader>
          <CardTitle>Записи телефонных разговоров</CardTitle>
        </CardHeader>

        <div className='flex items-center gap-8 px-8 pb-8'>
          <div className='flex h-16 w-full items-center rounded-md bg-surface-primary'>
            <SearchIcon className='mx-5 text-labels-tertiary' />
            <input
              value={query}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 11)
                setQuery(e.target.value)
              }}
              className='flex-grow border-none placeholder-labels-tertiary outline-none ring-0 focus:ring-0'
              placeholder='Введите номер'
            />
          </div>
        </div>

        {!!groupedCalls?.length &&
          groupedCalls?.map((callsByDate) => (
            <div>
              <div className='px-10 pb-4 text-p200 font-medium text-labels-secondary'>
                {(() => {
                  const today = new Date()

                  const yesterday = new Date()
                  yesterday.setDate(yesterday.getDate() - 1)

                  if (callsByDate.date.getFullYear() !== today.getFullYear()) {
                    return dateFormatterWithYear.format(callsByDate.date)
                  } else if (callsByDate.date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
                    return `Сегодня, ${dateFormatter.format(callsByDate.date)}`
                  } else if (callsByDate.date.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0)) {
                    return `Вчера, ${dateFormatter.format(callsByDate.date)}`
                  } else {
                    return dateFormatter.format(callsByDate.date)
                  }
                })()}
              </div>
              <div className='flex flex-col gap-3 px-8 pb-8 empty:hidden'>
                {callsByDate?.data?.map((call) => <Call flat key={call.id} call={call} />)}
              </div>
            </div>
          ))}
        <div className='flex items-center justify-center pb-10 empty:hidden'>
          {isFetching && <LoadingIcon className='animate-spin self-center text-labels-tertiary' />}
        </div>
      </Card>
    </div>
  )
}

export default Calls
