import { FC, useCallback, useMemo, useState } from 'react'
import GridView, { GridViewConfig } from '../../../components/GridView'
import {
  CollectionPostponableType,
  CustomerAccountKind,
  LegalCollection,
  LegalCollectionState,
  Payment,
  PaymentCollectionProcessStatus,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  TaskTargetType,
  useCancelPaymentTransactionsMutation,
  useCollectionProcessesByPaymentIdsQuery,
  useCreateCollectionPostponeMutation,
  useCreatePaymentMutation,
  useCustomerAccountQuery,
  useDealForCollectionProcessQuery,
  useDealPenaltyAmountLazyQuery,
  useDeletePaymentMutation,
  useLegalCollectionsByPaymentIdsQuery,
  usePaymentsQuery,
  usePayPaymentMutation,
  UserRoles
} from '../../../graphql/schema'
import useNodes from '../../../hooks/useNodes'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatDecimal } from '../../../utils/formatNumber'
import PaymentActions, { PaymentActionsIcon, PaymentActionType } from '../Payments/PaymentActions'
import usePaymentsAccessCheck from '../Payments/usePaymentsAccessCheck'
import parseDecimal from '../../../utils/parseDecimal'
import SuspenseModal from '../../../components/SuspenseModal'
import PenaltyForm from '../Payments/PenaltyForm'
import TransactionsDateForm from '../Payments/TransactionsDateForm'
import CollectionStatusSwitcher from './CollectionStatusSwitcher'
import AddCollectionProcess from './AddCollectionProcess'
import Dropdown from '../../../components/Dropdown'
import TaskForm from '../../../components/Tasks/TaskForm'
import { ReactComponent as AddIcon } from '../../../svg/icons/add.svg'
import { ReactComponent as TimerIcon } from '../../../svg/ui/timer.svg'
import { daysBetween } from '../../../utils/dates'
import { Card } from '../../../components/Card.tsx'
import CreateLegalCollection from './Legal/CreateLegalCollection.tsx'
import AvatarTooltip from '../../../components/Avatar/AvatarTooltip.tsx'
import LegalCollectionUserForm from './Legal/LegalCollectionUserForm.tsx'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import LegalPostponeForm from './Legal/LegalPostponeForm.tsx'
import useCurrentUser from '../../../hooks/useCurrentUser.ts'
import SentLegalCollection from './Legal/SentLegalCollection.tsx'
import { LEASING_PAYMENT_KINDS } from '../../../utils/payment.ts'
import { env } from '../../../env.ts'

interface CollectionPaymentsProps {
  dealId: number
}

type shortcutTaskTarget = {
  isManager: boolean
  isRoleLegal: boolean
  taskComment: string
  date?: Date
}

const SHORTCUT_ALLOWED_STATUSES: PaymentCollectionProcessStatus[] = [
  PaymentCollectionProcessStatus.Warning,
  PaymentCollectionProcessStatus.Penalty,
  PaymentCollectionProcessStatus.Claim,
  PaymentCollectionProcessStatus.PreparingLawsuit,
  PaymentCollectionProcessStatus.Lawsuit
]

const staging = env.REACT_APP_ENVIRONMENT === 'staging'

const CollectionPayments: FC<CollectionPaymentsProps> = ({ dealId }) => {
  const currentUser = useCurrentUser()
  // 4 - ava, 24 - ao
  const canManageLegal = staging || currentUser?._id === 4 || currentUser?._id === 24

  const { data: dealData } = useDealForCollectionProcessQuery({ variables: { id: `${dealId}` }, skip: !dealId })
  const deal = dealData?.deal

  const hasAccessOperationalCollectionManage = useAccessCheck('deal.collection.operational_collection_manage')
  const hasAccessLegalCollectionManage = useAccessCheck('deal.collection.legal_collection_manage')

  const {
    canPaymentsManage,
    canPenaltyPaymentsCreate,
    canPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate,
    canPayPayment
  } = usePaymentsAccessCheck(deal?.status)

  const { data: mainCustomerAccountData, refetch: refetchMainCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Main },
    skip: !deal?.customerCompany?._id
  })

  const { data: penaltyCustomerAccountData, refetch: refetchPenaltyCustomerAccount } = useCustomerAccountQuery({
    variables: { customerCompanyId: `${deal?.customerCompany?._id}`, kind: CustomerAccountKind.Penalty },
    skip: !deal?.customerCompany?._id
  })

  const mainCustomerAccount = mainCustomerAccountData?.customerAccount
  const penaltyCustomerAccount = penaltyCustomerAccountData?.customerAccount

  const {
    data: leasingPaymentsData,
    refetch: refetchLeasingPayments,
    loading: leasingPaymentsLoading,
    error: leasingPaymentsError
  } = usePaymentsQuery({
    variables: {
      kinds: LEASING_PAYMENT_KINDS,
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })

  const leasingPayments = useNodes(leasingPaymentsData?.payments.edges)

  const { data: collectionProcessesData, refetch: refetchCollectionProcesses } =
    useCollectionProcessesByPaymentIdsQuery({
      variables: {
        paymentIds: leasingPayments.map((payment) => parseInt(payment.id))
      },
      skip: !leasingPayments.length
    })
  const collectionProcesses = useNodes(collectionProcessesData?.paymentCollectionProcesses?.edges)

  const { data: legalCollectionsData, refetch: refetchLegalCollections } = useLegalCollectionsByPaymentIdsQuery({
    variables: {
      paymentIds: leasingPayments.map((payment) => payment.id)
    },
    skip: !leasingPayments.length
  })
  const legalCollections = useNodes(legalCollectionsData?.legalCollections?.edges) as LegalCollection[]

  const flatLeasingPayments = useMemo(
    () => leasingPayments.flatMap((payment) => [payment, ...payment.penaltyPayments] as Payment[]),
    [leasingPayments]
  )

  const [createPayment] = useCreatePaymentMutation()

  const [penaltyForDeal] = useDealPenaltyAmountLazyQuery()

  const [deletePayment] = useDeletePaymentMutation()

  const [payPaymentMutation] = usePayPaymentMutation()

  const [createCollectionPostpone] = useCreateCollectionPostponeMutation()

  const [editTransactionsFormOpenByPaymentId, setEditTransactionsFormOpenByPaymentId] = useState<string>()
  const [penaltyFormOpenByPaymentId, setPenaltyFormOpenByPaymentId] = useState<string>()
  const [penaltyCalculationLoading, setPenaltyCalculationLoading] = useState(false)
  const [taskTargetState, setTaskTargetState] = useState<shortcutTaskTarget>()
  const [editLegalUserId, setEditLegalUserId] = useState<{ collectionId: string; userId: number }>()
  const [collectionPostponeFormByCollectionId, setCollectionPostponeFormByCollectionId] = useState<string>()

  const [cancelPaymentTransactions] = useCancelPaymentTransactionsMutation({
    onCompleted: () => {
      refetchMainCustomerAccount()
    }
  })

  const cancelPaymentTransactionsCallback = useCallback(
    async (payment: Payment) => {
      await cancelPaymentTransactions({
        variables: {
          input: {
            paymentId: payment.id
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          cancelPaymentTransactions: {
            __typename: 'CancelPaymentTransactionsPayload',
            payment: {
              ...payment,
              state: PaymentState.Pending
            }
          }
        }
      })
    },
    [cancelPaymentTransactions]
  )

  const payPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: mainCustomerAccount?.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchLeasingPayments(), refetchMainCustomerAccount()])
    },
    [mainCustomerAccount?.id, payPaymentMutation, refetchMainCustomerAccount, refetchLeasingPayments]
  )

  const payPenaltyPayment = useCallback(
    async (payment: { id: string }) => {
      if (!payment || !penaltyCustomerAccount?.id) return

      await payPaymentMutation({
        variables: {
          input: {
            customerAccountId: penaltyCustomerAccount.id,
            paymentId: payment.id
          }
        }
      })

      await Promise.allSettled([refetchLeasingPayments(), refetchPenaltyCustomerAccount()])
    },
    [penaltyCustomerAccount?.id, payPaymentMutation, refetchPenaltyCustomerAccount, refetchLeasingPayments]
  )

  const recountPenalty = useCallback(
    async (payment: { id: string; targetId: string }) => {
      if (!payment || !mainCustomerAccount?.id) return

      try {
        setPenaltyCalculationLoading(true)

        const { data: newPenaltyAmount } = await penaltyForDeal({ variables: { paymentId: payment.targetId } })

        await deletePayment({
          variables: {
            input: {
              id: payment.id
            }
          }
        })

        await createPayment({
          variables: {
            input: {
              amount: parseDecimal((newPenaltyAmount?.penaltyAmountForPayment.amount || '').toString()),
              date: new Date(),
              kind: PaymentKind.Penalty,
              targetId: payment.targetId,
              targetType: PaymentTargetType.Payment
            }
          }
        })

        await Promise.allSettled([refetchLeasingPayments()])
      } catch (e) {
        console.error(e)
      } finally {
        setPenaltyCalculationLoading(false)
      }
    },
    [mainCustomerAccount?.id, penaltyForDeal, deletePayment, createPayment, refetchLeasingPayments]
  )

  const canPayLeasingPaymentId = leasingPayments.find(
    (payment) => payment.kind === PaymentKind.Leasing && payment.state !== PaymentState.Paid
  )?.id

  const leasingPaymentsConfig = useMemo<GridViewConfig<Payment>>(() => {
    if (canManageLegal) {
      return {
        grid: 'grid-cols-collection',
        columns: [
          {
            title: '№',
            value: (p) => {
              if (p.kind === PaymentKind.Penalty) {
                return 'Пени'
              }
              return leasingPayments.findIndex((payment) => payment.id === p.id) + 1
            }
          },
          { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)) },
          {
            title: 'Просрочено',
            // нужно проводить проверку, что дней не 0, чтобы не выводить "0 дней"
            // в случае, если платеж был оплачен, смотрим на дату оплаты платежа и считаем просрочку, в противном случае считаем по нынешней дате
            value: (p) =>
              p.paidAt ? (
                <>
                  {daysBetween(new Date(p.date), new Date(p.paidAt)) > 0 && (
                    <>{daysBetween(new Date(p.date), new Date(p.paidAt))} дней</>
                  )}
                </>
              ) : (
                <>
                  {daysBetween(new Date(p.date), new Date()) > 0 && (
                    <>{daysBetween(new Date(p.date), new Date())} дней</>
                  )}
                </>
              )
          },
          {
            title: 'Cумма, ₽',
            value: (p) => (
              <div className='flex w-full items-center justify-between gap-10'>
                {formatDecimal(p.amount * 100)}
                <PaymentActionsIcon payment={p} />
              </div>
            )
          },
          {
            title: 'Оперативный коллекшн',
            value: (p) => {
              const paymentId = parseInt(p.id)
              const collectionProcess = collectionProcesses.find((cp) => cp.payment === paymentId)

              return (
                <div className='flex w-full items-center justify-between gap-8 px-7 text-p200'>
                  {p.kind !== PaymentKind.Penalty && (p.isOverdued || collectionProcess?.status) && (
                    <>
                      {collectionProcess ? (
                        <div className='flex items-center gap-6'>
                          <CollectionStatusSwitcher
                            disabled={!hasAccessOperationalCollectionManage}
                            status={collectionProcess.status}
                            collectionProcessId={collectionProcess.id}
                            onChangeStatus={(status) => {
                              if (status === PaymentCollectionProcessStatus.Warning) {
                                setTaskTargetState({
                                  taskComment: 'Просрочка, сегодня без пени',
                                  isManager: true,
                                  isRoleLegal: false
                                })
                              }
                              if (status === PaymentCollectionProcessStatus.Penalty) {
                                setTaskTargetState({
                                  taskComment: 'Направить счет на пени',
                                  isManager: true,
                                  isRoleLegal: false
                                })
                              }
                              if (status === PaymentCollectionProcessStatus.Claim) {
                                setTaskTargetState({
                                  taskComment: 'Претензия',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }
                              if (status === PaymentCollectionProcessStatus.PreparingLawsuit) {
                                setTaskTargetState({
                                  taskComment: 'Подготовка искового пугалки',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }
                              if (status === PaymentCollectionProcessStatus.Lawsuit) {
                                setTaskTargetState({
                                  taskComment: 'Исковое заявление в суд',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <AddCollectionProcess
                          disabled={!hasAccessOperationalCollectionManage}
                          dealId={`${dealId}`}
                          paymentId={paymentId}
                          onAddCollectionProcess={() => {
                            setTaskTargetState({
                              taskComment: 'Просрочка, сегодня без пени',
                              isManager: true,
                              isRoleLegal: false
                            })
                            refetchCollectionProcesses()
                          }}
                        />
                      )}
                    </>
                  )}

                  {collectionProcess && (
                    <Dropdown className='empty:hidden'>
                      <>
                        <button
                          className='flex w-[180px] cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-base-red'
                          onClick={() => {
                            setTaskTargetState({
                              taskComment: '',
                              isManager: false,
                              isRoleLegal: true
                            })
                          }}
                        >
                          <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                          Создать задачу
                        </button>
                        {canPenaltyPaymentsCreate && p.kind === PaymentKind.Leasing && (
                          <button
                            className='flex items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-base-red'
                            onClick={() => {
                              setPenaltyFormOpenByPaymentId(p.id)
                            }}
                          >
                            <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                            <span className='whitespace-nowrap'>Добавить пени</span>
                          </button>
                        )}
                      </>
                    </Dropdown>
                  )}
                </div>
              )
            },
            padding: false
          },
          {
            title: 'Юридический коллекшн',
            value: (p) => {
              const legalCollection = legalCollections.find((lc) => lc.paymentId === p.id)
              const actualPostpone =
                legalCollection?.collectionPostpones &&
                legalCollection?.collectionPostpones[legalCollection.collectionPostpones.length - 1]
              const canCreate =
                [PaymentKind.Leasing, PaymentKind.Body].includes(p.kind) &&
                p.state !== PaymentState.Paid &&
                p.isOverdued
              const postponed =
                legalCollection?.state &&
                legalCollection.state === LegalCollectionState.ThreatSent &&
                !!actualPostpone?.dueDate &&
                new Date(actualPostpone.dueDate) > new Date()

              return (
                <div className='relative flex w-full items-center justify-between gap-8 px-7 text-p200'>
                  {legalCollection ? (
                    <div className='flex w-full items-center justify-between'>
                      <SentLegalCollection
                        legalCollection={legalCollection}
                        postponeDueDate={postponed ? actualPostpone?.dueDate : undefined}
                      />
                      {!!legalCollection && (
                        <button
                          disabled={!hasAccessLegalCollectionManage}
                          onClick={() =>
                            setEditLegalUserId({
                              collectionId: `${legalCollection?.id}`,
                              userId: parseInt(`${legalCollection?.legalUserId}`)
                            })
                          }
                        >
                          <AvatarTooltip
                            width='w-10'
                            height='h-10'
                            userId={parseInt(`${legalCollection?.legalUserId}`)}
                          />
                        </button>
                      )}
                    </div>
                  ) : (
                    canCreate && (
                      <CreateLegalCollection
                        disabled={!hasAccessLegalCollectionManage}
                        paymentId={p.id}
                        onDone={() => {
                          refetchLegalCollections()
                        }}
                      />
                    )
                  )}

                  {legalCollection && (
                    <Dropdown className='empty:hidden'>
                      <>
                        <button
                          className='flex w-[185px] cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-base-red'
                          onClick={() => {
                            setTaskTargetState({
                              taskComment: '',
                              isManager: false,
                              isRoleLegal: true
                            })
                          }}
                        >
                          <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                          Создать задачу
                        </button>
                        {legalCollection?.state === LegalCollectionState.ThreatSent && (
                          <button
                            className='flex w-full cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-base-red'
                            onClick={async () => {
                              if (legalCollection?.id) {
                                if (postponed) {
                                  await createCollectionPostpone({
                                    variables: {
                                      input: {
                                        postponableId: legalCollection.id,
                                        postponableType: CollectionPostponableType.LegalCollection,
                                        dueDate: new Date()
                                      }
                                    }
                                  })
                                  refetchLegalCollections()

                                  return
                                }

                                setCollectionPostponeFormByCollectionId(legalCollection.id)
                              }
                            }}
                          >
                            <TimerIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                            {postponed ? 'Вернуть в работу' : 'Отложить'}
                          </button>
                        )}
                      </>
                    </Dropdown>
                  )}
                </div>
              )
            },
            padding: false
          }
        ]
      }
    }

    return {
      grid: 'grid-cols-collection-s',
      columns: [
        {
          title: '№',
          value: (p) => {
            if (p.kind === PaymentKind.Penalty) {
              return 'Пени'
            }
            return leasingPayments.findIndex((payment) => payment.id === p.id) + 1
          }
        },
        { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)) },
        {
          title: 'Просрочено',
          // нужно проводить проверку, что дней не 0, чтобы не выводить "0 дней"
          // в случае, если платеж был оплачен, смотрим на дату оплаты платежа и считаем просрочку, в противном случае считаем по нынешней дате
          value: (p) =>
            p.paidAt ? (
              <>
                {daysBetween(new Date(p.date), new Date(p.paidAt)) > 0 && (
                  <>{daysBetween(new Date(p.date), new Date(p.paidAt))} дней</>
                )}
              </>
            ) : (
              <>
                {daysBetween(new Date(p.date), new Date()) > 0 && <>{daysBetween(new Date(p.date), new Date())} дней</>}
              </>
            )
        },
        {
          title: 'Cумма, ₽',
          value: (p) => formatDecimal(p.amount * 100)
        },
        {
          title: 'Оперативный коллекшн',
          value: (p) => {
            const paymentId = parseInt(p.id)
            const collectionProcess = collectionProcesses.find((cp) => cp.payment === paymentId)

            return (
              <div className='px-7'>
                {p.kind !== PaymentKind.Penalty && (p.isOverdued || collectionProcess?.status) && (
                  <>
                    {collectionProcess ? (
                      <div className='flex items-center gap-6'>
                        <CollectionStatusSwitcher
                          disabled={!hasAccessOperationalCollectionManage}
                          status={collectionProcess.status}
                          collectionProcessId={collectionProcess.id}
                          onChangeStatus={(status) => {
                            if (status === PaymentCollectionProcessStatus.Warning) {
                              setTaskTargetState({
                                taskComment: 'Просрочка, сегодня без пени',
                                isManager: true,
                                isRoleLegal: false
                              })
                            }
                            if (status === PaymentCollectionProcessStatus.Penalty) {
                              setTaskTargetState({
                                taskComment: 'Направить счет на пени',
                                isManager: true,
                                isRoleLegal: false
                              })
                            }
                            if (status === PaymentCollectionProcessStatus.Claim) {
                              setTaskTargetState({
                                taskComment: 'Претензия',
                                isManager: false,
                                isRoleLegal: true
                              })
                            }
                            if (status === PaymentCollectionProcessStatus.PreparingLawsuit) {
                              setTaskTargetState({
                                taskComment: 'Подготовка искового пугалки',
                                isManager: false,
                                isRoleLegal: true
                              })
                            }
                            if (status === PaymentCollectionProcessStatus.Lawsuit) {
                              setTaskTargetState({
                                taskComment: 'Исковое заявление в суд',
                                isManager: false,
                                isRoleLegal: true
                              })
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <AddCollectionProcess
                        disabled={!hasAccessOperationalCollectionManage}
                        dealId={`${dealId}`}
                        paymentId={paymentId}
                        onAddCollectionProcess={() => {
                          setTaskTargetState({
                            taskComment: 'Просрочка, сегодня без пени',
                            isManager: true,
                            isRoleLegal: false
                          })
                          refetchCollectionProcesses()
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            )
          },
          padding: false
        },
        {
          value: (p) => <PaymentActionsIcon payment={p} />
        },
        {
          value: (p) => {
            const paymentId = parseInt(p.id)
            const collectionProcess = collectionProcesses.find((cp) => cp.payment === paymentId)

            return (
              <Dropdown className='empty:hidden'>
                <>
                  <PaymentActions
                    payment={p}
                    hasPenalties={Boolean(p?.penaltyPayments?.length)}
                    canPaymentsManage={canPaymentsManage}
                    canPenaltyCreate={canPenaltyPaymentsCreate}
                    canPenaltyDelete={canPenaltyPaymentsDelete}
                    canEditTransactionsDate={canPaymentsEditTransactionsDate}
                    canPayPayment={canPayPayment && p.id === canPayLeasingPaymentId}
                    triggerAction={async (type) => {
                      switch (type) {
                        case PaymentActionType.CancelPaymentTransactions:
                          await cancelPaymentTransactionsCallback(p)
                          break
                        case PaymentActionType.EditPaymentTransactions:
                          setEditTransactionsFormOpenByPaymentId(p.id)
                          break
                        case PaymentActionType.OpenPenaltyModal:
                          setPenaltyFormOpenByPaymentId(p.id)
                          break
                        case PaymentActionType.PayPenaltyPayment:
                          await payPenaltyPayment(p)
                          break
                        case PaymentActionType.PayPayment:
                          await payPayment(p)
                          break
                        case PaymentActionType.RecountPenalty:
                          await recountPenalty(p)
                          break
                      }
                    }}
                  />
                  <>
                    {collectionProcess && SHORTCUT_ALLOWED_STATUSES.includes(collectionProcess.status) && (
                      <div>
                        {(collectionProcess.status === PaymentCollectionProcessStatus.Warning ||
                          collectionProcess.status === PaymentCollectionProcessStatus.Penalty) && (
                          <button
                            className='flex w-[270px] cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                            onClick={() => {
                              collectionProcess.status === PaymentCollectionProcessStatus.Warning
                                ? setTaskTargetState({
                                    taskComment: 'Просрочка, сегодня без пени',
                                    isManager: true,
                                    isRoleLegal: false
                                  })
                                : setTaskTargetState({
                                    taskComment: 'Направить счет на пени',
                                    isManager: true,
                                    isRoleLegal: false
                                  })
                            }}
                          >
                            <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                            Создать задачу менеджеру
                          </button>
                        )}
                        {collectionProcess.status === PaymentCollectionProcessStatus.Claim && (
                          <div className='flex w-[240px] flex-col'>
                            <button
                              className='flex cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                              onClick={() => {
                                setTaskTargetState({
                                  taskComment: 'Претензия',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }}
                            >
                              <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                              Создать задачу юристу
                            </button>
                            <button
                              className='flex cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                              onClick={() => {
                                setTaskTargetState({
                                  taskComment: 'Пени',
                                  isManager: false,
                                  isRoleLegal: false
                                })
                              }}
                            >
                              <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                              Создать задачу себе
                            </button>
                          </div>
                        )}
                        {collectionProcess.status === PaymentCollectionProcessStatus.PreparingLawsuit && (
                          <div className='flex w-[240px] flex-col'>
                            <button
                              className='flex cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                              onClick={() => {
                                setTaskTargetState({
                                  taskComment: 'Подготовка искового пугалки',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }}
                            >
                              <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                              Создать задачу юристу
                            </button>
                          </div>
                        )}
                        {collectionProcess.status === PaymentCollectionProcessStatus.Lawsuit && (
                          <div className='flex w-[240px] flex-col'>
                            <button
                              className='flex cursor-pointer items-center py-5 pl-5 pr-10 text-grayscale-200 hover:text-red-100'
                              onClick={() => {
                                setTaskTargetState({
                                  taskComment: 'Исковое заявление в суд',
                                  isManager: false,
                                  isRoleLegal: true
                                })
                              }}
                            >
                              <AddIcon className='ml-[3px] mr-8' height='14px' width='14px' />
                              Создать задачу юристу
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </>
              </Dropdown>
            )
          }
        }
      ]
    }
  }, [
    canManageLegal,
    leasingPayments,
    collectionProcesses,
    hasAccessOperationalCollectionManage,
    dealId,
    canPenaltyPaymentsCreate,
    refetchCollectionProcesses,
    legalCollections,
    hasAccessLegalCollectionManage,
    refetchLegalCollections,
    createCollectionPostpone,
    canPaymentsManage,
    canPenaltyPaymentsDelete,
    canPaymentsEditTransactionsDate,
    canPayPayment,
    canPayLeasingPaymentId,
    cancelPaymentTransactionsCallback,
    payPenaltyPayment,
    payPayment,
    recountPenalty
  ])

  return (
    <Card>
      <GridView
        data={flatLeasingPayments}
        config={leasingPaymentsConfig}
        loading={leasingPaymentsLoading || penaltyCalculationLoading}
        error={leasingPaymentsError?.message}
      />
      <SuspenseModal open={Boolean(taskTargetState)} setOpen={() => setTaskTargetState(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <TaskForm
            targetType={TaskTargetType.Deal}
            childTargetType={TaskTargetType.Collection}
            targetId={dealId}
            comment={taskTargetState?.taskComment}
            managerId={taskTargetState?.isManager ? deal?.user?._id : undefined}
            automaticDate={taskTargetState?.date ? taskTargetState.date : new Date()}
            automaticTime={'10:00'}
            automaticNotifications={true}
            onCreateTask={() => {
              setTaskTargetState(undefined)
            }}
            role={taskTargetState?.isRoleLegal ? UserRoles.RoleLegal : undefined}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!penaltyFormOpenByPaymentId} setOpen={() => setPenaltyFormOpenByPaymentId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <PenaltyForm
            paymentId={penaltyFormOpenByPaymentId as string}
            onDone={() => {
              refetchLeasingPayments()
              setPenaltyFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal
        open={!!editTransactionsFormOpenByPaymentId}
        setOpen={() => setEditTransactionsFormOpenByPaymentId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <TransactionsDateForm
            paymentId={editTransactionsFormOpenByPaymentId as string}
            onDone={() => {
              refetchLeasingPayments()
              setEditTransactionsFormOpenByPaymentId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!editLegalUserId} setOpen={() => setEditLegalUserId(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <LegalCollectionUserForm
            collectionId={editLegalUserId?.collectionId}
            userId={editLegalUserId?.userId}
            onDone={() => {
              setEditLegalUserId(undefined)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal
        open={!!collectionPostponeFormByCollectionId}
        setOpen={() => setCollectionPostponeFormByCollectionId(undefined)}
      >
        <div className='z-10 rounded-xl bg-white-0'>
          <LegalPostponeForm
            postponableId={collectionPostponeFormByCollectionId}
            onDone={() => {
              setCollectionPostponeFormByCollectionId(undefined)
              refetchLegalCollections()
            }}
          />
        </div>
      </SuspenseModal>
    </Card>
  )
}

export default CollectionPayments
