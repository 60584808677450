import { makeVar, useReactiveVar } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { AtsConversationsQueryVariables, useUserByIdLazyQuery } from '../../graphql/schema'
import { throttle } from 'throttle-debounce'

export interface CallHistoryFilterState {
  query?: string
  phones?: string[]
  date?: string
  callType?: 'all' | 'noanswer'
}

const callHistoryFilterStateVar = makeVar<CallHistoryFilterState>({
  callType: 'all'
})
const callHistoryQueryVar = makeVar<string>('')
const callHistoryUserIdVar = makeVar<number | undefined>(undefined)

const setQuery = throttle(500, (query: string) => {
  callHistoryFilterStateVar({
    ...callHistoryFilterStateVar(),
    query
  })
})

export const useCallHistoryFilter = () => {
  const filterState = useReactiveVar(callHistoryFilterStateVar)
  const userId = useReactiveVar(callHistoryUserIdVar)
  const query = useReactiveVar(callHistoryQueryVar)

  const [getUser] = useUserByIdLazyQuery()

  useEffect(() => {
    if (userId) {
      getUser({
        variables: {
          id: `${userId}`
        }
      }).then((res) => {
        if (res?.data?.user?.phone) {
          callHistoryFilterStateVar({
            ...callHistoryFilterStateVar(),
            phones: [res.data.user.phone.replace(/\D/g, '')]
          })
        }
      })
    }
  }, [getUser, userId])

  useEffect(() => {
    setQuery(query)
  }, [query])

  const variables = useMemo<AtsConversationsQueryVariables>((): AtsConversationsQueryVariables => {
    if (filterState?.query?.length && filterState.query.length > 4) {
      let query = filterState.query
      if (query[0] === '8') {
        query = '7' + query.slice(1)
      }
      return {
        phones: [query]
      }
    }
    return {
      phones: filterState.phones,
      status: filterState.callType === 'all' ? undefined : 'noanswer',
      start: filterState?.date ? new Date(filterState.date).toISOString() : undefined
    }
  }, [filterState])

  return {
    filterState,
    setFilterState: callHistoryFilterStateVar,
    variables,
    query,
    setQuery: callHistoryQueryVar,
    userId,
    setUserId: callHistoryUserIdVar
  }
}
