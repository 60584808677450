import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReactComponent as LoadingIcon } from '../../svg/ui/refresh.svg'
import { ReactComponent as AppScoringStatusPendingIcon } from '../../svg/icons/appScoringStatusPending.svg'
import {
  CompanyAnalysisStatus,
  useCompanyForScoringQuery,
  useCompanyAnalysesQuery,
  useCreateCompanyAnalysisMutation
} from '../../graphql/schema.tsx'
import { CompanyData } from '../../types/dadata.ts'
import { isIP } from '../../utils/contractUtils.ts'
import { Card, CardAccentButton, CardHeader, CardMenu } from '../../components/Card.tsx'
import CompanyAnalysisCard from './CompanyAnalysisCard.tsx'

const Scoring: FC = () => {
  const { id } = useParams<'id'>()

  const [openTab, setOpenTab] = useState<number | undefined>(0)

  const { data, refetch, loading } = useCompanyAnalysesQuery({
    variables: {
      companyId: parseInt(`${id}`)
    },
    skip: !id
  })
  const companyAnalyses = data?.companyAnalyses

  const { data: customerForScoringData } = useCompanyForScoringQuery({
    variables: { id: `${id}` },
    skip: !id
  })
  const company = customerForScoringData?.company
  const companyDadata: CompanyData = useMemo(() => (company?.dadata ? company.dadata.data : {}), [company?.dadata])
  const IP: boolean = isIP(companyDadata)

  const [createCompanyAnalysis, { loading: createScoringLoading }] = useCreateCompanyAnalysisMutation()

  const handleCreateAnalysis = async () => {
    await createCompanyAnalysis({
      variables: {
        input: {
          companyId: parseInt(`${id}`),
          status: CompanyAnalysisStatus.InProgress
        }
      }
    })
    refetch()
  }

  const canCreateScoring =
    !companyAnalyses?.length || !companyAnalyses.find((s) => s.status !== CompanyAnalysisStatus.Done)

  return (
    <div className='flex flex-col gap-4'>
      {canCreateScoring && !loading && (
        <Card>
          <CardHeader>
            <CardMenu>
              <CardAccentButton className='py-3' type='button' onClick={handleCreateAnalysis}>
                {createScoringLoading ? <LoadingIcon className='w-8 animate-spin' /> : <AppScoringStatusPendingIcon />}
                Запустить скоринг
              </CardAccentButton>
            </CardMenu>
          </CardHeader>
        </Card>
      )}

      {companyAnalyses?.map((companyAnalysis, i) => {
        const open = openTab === i

        return (
          <CompanyAnalysisCard
            key={companyAnalysis.id}
            companyIP={IP}
            companyAnalysisId={companyAnalysis.id}
            open={open}
            setOpen={() => setOpenTab((tab) => (tab === i ? undefined : i))}
          />
        )
      })}
    </div>
  )
}

export default Scoring
