import { FC } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import SubmitButton from '../../../../components/SubmitButton'
import Input from '../../../../components/Input.tsx'
import { getCalendarFormatDate } from '../../../../utils/dateFormatter.ts'

interface SentLegalCollectionDateFormProps {
  onDone: (date: string) => void
}

interface Inputs {
  date: string
}

const SentLegalCollectionDateForm: FC<SentLegalCollectionDateFormProps> = ({ onDone }) => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<Inputs>({
    defaultValues: {
      date: getCalendarFormatDate(new Date())
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!data?.date) return
    onDone(data.date)
  }

  return (
    <section className='overflow-x-hidden p-12 md:w-[448px]'>
      <h1 className='mb-12 font-display text-h200'>Укажите дату отправки</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-12'>
          <Input
            type='date'
            {...register('date', {
              required: true
            })}
            error={errors.date}
          />
        </div>

        <SubmitButton>Подтвердить завершение этапа</SubmitButton>
      </form>
    </section>
  )
}

export default SentLegalCollectionDateForm
