import { FC } from 'react'
import { BaseHistoryEventProps } from '../..'
import { EventActedAt, EventInitiatedBy } from '../EventComponents'
import { scoringStatusDict } from '../../../../utils/dictionaries'
import { ScoringAnalysisStatus } from '../../../../graphql/schema'

export interface ApplicationStatusUpdatedEventProps extends BaseHistoryEventProps {
  data?: {
    diff: {
      status: {
        after: string
        before: string
      }
    }
  }
}

const ScoringStatusUpdatedEvent: FC<ApplicationStatusUpdatedEventProps> = ({
  actedAt,
  data,
  withLine,
  initiatedByUserId
}) => {
  return (
    <div className='flex gap-6'>
      <EventActedAt date={actedAt} withLine={withLine} />
      <div>
        <div className='flex items-start gap-2'>
          <EventInitiatedBy userId={initiatedByUserId} />
          <span className='text-labels-tertiary'>сменил(а) статус "Скоринга"</span>
        </div>
        <div>
          {scoringStatusDict[data?.diff.status.before.toUpperCase() as ScoringAnalysisStatus]} →{' '}
          {scoringStatusDict[data?.diff.status.after.toUpperCase() as ScoringAnalysisStatus]}
        </div>
      </div>
    </div>
  )
}

export default ScoringStatusUpdatedEvent
