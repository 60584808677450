import { FC, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CommentTargetType,
  TaskTargetType,
  CustomerAccountKind,
  useCustomerByIdQuery,
  useRefreshFromDadataCustomerCompanyMutation,
  useUpdateCustomerEdOsMutation
} from '../../graphql/schema'
import { Address, CompanyData } from '../../types/dadata'
import useNodes from '../../hooks/useNodes'
import CompanyInfo from '../../components/CompanyInfo'
import EDO, { EDOCallback } from '../../components/EDO'
import CustomerCompanyBalance from '../../components/CustomerCompanyBalance/index.tsx'
import CompanyCheckFinmon from '../../components/Finmon/Company/CompanyCheckFinmon'
import ContactInfo, { ContactInfoKind } from '../../components/ContactInfo/ContactInfo'
import useAccessCheck from '../../hooks/useAccessCheck'
import Requisites from '../../components/Requisites/index'
import Comments from '../../components/Comments/Comments'
import Tasks from '../../components/Tasks'
import getUniqueItemsByProperties from '../../utils/getUniqueItemsByProperties.ts'
import useCustomerContactInfos from '../../hooks/useCustomerContactInfos.ts'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../components/Card.tsx'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import Avatar from '../../components/Avatar/index.tsx'
import getFullName from '../../utils/getFullName.ts'
import Modal from '../../components/Modal.tsx'
import CustomerManagerForm from './CustomerManagerForm.tsx'
import { isIP } from '../../utils/contractUtils.ts'
import usePersonAddress from '../../hooks/usePersonAddress.ts'
import Calls from '../../components/Calls'
import useCurrentUser from '../../hooks/useCurrentUser.ts'
import { env } from '../../env.ts'

const staging = env.REACT_APP_ENVIRONMENT === 'staging'

const CustomerInfo: FC = () => {
  const { id } = useParams<'id'>()

  const { data, refetch, loading } = useCustomerByIdQuery({ variables: { id: id as string }, skip: !id })
  const customer = data?.customerCompany
  const requisites = useNodes(customer?.company?.bankDetails?.edges) || []

  const selectedEDOs = useNodes(customer?.edoTypes?.edges)

  const deals = useNodes(customer?.deals?.edges)
  const dealIds = deals?.map((o) => o._id)

  const customerContactInfos = useCustomerContactInfos(dealIds, id)

  const customerUniqueContacts = getUniqueItemsByProperties(customerContactInfos, ['fio', 'email', 'phone'])

  const dadata: CompanyData = customer?.company?.dadata ? customer.company.dadata.data : {}
  const address: Address | undefined = dadata?.address

  const IP: boolean = isIP(dadata)

  const IPfactAddress = usePersonAddress(customer?.company?.inn || '')

  const [refreshDadataMutation, { loading: loadingRefreshDadata }] = useRefreshFromDadataCustomerCompanyMutation()
  const [updateCustomerEDOs] = useUpdateCustomerEdOsMutation()

  const hasAccessEdoManage = useAccessCheck('customer_company.edo_type.manage')
  const hasAccessCustomerManagerManage = useAccessCheck('customer_company.manager.manage')

  async function refreshDadata() {
    if (!customer?.company?.inn) return
    await refreshDadataMutation({ variables: { input: { inn: customer?.company?.inn } } })
    refetch()
  }

  const onEDOChange = useCallback<EDOCallback>(
    (data) => {
      if (!customer?._id) return

      const edos = data.edos === undefined ? selectedEDOs : data.edos
      const customEDO = data.customEDO === undefined ? customer?.edoCustomValue : data.customEDO
      const edoStatus = data.enabled === undefined ? customer?.edoStatus : data.enabled

      updateCustomerEDOs({
        variables: {
          input: {
            id: `${customer._id}`,
            edoTypes: edos && edos.map((o) => `${o._id}`),
            //@ts-ignore
            edoCustomValue: customEDO,
            //@ts-ignore
            edoStatus: edoStatus
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateCustomerCompany: {
            __typename: 'updateCustomerCompanyPayload',
            customerCompany: {
              __typename: 'CustomerCompany',
              _id: customer?._id,
              // @ts-ignore
              edoStatus: edoStatus,
              edoTypes: {
                __typename: 'EdoTypeConnection',
                edges:
                  edos?.map((o) => ({
                    __typename: 'EdoTypeEdge',
                    node: {
                      ...o,
                      __typename: 'EdoType'
                    }
                  })) || []
              },
              //@ts-ignore
              edoCustomValue: customEDO
            }
          }
        }
      })
    },
    [customer?._id, customer?.edoCustomValue, customer?.edoStatus, selectedEDOs, updateCustomerEDOs]
  )

  const [managersFormOpen, setManagersFormOpen] = useState(false)

  const currentUser = useCurrentUser()
  const canViewCalls = staging || currentUser?._id === 4 || currentUser?._id === 24

  return (
    <div className='grid grid-cols-2 gap-6'>
      <div className='col-span-2 grid grid-cols-3 gap-x-6'>
        <CustomerCompanyBalance title='Основной баланс' customerId={id} kind={CustomerAccountKind.Main} />

        <CustomerCompanyBalance title='Баланс пеней' customerId={id} kind={CustomerAccountKind.Penalty} />

        <Card>
          <CardHeader>
            <CardTitle>Менеджер по лизингу</CardTitle>
            <CardMenu>
              {hasAccessCustomerManagerManage && (
                <CardIconButton
                  onClick={() => {
                    setManagersFormOpen(true)
                  }}
                >
                  <EditIcon />
                </CardIconButton>
              )}
            </CardMenu>
          </CardHeader>
          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <div className='w-full px-6 py-4'>
                <div className='flex items-center'>
                  {!!customer?.customerManagerUser && (
                    <Avatar className='mr-5' userId={customer?.customerManagerUser?._id} />
                  )}
                  {getFullName(customer?.customerManagerUser) || '—'}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className='col-span-1 flex flex-col gap-6'>
        <CompanyInfo
          dadata={dadata}
          companyName={customer?.company?.dadata?.value}
          refreshDadata={refreshDadata}
          loading={loadingRefreshDadata || loading}
          lastUpdateName={customer?.company?.lastDadataRequestUser?.name}
          lastUpdateDate={customer?.company?.lastDadataRequestDate}
        />

        <Card>
          <CardHeader>
            <CardTitle>Адреса</CardTitle>
          </CardHeader>

          <div className='px-5 pb-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <table className='w-full'>
                <tbody>
                  <tr className='group'>
                    <td className='w-70 rounded-l-xl p-6'>Юридический адрес</td>
                    <td className='rounded-r-xl p-6'>{IP ? IPfactAddress : address?.unrestricted_value}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>

        <Requisites
          targetId={customer?.company?._id || 0}
          targetType='company'
          requisites={requisites}
          onDone={() => refetch()}
        />
      </div>

      <div className='col-span-1 flex flex-col gap-6'>
        <Tasks targetId={customer?._id} targetType={TaskTargetType.Customer} />

        <CompanyCheckFinmon inn={customer?.company?.inn || ''} />

        <EDO
          enabled={customer?.edoStatus}
          selectedEDOs={selectedEDOs}
          onChange={onEDOChange}
          customEDO={customer?.edoCustomValue}
          hasAccess={hasAccessEdoManage}
        />

        <Comments targetId={customer?._id || 0} targetType={CommentTargetType.CustomerCompany} />

        <ContactInfo
          onDone={() => refetch()}
          contactInfos={customerUniqueContacts}
          targetId={customer?._id || 0}
          targetType={ContactInfoKind.CustomerCompany}
          abilityCreate='customer_company.contact_info.create'
          abilityEdit='customer_company.contact_info.edit'
          title='Контакты клиента'
        />

        {canViewCalls && <Calls phones={customerUniqueContacts?.map((c) => `${c.phone?.replace(/\D/g, '')}`)} />}
      </div>

      <Modal open={managersFormOpen} setOpen={setManagersFormOpen}>
        <div className='z-10 rounded-xl bg-white-0'>
          <CustomerManagerForm
            customerId={id || ''}
            managerId={customer?.customerManagerUser?._id.toString() || ''}
            onDone={() => {
              setManagersFormOpen(false)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CustomerInfo
