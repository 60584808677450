import { FC, useState } from 'react'
import { ReactComponent as CallInIcon } from '../../svg/ui/call-in.svg'
import { ReactComponent as CallOutIcon } from '../../svg/ui/call-out.svg'
import { ReactComponent as ContactIcon } from '../../svg/ui/contact.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as FolderDownloadIcon } from '../../svg/ui/folder-download.svg'
import { dateFormatter, dateTimeFormatter, timeFormatter } from '../../utils/dateFormatter.ts'
import { formatPhoneNumber } from '../../utils/formatPhoneNumber.ts'
import CallAudioPlayer from './CallAudioPlayer.tsx'
import Tooltip from '../Tooltip.tsx'
import c from 'clsx'

export type Call = {
  id: string
  uid: string
  createdAt: string
  status?: string
  start?: string
  callType?: string
  clientPhone?: string
  duration?: number
  record?: string
  userName?: string
  userPhone?: string
}

interface CallProps {
  call: Call
  flat?: boolean
}

const Call: FC<CallProps> = ({ call, flat = false }) => {
  const [downloading, setDownloading] = useState(false)

  const download = async () => {
    if (downloading || !call?.record) return

    setDownloading(true)
    try {
      const response = await fetch(call.record)

      if (!response.ok) throw new Error('Ошибка загрузки')

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute(
        'download',
        `Запись телефонного разговора ${call.userName}${call?.start && ' от ' + dateFormatter.format(new Date(call.start))}.mp3`
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } finally {
      setDownloading(false)
    }
  }

  const callIconColor = call?.status === 'success' ? 'text-base-green' : 'text-base-red'

  return (
    <div
      className={c(
        'grid items-center justify-between rounded-lg bg-surface-primary py-3 shadow-card',
        flat
          ? 'grid-cols-[auto_auto_auto_auto_auto_auto] gap-x-2 px-6'
          : 'grid-cols-[auto_auto_1fr_1fr_auto_auto] gap-x-20 px-10'
      )}
    >
      {call?.callType === 'in' ? <CallInIcon className={callIconColor} /> : <CallOutIcon className={callIconColor} />}

      <div className='font-medium'>
        {call?.start &&
          (flat ? timeFormatter.format(new Date(call.start)) : dateTimeFormatter.format(new Date(call.start)))}
      </div>

      <div className='flex items-center'>
        <div className='mr-3 flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-separators-tertiary text-labels-secondary'>
          <Tooltip target={<ContactIcon />}>{call?.userName || call?.userPhone}</Tooltip>
        </div>
        {!flat && (call?.userName || call?.userPhone)}
      </div>

      <div className='flex items-center'>
        {!flat && (
          <div className='mr-4 flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-separators-tertiary text-labels-secondary'>
            <ContactIcon />
          </div>
        )}
        {formatPhoneNumber('+' + call?.clientPhone)}
      </div>

      <CallAudioPlayer record={call?.record} duration={call?.duration} />

      <button
        type='button'
        className='rounded-md bg-surface-secondary px-5 py-4 text-labels-tertiary hover:text-labels-secondary'
        disabled={downloading}
        onClick={download}
      >
        {downloading ? (
          <LoadingIcon width={16} height={16} className='animate-spin' />
        ) : (
          <FolderDownloadIcon width={16} height={16} />
        )}
      </button>
    </div>
  )
}

export default Call
