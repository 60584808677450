import { DocumentsQuery, DocumentTypeEntity, useDocumentsLazyQuery } from '../../../../graphql/schema.tsx'
import { NodeType } from '../../../../types'
import { suspend } from 'suspend-react'

interface DocumentsQueryVars {
  entityIds: (number | string)[]
  entityType: DocumentTypeEntity
  folderName: string
}

const useMultipleEntitySupplyDocs = ({ entityIds, entityType, folderName }: DocumentsQueryVars) => {
  const [getDocuments] = useDocumentsLazyQuery()

  return suspend(async () => {
    const docs: { [key: string]: NodeType<DocumentsQuery['documents']>[] } = {}

    await Promise.all(
      entityIds.map((entityId) =>
        getDocuments({
          variables: {
            filter: {
              entityType,
              type: folderName,
              entityId: `${entityId}`
            }
          }
        })
      )
    ).then((res) => {
      res.forEach(({ data }, index) => {
        if (data?.documents?.edges?.length) {
          docs[entityIds[index]] = data?.documents?.edges?.map((edge) => edge?.node).filter((n) => !!n)
        }
      })
    })

    return docs
  }, [entityIds.join(), entityType, folderName])
}

export default useMultipleEntitySupplyDocs
