import { FC, useMemo, useState } from 'react'
import { ReactComponent as PayIcon } from '../../../svg/icons/payout.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { ApolloError } from '@apollo/client'
import { SubmitHandler, useForm } from 'react-hook-form'
import Textarea from '../../../components/Textarea'
import {
  BankOrderTargetType,
  BankOrderState,
  BankOrdersQuery,
  BankOrdersQueryVariables,
  FeraBankAccount,
  BankOrdersDocument,
  useCreateBankOrderMutation,
  useDealSupplySuspenseQuery,
  useFeraBankAccountsSuspenseQuery,
  useFundingSourcesByDealIdSuspenseQuery
} from '../../../graphql/schema'
import c from 'clsx'
import { handleBackendErrorsToForm } from '../../../utils/backendErrorUtils'
import SubmitButton from '../../../components/SubmitButton'
import { RadioGroup } from '@headlessui/react'
import MaskedInput from '../../../components/MaskedInput'
import parseDecimal from '../../../utils/parseDecimal'
import Suggestions from '../../../components/Suggestions'
import useNodes from '../../../hooks/useNodes'
import { Requisite } from '../../../components/Requisites'
import Select from '../../../components/Select'
import { ReactComponent as TickIcon } from '../../../svg/icons/tick-xs.svg'
import { showPopup } from '../../../components/Toaster/showPopup'

interface SupplyPaymentFormProps {
  onDone?: () => void
  supplyId: number
  dealId: number
}

interface SupplyPaymentFormValues {
  purpose: string
  amount: string
  receiptAccount: Requisite
  receiptAccountQuery: string
  feraBankAccount: FeraBankAccount
  feraBankAccountQuery: string
  fundingSourceId: string
  fundingSourceName: string
}

const SupplyPaymentForm: FC<SupplyPaymentFormProps> = ({ onDone, supplyId, dealId }) => {
  const { data: supplyData } = useDealSupplySuspenseQuery({ variables: { id: `${supplyId}` } })

  const { data: feraBankAccountsData } = useFeraBankAccountsSuspenseQuery()

  const { data: dealFundingSourcesData } = useFundingSourcesByDealIdSuspenseQuery({
    variables: { dealId: `${dealId}` },
    skip: !dealId
  })

  const supplier = supplyData?.dealSupply?.supplierCompany
  const companyBranch = supplyData?.dealSupply?.companyBranch
  const receiptAccounts = useNodes(supplier?.company?.bankDetails?.edges)
  const feraBankAccounts = useNodes(feraBankAccountsData?.feraBankAccounts?.edges)
  const fundingSources = useNodes(dealFundingSourcesData?.dealFundingSources?.edges)?.map((i) => i.fundingSource)

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors }
  } = useForm<SupplyPaymentFormValues>({
    // в случае, если счёт получателя только один, он по умолчанию подставляется объектом в поле receiptAccount и его название подставляется в поле receiptAccountQuery
    defaultValues: {
      purpose: `Оплата по счёту №____ от __.__.__ за оборудование, в т.ч. НДС ${supplyData?.dealSupply?.vatRate}% - _____ руб.`,
      receiptAccount: receiptAccounts?.length === 1 ? receiptAccounts[0] : undefined,
      receiptAccountQuery: receiptAccounts?.length === 1 ? receiptAccounts[0].name : undefined
    },
    mode: 'onBlur'
  })

  const [showRequisites, setShowRequisites] = useState(false)
  const [createBankOrder, { loading }] = useCreateBankOrderMutation()

  const purpose = watch('purpose')
  const fundingSourceId = watch('fundingSourceId')
  const fundingSourceName = watch('fundingSourceName')
  const receiptAccount = watch('receiptAccount')
  const receiptAccountQuery = watch('receiptAccountQuery')
  const feraBankAccount = watch('feraBankAccount')
  const feraBankAccountQuery = watch('feraBankAccountQuery')

  const receiptAccountsFiltered = useMemo(() => {
    if (receiptAccountQuery) {
      return receiptAccounts.filter(
        (receiptAccount) =>
          receiptAccount?.name.toLowerCase().includes(receiptAccountQuery.toLowerCase()) ||
          receiptAccount?.bic.toLowerCase().includes(receiptAccountQuery.toLowerCase()) ||
          receiptAccount?.account.toLowerCase().includes(receiptAccountQuery.toLowerCase())
      )
    }
    return receiptAccounts
  }, [receiptAccountQuery, receiptAccounts])

  const feraBankAccountsFiltered = useMemo(() => {
    if (feraBankAccountQuery) {
      return feraBankAccounts.filter(
        (feraBankAccount) =>
          feraBankAccount?.name.toLowerCase().includes(feraBankAccountQuery.toLowerCase()) ||
          feraBankAccount?.bic.toLowerCase().includes(feraBankAccountQuery.toLowerCase()) ||
          feraBankAccount?.number.toLowerCase().includes(feraBankAccountQuery.toLowerCase())
      )
    }
    return feraBankAccounts
  }, [feraBankAccountQuery, feraBankAccounts])

  const onSubmit: SubmitHandler<SupplyPaymentFormValues> = async (data) => {
    if (!receiptAccount) throw new Error('Отсутствует счёт получателя')
    if (!supplier) throw new Error('Отсутствует поставщик')
    if (!feraBankAccount) throw new Error('Отсутствует счёт оплаты поставки')

    const amount = parseDecimal(data.amount.replace(/\s/g, ''))
    await createBankOrder({
      variables: {
        input: {
          amount,
          feraBankAccountId: data.feraBankAccount.id,
          paymentPurpose: data.purpose,
          recipientAccountNumber: receiptAccount.account,
          recipientBankBik: receiptAccount.bic,
          recipientBankName: receiptAccount.name,
          recipientBankCorrNumber: receiptAccount.correspondentAccount,
          recipientInn: `${supplier?.company?.inn}`,
          recipientName: `${supplier?.company?.shortWithOpf}`,
          recipientKpp: companyBranch?.kpp || supplier?.company?.kpp,
          targetId: `${supplyId}`,
          targetType: BankOrderTargetType.DealSupply,
          fundingSourceId: data?.fundingSourceId || null
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        createBankOrder: {
          __typename: 'CreateBankOrderPayload',
          bankOrder: {
            __typename: 'BankOrder',
            id: Math.round(Math.random() * -10000).toString(),
            targetId: `${supplyId}`,
            createdAt: new Date().toISOString(),
            targetType: BankOrderTargetType.DealSupply,
            amount,
            state: BankOrderState.Pending,
            feraBankAccount: feraBankAccount,
            fundingSource: fundingSources?.find((s) => s.id === data.fundingSourceId),
            recipientInn: `${supplier?.company?.inn}`,
            recipientKpp: companyBranch?.kpp || supplier?.company?.kpp,
            recipientBankName: receiptAccount.name,
            recipientAccountNumber: receiptAccount.account,
            recipientBankBik: receiptAccount.bic,
            recipientBankCorrNumber: receiptAccount.correspondentAccount,
            documentNumber: Math.round(Math.random() * -10000)
          },
          errors: []
        }
      },
      update: (cache, result) => {
        const variables = {
          targetId: `${supplyId}`,
          targetType: BankOrderTargetType.DealSupply
        }

        const orders = cache.readQuery<BankOrdersQuery, BankOrdersQueryVariables>({
          query: BankOrdersDocument,
          variables
        })

        if (!orders?.bankOrders?.edges) return

        const newOrder = result.data?.createBankOrder?.bankOrder
        if (!newOrder) return

        cache.writeQuery<BankOrdersQuery, BankOrdersQueryVariables>({
          query: BankOrdersDocument,
          variables,
          data: {
            __typename: 'Query',
            bankOrders: {
              __typename: 'BankOrderConnection',
              edges: [
                ...orders.bankOrders.edges,
                {
                  __typename: 'BankOrderEdge',
                  node: newOrder
                }
              ]
            }
          }
        })
      }
    })
      .then(() => {
        if (onDone) onDone()
      })
      .catch((err: ApolloError) => {
        err.name === 'BAD_USER_INPUT'
          ? handleBackendErrorsToForm<SupplyPaymentFormValues>(err, (fieldPath, textError) => {
              setError(fieldPath, { message: textError, type: 'focus' }, { shouldFocus: true })
            })
          : showPopup({ title: err.name, subtitle: err.message })
      })
  }

  if (!supplier) return null

  return (
    <section className='md: w-[448px] p-12 pb-6'>
      <h1 className='mb-6 font-display text-h200'>Оплата поставщику</h1>
      <div className='flex gap-3'>
        <p className='mb-12'>{supplier?.company?.shortWithOpf}</p>
        {companyBranch?.name && <p className='text-grayscale-150'>{companyBranch?.name}</p>}
      </div>
      <RadioGroup
        className='mb-12 grid auto-cols-fr grid-flow-col rounded-xl bg-grayscale-450 p-1'
        value={'foo'}
        onChange={(value: string) => {
          const showRequisites = value === 'b'
          setShowRequisites(showRequisites)
        }}
      >
        <RadioGroup.Option value='a'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              !showRequisites && 'border-grayscale-350 bg-white-0'
            )}
          >
            Детали
          </div>
        </RadioGroup.Option>
        <RadioGroup.Option value='b'>
          <div
            className={c(
              'm-1 cursor-pointer rounded-xl border-1 border-solid border-grayscale-450 px-5 py-5 text-center',
              showRequisites && 'border-grayscale-350 bg-white-0'
            )}
          >
            Реквизиты
          </div>
        </RadioGroup.Option>
      </RadioGroup>

      <div className={c(showRequisites ? '' : 'hidden')}>
        <table className='w-full'>
          <tbody>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>ИНН</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {supplier?.company?.inn}
              </td>
            </tr>
            <tr className='group'>
              <td className='px-8 py-6 first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>КПП</td>
              <td className='px-8 py-6 text-right first:rounded-l-xl last:rounded-r-xl group-odd:bg-grayscale-450'>
                {companyBranch?.kpp || supplier?.company?.kpp}
              </td>
            </tr>
          </tbody>
        </table>
        <div className='mt-19'>
          <Suggestions<Requisite>
            highlight={receiptAccountQuery || receiptAccount?.name}
            suggestions={receiptAccountsFiltered?.map((receiptAccount) => ({
              key: receiptAccount._id,
              title: receiptAccount.name,
              subtitle: `БИК: ${receiptAccount.bic || '–'} Р/с: ${receiptAccount.account || '–'}`,
              payload: receiptAccount
            }))}
            // при выборе счёте получателя из дропдауна он подставляется объектом в поле receiptAccount и его название подставляется в строку receiptAccountQuery
            select={(suggestion) => {
              setValue('receiptAccount', suggestion?.payload as Requisite)
              setValue('receiptAccountQuery', suggestion.payload?.name || '')
            }}
          >
            <div className='inp-label text-p350 mb-5'>Выбрать счёт получателя</div>
            <label
              className={c(
                'group relative flex items-center rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100',
                !receiptAccount && 'ring-1 ring-red-100'
              )}
            >
              {/* поле для отображения названия счёта получателя и поиска */}
              <input
                type='text'
                placeholder='Выбрать'
                className='w-full border-none bg-transparent px-10 py-7 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
                autoComplete='off'
                {...register('receiptAccountQuery', {
                  required: true,
                  validate: (value) => {
                    return value === receiptAccount.name || 'Поле и счёт должны совпадать'
                  }
                })}
              />
              {receiptAccount?.account && (
                <p className='whitespace-nowrap pr-10 text-grayscale-200'>
                  Р/с: ...{receiptAccount.account.substring(receiptAccount.account.length - 6)}
                </p>
              )}
            </label>
          </Suggestions>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={c('relative flex flex-col gap-8', !showRequisites ? '' : 'hidden')}>
          <Select
            label='Источник финансирования'
            placeholder='Выбрать'
            type='text'
            autoComplete='off'
            value={fundingSourceName}
            error={errors.fundingSourceId || errors.fundingSourceName}
            {...register('fundingSourceId', { required: true })}
          >
            {fundingSources?.map((fundingSource) => (
              <li
                key={fundingSource.id}
                onClick={() => {
                  setValue('fundingSourceId', fundingSource.id, { shouldValidate: true })
                  setValue('fundingSourceName', fundingSource.name)
                }}
                className='cursor-pointer px-12 py-5 hover:bg-grayscale-450'
              >
                <div className='flex items-center justify-between'>
                  <div className='mb-1 text-grayscale-0'>{fundingSource.name}</div>
                  {fundingSourceId === fundingSource.id && (
                    <div className='ml-5 flex items-center rounded-full bg-red-100/5 px-5 py-2 text-red-100'>
                      <TickIcon />
                    </div>
                  )}
                </div>
              </li>
            ))}
          </Select>
          <Suggestions<FeraBankAccount>
            initialState={!feraBankAccount}
            highlight={feraBankAccountQuery || feraBankAccount?.name}
            suggestions={feraBankAccountsFiltered?.map((feraBankAccount) => ({
              key: feraBankAccount.id,
              title: feraBankAccount.name,
              subtitle: `БИК: ${feraBankAccount.bic || '–'} Р/с: ${feraBankAccount.number || '–'}`,
              payload: feraBankAccount
            }))}
            // при выборе счёте оплаты из дропдауна он подставляется объектом в поле feraBankAccount и его название подставляется в строку feraBankAccountQuery
            select={(suggestion) => {
              setValue('feraBankAccount', suggestion?.payload as FeraBankAccount)
              setValue('feraBankAccountQuery', suggestion.payload?.name || '')
            }}
          >
            <div className='inp-label text-p350 mb-5'>Счёт оплаты поставки</div>
            <label className='group relative flex items-center rounded-xl bg-white-0 ring-1 ring-grayscale-400 focus-within:ring-red-100 hover:ring-grayscale-250 hover:focus-within:ring-red-100'>
              {/* поле для отображения счёта оплаты (название банка) и поиска */}
              <input
                type='text'
                placeholder='Выбрать'
                className='w-full border-none bg-transparent px-10 py-7 placeholder-grayscale-250 outline-none transition-opacity focus:ring-0'
                autoComplete='off'
                {...register('feraBankAccountQuery', {
                  required: true,
                  validate: (value) => {
                    return value === feraBankAccount.name || 'Поле и счёт должны совпадать'
                  }
                })}
              />
              {feraBankAccount?.number && (
                <p className='whitespace-nowrap pr-10 text-grayscale-200'>
                  Р/с: ...{feraBankAccount.number.substring(feraBankAccount.number.length - 6)}
                </p>
              )}
            </label>
          </Suggestions>

          <div className='relative'>
            <Textarea
              label='Назначение платежа'
              autoComplete='off'
              {...register('purpose', { required: true })}
              maxLength={210}
            />
            <div className='absolute bottom-1 right-4 text-xs text-grayscale-200'>{purpose?.length}/210</div>
          </div>
          <MaskedInput
            label='Сумма'
            placeholder='300 000,00'
            inputMode='decimal'
            mask='number'
            onAccept={(value) => setValue('amount', value)}
            {...register('amount', { required: true, min: 1 })}
            error={''}
          />
        </div>

        <p className='my-12 text-center text-p100 text-red-100'>
          Убедитесь, что данные в платеже совпадают с данными счета
        </p>

        {receiptAccount ? (
          <SubmitButton loading={loading}>
            <PayIcon className='mr-5' />
            Отправить на оплату
          </SubmitButton>
        ) : (
          <button
            className='flex h-27 w-full items-center justify-center rounded-xl bg-grayscale-200 font-semibold disabled:text-white-0'
            disabled={true}
            title='Не выбран счёт получателя'
          >
            <WarningIcon className='mr-5' height='24px' width='24px' />
            <p>Не выбран счёт получателя</p>
          </button>
        )}
        <p className='mt-5 text-center text-grayscale-250'>Оплата со счета {feraBankAccount?.name || '-'}</p>
      </form>
    </section>
  )
}

export default SupplyPaymentForm
