import { FC, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ReactComponent as DealIcon } from '../../svg/icons/deal.svg'
import { ReactComponent as AmoCrmIcon } from '../../svg/icons/amocrm.svg'
import { ReactComponent as EditIcon } from '../../svg/icons/edit.svg'
import { useApplicationByIdQuery } from '../../graphql/schema'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import CopyInn from '../../components/CopyInn'
import useAccessCheck from '../../hooks/useAccessCheck.ts'
import PhoneNumber from '../../components/PhoneNumber.tsx'
import {
  EntityBar,
  EntityBarItem,
  EntityHeader,
  EntityInfo,
  EntityName,
  EntityNav,
  EntityNavItem
} from '../../components/EntityPage/EntityHeader.tsx'
import PrimaryButtonLink from '../../components/UI/PrimaryButtonLink.tsx'
import PrimaryButton from '../../components/UI/PrimaryButton.tsx'
import { EntityPage } from '../../components/EntityPage/EntityPage.tsx'
import ApplicationStatusSwitcher from './ApplicationStatusSwitcher.tsx'
import ContactUser from '../../components/ContactUser.tsx'
import SuspenseModal from '../../components/SuspenseModal.tsx'

const Application: FC = () => {
  const { id } = useParams<'id'>()
  const [editAppId, setEditAppId] = useState<number | undefined>()
  const formOpen = editAppId !== undefined

  const hasAccessScoringView = useAccessCheck('application.scoring.view')

  const {
    data,
    refetch,
    loading: appLoading
  } = useApplicationByIdQuery({
    variables: { id: id?.toString() as string },
    skip: !id,
    returnPartialData: true
  })

  const application = data?.application
  const user = application?.user

  if (!application) return null

  const existingDealId = application?.deal?._id
  const oldAppWithAmo = application?.created && new Date('2023-04-28') > new Date(application.created)

  return (
    <div className='flex-grow'>
      <EntityHeader>
        <div className='flex items-start justify-between'>
          <div>
            <EntityInfo>
              <div className='mr-6'>Заявка №{id}</div>
              {user && <ContactUser userId={user?._id} />}
            </EntityInfo>
            <div className='mb-6 flex items-center'>
              <EntityName
                value={application?.customerCompany?.company?.shortWithOpf}
                loading={appLoading}
                to={`/customers/${application?.customerCompany?._id}`}
              />
              <CopyInn inn={application?.customerCompany?.company?.inn} />
            </div>
          </div>
          <div className='flex flex-none gap-x-4'>
            {!!application && (
              <PrimaryButton onClick={() => setEditAppId(application._id)}>
                <EditIcon title='Редактировать' />
                Редактировать
              </PrimaryButton>
            )}
            {existingDealId && (
              <PrimaryButtonLink to={`/deals/${existingDealId}`}>
                <DealIcon />
                Открыть сделку №{existingDealId}
              </PrimaryButtonLink>
            )}
          </div>
        </div>
        <ApplicationStatusSwitcher id={application._id} />
        <EntityBar>
          <EntityBarItem title='Имя' value={application?.fio} loading={appLoading} />
          <EntityBarItem title='Email' value={application?.email} loading={appLoading} />
          <EntityBarItem title='Телефон' loading={appLoading}>
            <PhoneNumber number={application?.phone} />
          </EntityBarItem>
        </EntityBar>
        <EntityNav>
          <EntityNavItem title='Общая информация' to='' />
          <EntityNavItem title='Поставщики' to='suppliers' />
          {hasAccessScoringView && <EntityNavItem title='Скоринг' to='scoring' />}
          <EntityNavItem title='Согласования' to='reconciliations' />
          <EntityNavItem title='Документы' to='docs' />
          <EntityNavItem title='Контакты' to='contacts' />
          {oldAppWithAmo && (
            <a
              className='flex h-full cursor-pointer items-center pb-8 text-body-m font-medium hover:text-base-red aria-current:border-b-2 aria-current:text-base-red'
              target='_blank'
              rel='noreferrer'
              href={`https://fera.amocrm.ru/leads/detail/${application.amoCrmId}`}
            >
              <AmoCrmIcon />
              <span className='ml-4 font-medium'>Открыть в AmoCRM</span>
            </a>
          )}
        </EntityNav>
      </EntityHeader>

      <EntityPage>
        <Outlet context={[data, refetch, setEditAppId]} />
      </EntityPage>

      <SuspenseModal open={formOpen} setOpen={() => setEditAppId(undefined)}>
        <div className='rounded-xl bg-white-0'>
          <ApplicationForm
            onDone={() => {
              refetch()
              setEditAppId(undefined)
            }}
            id={editAppId}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Application
