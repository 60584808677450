import { FC, useCallback, useState } from 'react'
import { GridButton, GridTable, GridTableCell, GridTableRow } from '../../../components/Table'
import { ReactComponent as DeleteIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as FileUploadIcon } from '../../../svg/ui/upload.svg'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatMoneyDecimal } from '../../../utils/formatNumber'
import {
  DocumentTypeEntity,
  NomenclatureType,
  SpecItem,
  useCustomerForDealSupplyQuery,
  useDeleteShipmentMutation,
  UserRoles,
  useSupplierForDealSupplyQuery,
  useUpdateShipmentMutation
} from '../../../graphql/schema'
import { Card, CardAccentButton, CardIconButton, CardMenu } from '../../../components/Card'
import { ReactComponent as NomenclatureIcon } from '../../../svg/ui/agreement.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import ConfirmationForm from '../../../components/ConfirmationForm'
import Modal from '../../../components/Modal'
import { SHIPMENT_SPECS_FOLDER_NAME } from '../../../config'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import Specification from '../../../components/Specification'
import useCurrentUser from '../../../hooks/useCurrentUser'
import SuspenseModal from '../../../components/SuspenseModal'
import ShipmentForm from './ShipmentForm'
import { useDropzone } from 'react-dropzone'
import { ShipmentDocumentType, updFromZip, UPDInfo } from '../../../utils/UPD.ts'
import { Link } from 'react-router-dom'
import copyToClipboard from 'copy-to-clipboard'
import { showFizz } from '../../../components/Fizz'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import useUploadDocuments from '../../../components/DocumentManager/useUploadDocuments.ts'

interface SupplyShipmentProps {
  id: number
  index: number
  amount?: number
  date?: string
  editable?: boolean
  documentType?: string
  documentId?: string
  documentDate?: string
  nomenclature?: SpecItem[]
  supplierId?: number
  supplierCompanyBranchKPP?: string
  customerId?: number
  onDone: () => void
}

const SupplyShipment: FC<SupplyShipmentProps> = ({
  index,
  amount,
  date,
  id,
  documentType,
  documentId,
  documentDate,
  nomenclature,
  editable = true,
  supplierId,
  supplierCompanyBranchKPP,
  customerId,
  onDone
}) => {
  const { data: supplierData } = useSupplierForDealSupplyQuery({
    variables: {
      id: `${supplierId}`
    },
    skip: !supplierId
  })
  const supplier = supplierData?.supplierCompany

  const { data: customerData } = useCustomerForDealSupplyQuery({
    variables: {
      id: `${customerId}`
    },
    skip: !customerId
  })
  const customer = customerData?.customerCompany

  const [deleteShipment] = useDeleteShipmentMutation({
    update: (cache, result) => {
      const deletedId = result.data?.removeShipment?.shipment?._id
      if (!deletedId) return
      const normalizedId = cache.identify({ id: deletedId, __typename: 'Shipment' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [specModalOpen, setSpecModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [UPDInfo, setUPDInfo] = useState<UPDInfo>()
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])

  const uploadDocuments = useUploadDocuments({
    folderName: SHIPMENT_SPECS_FOLDER_NAME,
    entityType: DocumentTypeEntity.Shipment
  })
  const [updateShipment] = useUpdateShipmentMutation()

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const result = await updFromZip(acceptedFiles[0])
      if (!result) return
      const { upd, files } = result

      if (upd.documentType === ShipmentDocumentType.INVOICE) {
        await uploadDocuments(files, { entityId: id.toString() })

        await updateShipment({
          variables: {
            input: {
              id: id.toString(),
              nomenclatureType:
                documentType === NomenclatureType.Nomenclature && !!nomenclature
                  ? NomenclatureType.Upd
                  : NomenclatureType.Invoice
            }
          }
        })

        return
      }

      setUPDInfo(upd)
      setFilesToUpload(files)
      setEditModalOpen(true)
    },
    [documentType, id, nomenclature, updateShipment, uploadDocuments]
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onDrop,
    multiple: false,
    noClick: true,
    accept: {
      'application/zip': ['.zip'],
      'application/octet-stream': ['.zip']
    }
  })

  const hasAccessDeleteShipment = useAccessCheck('deal.supplies.delete_shipment')

  const currentUser = useCurrentUser()
  const isAdmin = currentUser?.roles.includes(UserRoles.RoleAdmin)

  return (
    <div>
      <GridTable className='grid-cols-[42%_58%] border-b-1 border-separators-primary'>
        <GridTableCell className='col-span-full flex items-center justify-between'>
          <div className='mr-auto font-medium'>Отгрузка №{index}</div>
          {editable && (
            <CardMenu>
              {isAdmin && (
                <CardIconButton onClick={() => setEditModalOpen(true)}>
                  <EditIcon />
                </CardIconButton>
              )}
              {hasAccessDeleteShipment && (
                <CardIconButton
                  onClick={() => {
                    setDeleteModalOpen(true)
                  }}
                >
                  <DeleteIcon />
                </CardIconButton>
              )}
            </CardMenu>
          )}
        </GridTableCell>
        <GridTableRow>
          <GridTableCell className='text-labels-secondary'>Сумма</GridTableCell>
          <GridTableCell>{typeof amount === 'number' && formatMoneyDecimal(amount / 100)}</GridTableCell>
        </GridTableRow>
        <GridTableRow>
          <GridTableCell className='text-labels-secondary'>Дата приёмки</GridTableCell>
          <GridTableCell>{date && dateFormatter.format(new Date(date))}</GridTableCell>
        </GridTableRow>
        {!!nomenclature && (
          <GridTableRow>
            <GridTableCell className='text-labels-secondary'>Номенклатура</GridTableCell>
            <GridButton onClick={() => setSpecModalOpen(true)}>
              <NomenclatureIcon />
              Открыть
            </GridButton>
          </GridTableRow>
        )}
      </GridTable>

      <div className='p-5'>
        <EntityFolder
          entityId={id}
          canDelete={editable}
          canUpload={editable}
          entityType={DocumentTypeEntity.Shipment}
          folderName={SHIPMENT_SPECS_FOLDER_NAME}
          onChange={onDone}
        />
      </div>

      {documentType !== NomenclatureType.Upd && (
        <div {...getRootProps()} className='px-5 pb-5'>
          <input type='hidden' {...getInputProps()} />
          <CardAccentButton type='button' onClick={open}>
            <FileUploadIcon />
            Заполнить автоматически из выгрузки ЭДО
          </CardAccentButton>
        </div>
      )}

      <Modal open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <div className='rounded-xl bg-surface-primary'>
          <ConfirmationForm
            title='Удалить отгрузку?'
            onDone={async () => {
              await deleteShipment({
                variables: {
                  input: { shipment: id.toString() }
                }
              })
              setDeleteModalOpen(false)
            }}
            onDismiss={() => setDeleteModalOpen(false)}
          >
            Это действие необратимо
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal open={editModalOpen} setOpen={setEditModalOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <ShipmentForm
            shipmentId={id}
            UPDInfo={UPDInfo}
            filesToUpload={filesToUpload}
            supplierINN={supplier?.company?.inn}
            receiverINN={customer?.company?.inn}
            buyerINN='9703051608' /* Fera INN */
            onDone={() => {
              setEditModalOpen(false)
              setUPDInfo(undefined)
              setFilesToUpload([])
              onDone()
            }}
          />
        </div>
      </SuspenseModal>
      <Modal open={specModalOpen} setOpen={setSpecModalOpen}>
        <section className='min-w-[500px] max-w-[1200px] rounded-xl bg-surface-primary px-12 pb-12 pt-10'>
          <h1 className='mb-10 text-title-l font-medium'>
            Документ отгрузки
            {documentId && ` №${documentId}`}
            {documentDate && ` от ${dateFormatter.format(new Date(documentDate))}`}
          </h1>
          <Card className='grid gap-5 p-5'>
            <div className='rounded-md bg-surface-primary shadow-card'>
              <GridTable className='grid-cols-[auto_1fr]'>
                <GridTableRow>
                  <GridTableCell className='text-labels-secondary'>Продавец</GridTableCell>
                  <GridTableCell>
                    <Link className='hover:text-base-red' to={`/suppliers/${supplier?._id}`}>
                      {supplier?.company?.shortWithOpf || supplier?.company?.fullWithOpf}
                    </Link>
                    <div className='text-body-s text-labels-secondary'>
                      ИНН:{' '}
                      <span
                        className='cursor-pointer hover:text-base-red'
                        onClick={(e) => {
                          copyToClipboard(`${supplier?.company?.inn}`)
                          showFizz('Скопировано', e.clientX, e.clientY)
                        }}
                      >
                        {supplier?.company?.inn}
                      </span>{' '}
                      КПП:{' '}
                      <span
                        className='cursor-pointer hover:text-base-red'
                        onClick={(e) => {
                          copyToClipboard(`${supplierCompanyBranchKPP || supplier?.company?.kpp}`)
                          showFizz('Скопировано', e.clientX, e.clientY)
                        }}
                      >
                        {supplierCompanyBranchKPP || supplier?.company?.kpp}
                      </span>
                    </div>
                  </GridTableCell>
                </GridTableRow>
                <GridTableRow>
                  <GridTableCell className='text-labels-secondary'>Грузополучатель</GridTableCell>
                  <GridTableCell>
                    <Link className='hover:text-base-red' to={`/customers/${customer?._id}`}>
                      {customer?.company?.shortWithOpf || customer?.company?.fullWithOpf}
                    </Link>
                    <div className='text-body-s text-labels-secondary'>
                      ИНН:{' '}
                      <span
                        className='cursor-pointer hover:text-base-red'
                        onClick={(e) => {
                          copyToClipboard(`${customer?.company?.inn}`)
                          showFizz('Скопировано', e.clientX, e.clientY)
                        }}
                      >
                        {customer?.company?.inn}
                      </span>{' '}
                      КПП:{' '}
                      <span
                        className='cursor-pointer hover:text-base-red'
                        onClick={(e) => {
                          copyToClipboard(`${customer?.company?.kpp}`)
                          showFizz('Скопировано', e.clientX, e.clientY)
                        }}
                      >
                        {customer?.company?.kpp}
                      </span>
                    </div>
                  </GridTableCell>
                </GridTableRow>
              </GridTable>
            </div>

            <div className='rounded-md bg-surface-primary shadow-card'>
              <Specification items={nomenclature} currency='RUB' withPricePerUnitCentsWithoutVatCell={true} />
            </div>
          </Card>
        </section>
      </Modal>
    </div>
  )
}

export default SupplyShipment
