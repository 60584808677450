import { FC, useEffect, useRef, useState } from 'react'
import { ReactComponent as PlayIcon } from '../../svg/ui/play.svg'
import { ReactComponent as PauseCallIcon } from '../../svg/ui/pause-call.svg'

interface CallAudioPlayerProps {
  duration?: number
  record?: string
}

const CallAudioPlayer: FC<CallAudioPlayerProps> = ({ duration, record }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(duration || 0)
  const audioRef = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    audioRef.current = new Audio(record)

    const updateTime = () => setCurrentTime(audioRef.current?.currentTime || 0)
    audioRef.current.addEventListener('timeupdate', updateTime)
    audioRef.current.addEventListener('ended', () => setIsPlaying(false))

    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.removeEventListener('ended', () => setIsPlaying(false))
        audioRef.current.removeEventListener('timeupdate', () => setCurrentTime(duration || 0))
      }
    }
  }, [duration, record])

  const togglePlayPause = () => {
    if (!audioRef.current || !duration) return

    if (isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  const formatDuration = (seconds: number = 0) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.round(seconds % 60)
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
  }

  return (
    <div className='flex items-center rounded-md bg-surface-secondary px-5 py-3'>
      <button type='button' onClick={togglePlayPause} className='text-labels-tertiary hover:text-labels-secondary'>
        {isPlaying ? <PauseCallIcon width={12} /> : <PlayIcon width={12} />}
      </button>
      <div className='ml-4 min-w-24 text-center text-labels-secondary'>{formatDuration(currentTime)}</div>
      {/*<div className='ml-10 rounded-md bg-surface-tertiary px-2 py-1'>{`->A`}</div>*/}
    </div>
  )
}

export default CallAudioPlayer
