import { FC } from 'react'
import { useDealPaymentScheduleSuspenseQuery } from '../../../graphql/schema'
import { Card, CardHeader, CardTitle } from '../../../components/Card'
import GridView, { GridViewConfig } from '../../../components/GridView'
import { dateFormatter } from '../../../utils/dateFormatter'
import { formatDecimal } from '../../../utils/formatNumber'

interface PaymentSchedulesCompareProps {
  scheduleAId: string
  scheduleAIndex: number
  scheduleBId: string
  scheduleBIndex: number
  actualScheduleId?: string
}

interface SimplePayment {
  id?: string
  date: string
  amount: number
}

const PaymentScheduleConfig: GridViewConfig<SimplePayment> = {
  grid: 'grid-cols-[auto_auto_1fr]',
  columns: [
    {
      title: '№',
      value: (_, i) => i + 1,
      numeric: true
    },
    { title: 'Дата', value: (p) => dateFormatter.format(new Date(p.date)), numeric: true },
    {
      title: 'Итого, ₽',
      value: (p) => <div className='flex w-full justify-between gap-x-16'>{formatDecimal(p.amount * 100)}</div>,
      numeric: true
    }
  ]
}

const PaymentSchedulesCompare: FC<PaymentSchedulesCompareProps> = ({
  scheduleAId,
  scheduleAIndex,
  scheduleBId,
  scheduleBIndex,
  actualScheduleId
}) => {
  // const [scheduleA, setScheduleA] = useState<string>(scheduleAId)
  // const [scheduleB, setScheduleB] = useState<string>(scheduleBId)
  const scheduleA = scheduleAId
  const scheduleB = scheduleBId

  const { data: scheduleAData } = useDealPaymentScheduleSuspenseQuery({
    variables: {
      id: scheduleA
    }
  })
  const scheduleAPayments = (scheduleAData?.paymentSchedule?.paymentsData as SimplePayment[]) || []
  const scheduleADocumentDate =
    scheduleAData?.paymentSchedule?.documentDate &&
    dateFormatter.format(new Date(scheduleAData?.paymentSchedule?.documentDate))

  const { data: scheduleBData } = useDealPaymentScheduleSuspenseQuery({
    variables: {
      id: scheduleB
    }
  })
  const scheduleBPayments = (scheduleBData?.paymentSchedule?.paymentsData as SimplePayment[]) || []
  const scheduleBDocumentDate =
    scheduleBData?.paymentSchedule?.documentDate &&
    dateFormatter.format(new Date(scheduleBData?.paymentSchedule?.documentDate))

  return (
    <div>
      <section className='md: w-[800px] px-12 pb-12 pt-10'>
        <h1 className='mb-5 text-title-l font-medium'>Сравнение графиков платежей</h1>
        <div className='grid grid-cols-2 gap-4'>
          <Card>
            <CardHeader>
              <CardTitle>
                {scheduleA === actualScheduleId ? 'Текущий' : 'Архивный'} график № {scheduleAIndex}{' '}
                {scheduleADocumentDate ? `от ${scheduleADocumentDate}` : '(Дата не указана)'}
              </CardTitle>
            </CardHeader>
            <div className='px-5 pb-5'>
              <GridView config={PaymentScheduleConfig} data={scheduleAPayments} />
            </div>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>
                {scheduleB === actualScheduleId ? 'Текущий' : 'Архивный'} график № {scheduleBIndex}{' '}
                {scheduleBDocumentDate ? `от ${scheduleBDocumentDate}` : '(Дата не указана)'}
              </CardTitle>
            </CardHeader>
            <div className='px-5 pb-5'>
              <GridView config={PaymentScheduleConfig} data={scheduleBPayments} />
            </div>
          </Card>
        </div>
      </section>
    </div>
  )
}

export default PaymentSchedulesCompare
