import { FC, useState } from 'react'
import { LegalCollection, LegalCollectionState, useUpdateLegalCollectionMutation } from '../../../../graphql/schema'
import { ReactComponent as LoadingIcon } from './../../../../svg/icons/loading.svg'
import SentLegalCollectionDateForm from './SentLegalCollectionDateForm.tsx'
import Modal from '../../../../components/Modal.tsx'
import { dateFormatter } from '../../../../utils/dateFormatter.ts'
import { addWorkDays } from '../../../../utils/dates.ts'

interface SentLegalCollectionProps {
  legalCollection: LegalCollection
  postponeDueDate?: string
}

const SentLegalCollection: FC<SentLegalCollectionProps> = ({ legalCollection, postponeDueDate }) => {
  const [dateFormOpenByState, setDateFormOpenByState] = useState<LegalCollectionState>()
  const [updateCollection, { loading: updateCollectionLoading }] = useUpdateLegalCollectionMutation()

  return (
    <>
      {(() => {
        switch (legalCollection?.state) {
          case LegalCollectionState.Assigned:
            return (
              <div className='flex items-center gap-5 font-medium text-labels-secondary'>
                <div className='px-5'>Отправить претензию</div>
                <button
                  onClick={() => setDateFormOpenByState(LegalCollectionState.ClaimSent)}
                  className='flex items-center rounded-md border-1 border-grayscale-400 px-5 py-2'
                >
                  Отправлено
                  {updateCollectionLoading && <LoadingIcon className='mx-auto ml-5 h-7 w-7 animate-spin' />}
                </button>
              </div>
            )
          case LegalCollectionState.ClaimSent:
            if (legalCollection?.claimSentAt) {
              const afterTenWorkDays = addWorkDays(new Date(legalCollection?.claimSentAt), 10)
              if (!afterTenWorkDays || new Date() < afterTenWorkDays)
                return (
                  <div className='max-w-max px-5 font-medium text-labels-secondary'>
                    Претензия отправлена {dateFormatter.format(new Date(legalCollection.claimSentAt))}
                  </div>
                )
            }

            return (
              <div className='flex items-center gap-5 font-medium text-labels-secondary'>
                <div className='px-5'>Отправить пугалку</div>
                <button
                  onClick={() => setDateFormOpenByState(LegalCollectionState.ThreatSent)}
                  className='flex items-center rounded-md border-1 border-grayscale-400 px-5 py-2'
                >
                  Отправлено
                  {updateCollectionLoading && <LoadingIcon className='mx-auto ml-5 h-7 w-7 animate-spin' />}
                </button>
              </div>
            )
          case LegalCollectionState.ThreatSent:
            if (legalCollection?.threatSentAt) {
              const afterThreeDays = new Date(legalCollection?.threatSentAt)
              afterThreeDays.setDate(afterThreeDays.getDate() + 3)
              if (new Date() < afterThreeDays)
                return (
                  <div className='max-w-max px-5 font-medium text-labels-secondary'>
                    Пугалка отправлена {dateFormatter.format(new Date(legalCollection.threatSentAt))}
                  </div>
                )
            }

            return (
              <div className='flex items-center gap-5 font-medium text-labels-secondary'>
                <div className='px-5'>Отправить иск</div>
                {postponeDueDate ? (
                  `Отложено до ${dateFormatter.format(new Date(postponeDueDate))}`
                ) : (
                  <button
                    onClick={() => setDateFormOpenByState(LegalCollectionState.LawsuitSent)}
                    className='flex items-center rounded-md border-1 border-grayscale-400 px-5 py-2 font-medium text-labels-secondary'
                  >
                    Отправлено
                    {updateCollectionLoading && <LoadingIcon className='mx-auto ml-5 h-7 w-7 animate-spin' />}
                  </button>
                )}
              </div>
            )
          case LegalCollectionState.LawsuitSent:
            if (legalCollection?.lawsuitSentAt) {
              return (
                <div className='max-w-max px-5 font-medium text-labels-secondary'>
                  Иск отправлен {dateFormatter.format(new Date(legalCollection.lawsuitSentAt))}
                </div>
              )
            }
        }
      })()}

      <Modal open={!!dateFormOpenByState} setOpen={() => setDateFormOpenByState(undefined)}>
        <div className='z-10 rounded-xl bg-white-0'>
          <SentLegalCollectionDateForm
            onDone={async (date) => {
              if (!legalCollection?.state || !date) return

              setDateFormOpenByState(undefined)
              await updateCollection({
                variables: {
                  input: {
                    id: legalCollection.id,
                    state: dateFormOpenByState,
                    claimSentAt: dateFormOpenByState === LegalCollectionState.ClaimSent ? new Date(date) : undefined,
                    threatSentAt: dateFormOpenByState === LegalCollectionState.ThreatSent ? new Date(date) : undefined,
                    lawsuitSentAt: dateFormOpenByState === LegalCollectionState.LawsuitSent ? new Date(date) : undefined
                  }
                }
              })
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default SentLegalCollection
