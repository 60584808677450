import { FC, useMemo, useState } from 'react'
import { useApplicationsListQuery, ApplicationsListQuery, useCountersQuery } from '../../graphql/schema'
import ApplicationForm from '../../components/Forms/ApplicationForm'
import FilterForm, { useApplicationFilter } from './FilterForm'
import { dateTimeFormatter } from '../../utils/dateFormatter'
import useScrollPagination from '../../hooks/useScrollPagination'
import SearchBar, { FilterButton } from '../../components/Search'
import AvatarTooltip from '../../components/Avatar/AvatarTooltip'
import { NodeType } from '../../types'
import AddButton from '../../components/AddButton'
import useNodes from '../../hooks/useNodes'
import GridView, { GridViewConfig } from '../../components/GridView'
import CurrentUserFilter from './CurrentUserFilter'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import SelectedFilters from './SelectedFilters'
import { AppStatus, ApplicationStatusBadge } from '../../components/ApplicationStatus'
import Tooltip from '../../components/Tooltip'
import SuspenseModal from '../../components/SuspenseModal.tsx'

type Application = NodeType<ApplicationsListQuery['applications']>

const appTableConfig: GridViewConfig<Application> = {
  grid: 'grid-cols-application',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    {
      title: 'Компания',
      value: (a) => <span className='line-clamp-1'>{a?.customerCompany?.company?.shortWithOpf}</span>
    },
    { title: 'Источник', value: (a) => <span className='line-clamp-1'>{a?.source?.name}</span> },
    { title: 'Cоздано', value: (a) => dateTimeFormatter.format(new Date(a.created)), numeric: true },
    {
      title: 'МЛ',
      titleClassName: 'text-center',
      padding: false,
      value: (a) => <AvatarTooltip className='mx-auto' userId={a?.user?._id} />
    },
    {
      title: 'Статус',
      padding: false,
      value: (a) => (
        <div className='flex px-[5px]'>
          <Tooltip target={<ApplicationStatusBadge status={a.status} />}>
            {a.status === AppStatus.Closed && a.lossReason}
          </Tooltip>
        </div>
      )
    }
  ],
  rows: {
    link: (a) => `/applications/${a?._id}`
  }
}

const Applications: FC = () => {
  const [addFormOpen, setAddFormOpen] = useState(false)
  const { data: countersData } = useCountersQuery()
  const rawCounters = useNodes(countersData?.counters?.edges)

  const applicationsTodayCount = useMemo(() => {
    const applicationsTodayCount = rawCounters?.find((node) => node.name === 'ApplicationToday')
    return applicationsTodayCount ? applicationsTodayCount.count : 0 // Вернём 0, если счётчик не найден
  }, [rawCounters])

  const { setFilterState, query, setQuery, filterActive, filterState, variables } = useApplicationFilter()

  const {
    data: applicationsData,
    refetch,
    fetchMore,
    loading,
    error
  } = useApplicationsListQuery({
    variables,
    // для первого запроса обращаемся к кэшу, а для последующих - и к серверу тоже
    // чтобы не слетала пагинация
    nextFetchPolicy: 'cache-and-network'
  })

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const applicationsList = useNodes(applicationsData?.applications?.edges)

  function openAddModal() {
    setAddFormOpen(true)
  }

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    applicationsData?.applications?.pageInfo
  )

  return (
    <div className='container flex-grow px-6 py-15'>
      <div className='mb-8 rounded-xl bg-surface-secondary p-5'>
        <div className='rounded-md bg-white-0 shadow-xs'>
          <div className='flex items-start justify-between border-b-1 border-grayscale-400 p-10'>
            <div>
              <h1 className='font-display text-h100'>Заявки</h1>
            </div>
          </div>
          <div className='flex gap-12 p-10'>
            <div>
              <div className='mb-4 text-grayscale-150'>Заявок за сегодня</div>
              <div>{applicationsTodayCount}</div>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query}>
        <CurrentUserFilter
          userId={filterState?.users[0]}
          onChange={(userId) => {
            setFilterState({
              ...filterState,
              users: userId ? [userId] : []
            })
          }}
        />
        <FilterButton active={filterActive}>
          <FilterForm state={filterState} onChange={setFilterState} />
        </FilterButton>
      </SearchBar>

      <SelectedFilters />

      <div className='rounded-xl bg-surface-secondary p-5' ref={triggerRef}>
        <AddButton className='mb-5' onClick={() => openAddModal()}>
          Новая заявка
        </AddButton>
        <GridView
          config={appTableConfig}
          data={applicationsList}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
      <SuspenseModal open={addFormOpen} setOpen={setAddFormOpen}>
        <div className='rounded-xl bg-surface-primary'>
          <ApplicationForm
            onDone={() => {
              refetch()
              setAddFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
    </div>
  )
}

export default Applications
