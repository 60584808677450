import { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from '../../../components/Tooltip'
import ConfirmationForm from '../../../components/ConfirmationForm'
import SupplyPaymentForm from './SupplyPaymentForm'
import SupplyForm from './SupplyForm'
import { NodeType } from '../../../types'
import EntityFolder from '../../../components/DocumentManager/EntityFolder'
import Document from '../../../components/DocumentManager/Document'
import { ReactComponent as PayIcon } from '../../../svg/icons/payout.svg'
import { ReactComponent as WarningIcon } from '../../../svg/icons/warning.svg'
import { ReactComponent as TrashIcon } from '../../../svg/ui/delete.svg'
import { ReactComponent as AddIcon } from '../../../svg/ui/plus.svg'
import { ReactComponent as EditIcon } from '../../../svg/ui/edit.svg'
import { ReactComponent as NewDocIcon } from '../../../svg/ui/new-doc.svg'
import { ReactComponent as SuccessIcon } from '../../../svg/ui/success.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/ui/refresh.svg'
import Modal from '../../../components/Modal'
import {
  BankOrderState,
  BankOrderTargetType,
  DealSuppliesQuery,
  DealSupplyMode,
  DocumentTypeEntity,
  PaymentKind,
  PaymentState,
  PaymentTargetType,
  useBankOrdersQuery,
  useDealSupplyShipmentsLazyQuery,
  useDealRatesForSupplyQuery,
  usePaymentsQuery,
  useRemoveDealSupplyMutation,
  useUpdateDealSupplyFlagsMutation,
  useAddShipmentMutation,
  useDocumentsLazyQuery,
  DocumentsQuery,
  useDealSuppliesForVatQuery,
  VatKind
} from '../../../graphql/schema'
import useEntityDocuments from '../../../components/DocumentManager/useEntityDocuments'
import useNodes from '../../../hooks/useNodes'
import { formatDecimal, formatMoneyDecimal } from '../../../utils/formatNumber'
import Hint from '../../../components/Hint'
import {
  SHIPMENT_SPECS_FOLDER_NAME,
  SHIPPING_FOLDER_NAME,
  SPECIFICATIONS_FOLDER_NAME,
  SUPPLY_FOLDER_NAME,
  TRANSFER_FOLDER_NAME
} from '../../../config'
import { dateFormatter } from '../../../utils/dateFormatter'
import Spec from './Spec'
import SuspenseModal from '../../../components/SuspenseModal'
import useAccessCheck from '../../../hooks/useAccessCheck.ts'
import { getSpecTotals } from './parseSpec.ts'
import BankOrders from '../../../components/BankOrders/BankOrders.tsx'
import TransferActForm from './TransferActForm.tsx'
import SupplyOverdueTooltip from '../../../components/SupplyOverdueTooltip.tsx'
import { Card, CardHeader, CardIconButton, CardMenu, CardTitle } from '../../../components/Card.tsx'
import { GridButton, GridTable, GridTableCell, GridTableRow } from '../../../components/Table.tsx'
import PrimaryButton from '../../../components/UI/PrimaryButton.tsx'
import SupplyShipment from './SupplyShipment.tsx'
import Switch from '../../../components/Switch.tsx'
import ShipmentForm from './ShipmentForm.tsx'
import { vatKindDict } from '../../../utils/dictionaries.ts'
import checkSuppliesVat from '../../../utils/checkSuppliesVat.ts'

interface SupplyProps {
  dealId: number
  customerId?: number
  supply: NodeType<NonNullable<DealSuppliesQuery['deal']>['dealSupplies']>
}

const Supply: FC<SupplyProps> = ({ supply, dealId, customerId }) => {
  const [supplyPaymentFormOpen, setSupplyPaymentFormOpen] = useState<boolean>(false)
  const [transferActFormOpen, setTransferActFormOpen] = useState<boolean>(false)

  // lazy query to refetch supply shipments
  const [refetchShipments] = useDealSupplyShipmentsLazyQuery({
    variables: { id: supply._id.toString() },
    fetchPolicy: 'network-only'
  })

  const [step, setStep] = useState<number | undefined>()

  const frameMode = supply.mode === DealSupplyMode.Frame

  const { data } = useBankOrdersQuery({
    variables: {
      targetId: supply._id.toString(),
      targetType: BankOrderTargetType.DealSupply
    }
  })

  const { data: basePaymentsData } = usePaymentsQuery({
    variables: {
      kinds: [PaymentKind.Advance, PaymentKind.Commission],
      targetType: PaymentTargetType.Deal,
      targetId: dealId.toString()
    }
  })

  const { data: suppliesForVatData } = useDealSuppliesForVatQuery({
    variables: {
      id: `${dealId}`
    }
  })
  const suppliesForVatComparison = useNodes(suppliesForVatData?.deal?.dealSupplies?.edges)
  const advancePaymentDone =
    useNodes(basePaymentsData?.payments.edges).find((p) => p.kind === PaymentKind.Advance)?.state === PaymentState.Paid
  const comissionPaymentDone =
    useNodes(basePaymentsData?.payments.edges).find((p) => p.kind === PaymentKind.Commission)?.state ===
    PaymentState.Paid

  const payments = useNodes(data?.bankOrders?.edges)
  const shipments = useNodes(supply.shipments?.edges)
  const hasPayments = !!payments.length
  const allPaymentsDone = payments.every((p) => p?.state === BankOrderState.Paid)
  const hasAccessSupplyFullyPaid = useAccessCheck('deal.supplies.supply_fully_paid')
  const hasAccessBankOrdersManage = useAccessCheck('bank_orders.manage')
  const hasAccessShippingDoneManage = useAccessCheck('deal.supplies.shipping_done_manage')

  const supplierCompany = supply?.supplierCompany

  const [removeDealSupply] = useRemoveDealSupplyMutation({
    update: (cache) => {
      const normalizedId = cache.identify({ id: supply._id, __typename: 'DealSupply' })
      cache.evict({ id: normalizedId })
      cache.gc()
    }
  })

  const supplyDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SUPPLY_FOLDER_NAME
  })
  const supplyDocsSettled = supplyDocs.every((doc) => !doc.loading)
  const specificationDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SPECIFICATIONS_FOLDER_NAME
  })
  const specificationDocsSettled = specificationDocs.every((doc) => !doc.loading)
  const shippingDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: SHIPPING_FOLDER_NAME
  })
  // const shippingDocsSettled = shippingDocs.every((doc) => !doc.loading)
  const transferDocs = useEntityDocuments({
    entityId: supply._id,
    entityType: DocumentTypeEntity.DealSupply,
    folderName: TRANSFER_FOLDER_NAME
  })
  // const transferDocsSettled = transferDocs.every((doc) => !doc.loading)

  const hasContractDocs = frameMode ? !!specificationDocs.length : !!supplyDocs.length
  const contractDocsSettled = frameMode ? specificationDocsSettled : supplyDocsSettled
  // const hasShippingDocs = !!shippingDocs.length && !!transferDocs.length
  const hasPlannedShippingDate = Boolean(supply.shippingDate)
  const hasAmount = supply.amount && supply.amount > 0

  const { data: dealAdvanceRateData } = useDealRatesForSupplyQuery({
    variables: { id: dealId.toString() },
    skip: !dealId
  })
  const dealAdvanceRate = dealAdvanceRateData?.deal?.advanceRate
  const dealComissionRate = dealAdvanceRateData?.deal?.comissionRate

  const [updateDealSupply] = useUpdateDealSupplyFlagsMutation()

  const onFlagChange = useCallback(
    (flags: { contractDone?: boolean; paymentDone?: boolean; shippingDone?: boolean }) => {
      // we need to set fact shipping date to the latest shipment date when shipping is done
      let factShippingDate: Date | undefined

      if (flags.shippingDone === true) {
        const maxShippingTimestamp = Math.max(...shipments.map((s) => (s.date ? new Date(s.date).getTime() : 0)))
        if (maxShippingTimestamp <= 0) return
        factShippingDate = new Date(maxShippingTimestamp)
        if (!supply.factShippingDate || new Date(supply.factShippingDate) < factShippingDate) {
          updateDealSupply({
            variables: {
              input: {
                id: supply._id.toString(),
                factShippingDate: factShippingDate.toISOString()
              }
            }
          })
        }
      }

      updateDealSupply({
        variables: {
          input: {
            id: supply._id.toString(),
            contractDone: supply.contractDone,
            paymentDone: supply.paymentDone,
            shippingDone: supply.shippingDone,
            factShippingDate: factShippingDate?.toISOString(),
            ...flags
          }
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateDealSupply: {
            __typename: 'updateDealSupplyPayload',
            dealSupply: {
              __typename: 'DealSupply',
              _id: supply._id,
              contractDone: supply.contractDone,
              paymentDone: supply.paymentDone,
              shippingDone: supply.shippingDone,
              factShippingDate: factShippingDate?.toISOString(),
              ...flags
            }
          }
        }
      })
    },
    [
      updateDealSupply,
      supply._id,
      supply.contractDone,
      supply.paymentDone,
      supply.shippingDone,
      supply.factShippingDate,
      shipments
    ]
  )

  const [deletionFormOpen, setDeletionFormOpen] = useState(false)
  const [editFormOpen, setEditFormOpen] = useState(false)
  const [editShipmentFormOpenById, setEditShipmentFormOpenById] = useState<number>()

  const hasRequisites = Boolean(
    supplierCompany &&
      supplierCompany?.company?.bankDetails?.edges?.length &&
      supplierCompany?.company?.bankDetails?.edges[0]?.node?.bic &&
      supplierCompany?.company?.bankDetails?.edges[0]?.node?.account &&
      supplierCompany?.company?.inn
  )

  useEffect(() => {
    if (step !== undefined) return

    if (supply?.paymentDone) {
      setStep(2)
    } else if (supply?.contractDone) {
      setStep(1)
    } else {
      setStep(0)
    }
  }, [supply?.paymentDone, supply?.contractDone, step])

  const [addShipment, { loading: addShipmentLoading }] = useAddShipmentMutation()
  const handleAddShipment = async () => {
    if (addShipmentLoading) return

    const result = await addShipment({
      variables: {
        input: {
          dealSupply: supply._id.toString(),
          status: 'pending'
        }
      }
    })

    const shipmentId = result.data?.addShipment?.shipment?._id
    if (!shipmentId) {
      console.error('Failed to create shipment')
      return
    }
    refetchShipments()
  }

  const canPay =
    hasRequisites &&
    hasContractDocs &&
    hasAmount &&
    hasPlannedShippingDate &&
    (dealAdvanceRate ? advancePaymentDone : true) &&
    (dealComissionRate ? comissionPaymentDone : true)

  const [getDocuments] = useDocumentsLazyQuery()
  const [shipmentDocsByEntityId, setShipmentDocsByEntityId] = useState<{
    [key: string]: NodeType<DocumentsQuery['documents']>[]
  }>({})
  const updateShipmentDocs = useCallback(() => {
    const docs: { [key: string]: NodeType<DocumentsQuery['documents']>[] } = {}
    const entityIds = shipments.map((s) => s._id)
    Promise.all(
      entityIds.map((entityId) =>
        getDocuments({
          variables: {
            filter: {
              entityType: DocumentTypeEntity.Shipment,
              type: SHIPMENT_SPECS_FOLDER_NAME,
              entityId: `${entityId}`
            }
          }
        })
      )
    ).then((res) => {
      res.forEach(({ data }, index) => {
        if (data?.documents?.edges?.length) {
          docs[entityIds[index]] = data?.documents?.edges?.map((edge) => edge?.node).filter((n) => !!n)
        }
      })
    })
    setShipmentDocsByEntityId(docs)
  }, [getDocuments, shipments])
  useEffect(() => {
    updateShipmentDocs()
  }, [shipments, updateShipmentDocs])

  const withoutDateShipments = shipments?.filter((s) => !s.date)
  const withoutAmountShipments = shipments?.filter((s) => typeof s.amount !== 'number')
  const withoutDocsShipments = shipments.filter((s) => !shipmentDocsByEntityId[s._id]?.length)
  const transferDocsPdf = transferDocs.filter((d) => d.mimeType === 'application/pdf')
  const canShippingDone =
    !withoutDateShipments?.length &&
    !withoutAmountShipments?.length &&
    !!transferDocsPdf.length &&
    !withoutDocsShipments?.length

  const { totalAmount } = getSpecTotals(supply?.nomenclature || [])
  const amountDeviates = supply?.nomenclature?.length && supply.amount && totalAmount && supply.amount !== totalAmount

  const { suppliesVatRateMatches, dealAndSuppliesVatKindMatches } = checkSuppliesVat(
    suppliesForVatComparison,
    suppliesForVatData?.deal?.kind,
    suppliesForVatData?.deal?.vatRate
  )

  const totalShipped = shipments.reduce((acc, s) => acc + s.amount, 0)

  const canDelete =
    !supply.paymentDone &&
    !supply.contractDone &&
    !supply.shippingDone &&
    !hasContractDocs &&
    !payments.length &&
    !transferDocs.length &&
    !shippingDocs.length &&
    !shipments.length

  return (
    <Card className='pb-5'>
      <CardHeader>
        <CardTitle>
          <Link to={`/suppliers/${supplierCompany._id}`}>{supplierCompany?.company?.shortWithOpf}</Link>
        </CardTitle>

        <div className='mr-auto pr-3'>
          <SupplyOverdueTooltip supplyId={supplierCompany._id} />
        </div>
        <CardMenu>
          <CardIconButton onClick={() => setEditFormOpen(true)}>
            <EditIcon className='flex-none' title='Редактировать' />
          </CardIconButton>
          {canDelete && (
            <CardIconButton title='Удалить' onClick={() => setDeletionFormOpen(true)}>
              <TrashIcon title='Удалить' />
            </CardIconButton>
          )}
        </CardMenu>
      </CardHeader>
      <div className='mx-5 mb-8 grid grid-cols-3 gap-4 rounded-md bg-surface-primary p-5 shadow-card'>
        <button
          data-headlessui-state={step === 0 ? 'active' : supply.contractDone && 'checked'}
          onClick={() => setStep(0)}
          className='flex items-center justify-center whitespace-nowrap rounded-md bg-surface-secondary py-3 text-labels-secondary ring-grayscale-400 ui-active:bg-tr-green ui-active:font-medium ui-active:text-dark-green'
        >
          {supply.contractDone && <SuccessIcon className='mr-3 inline-flex' />}
          Договор
        </button>
        <button
          data-headlessui-state={step === 1 ? 'active' : supply.paymentDone && 'checked'}
          onClick={() => setStep(1)}
          className='flex items-center justify-center whitespace-nowrap rounded-md bg-surface-secondary py-3 text-labels-secondary ring-grayscale-400 ui-active:bg-tr-green ui-active:font-medium ui-active:text-dark-green'
        >
          {supply.paymentDone && <SuccessIcon className='mr-3 inline-flex' />}
          Оплата
        </button>
        <button
          data-headlessui-state={step === 2 ? 'active' : supply.shippingDone && 'checked'}
          onClick={() => setStep(2)}
          className='flex items-center justify-center whitespace-nowrap rounded-md bg-surface-secondary py-3 text-labels-secondary ring-grayscale-400 ui-active:bg-tr-green ui-active:font-medium ui-active:text-dark-green'
        >
          {supply.shippingDone && <SuccessIcon className='mr-3 inline-flex' />}
          Отгрузка
        </button>
      </div>
      <div className='grid grid-cols-1 gap-y-5'>
        <div className='mx-5 grid gap-y-2 rounded-md bg-surface-primary p-5 shadow-card empty:hidden'>
          {!supply.amount && (
            <Hint
              text='Добавить стоимость поставки'
              onClick={() => {
                setEditFormOpen(true)
              }}
            />
          )}
          {!supply.shippingDate && (
            <Hint
              text='Добавить плановую дату отгрузки'
              onClick={() => {
                setEditFormOpen(true)
              }}
            />
          )}
          {step === 2 && (
            <>
              {withoutDateShipments?.map((s) => (
                <Hint
                  key={s._id}
                  text={`Заполните дату приёмки в Отгрузке №${shipments.length - shipments?.findIndex((shipment) => shipment._id === s._id)}`}
                  onClick={() => {
                    setEditShipmentFormOpenById(s._id)
                  }}
                />
              ))}
              {withoutAmountShipments?.map((s) => (
                <Hint
                  key={s._id}
                  text={`Заполните сумму отгрузки в Отгрузке №${shipments.length - shipments?.findIndex((shipment) => shipment._id === s._id)}`}
                  onClick={() => {
                    setEditShipmentFormOpenById(s._id)
                  }}
                />
              ))}
              {!transferDocsPdf?.length && <Hint text='Подгрузите подписанный акт приема-предачи' onClick={() => {}} />}
              {withoutDocsShipments?.map((s) => (
                <Hint
                  key={s._id}
                  text={`Подгрузите документ отгрузки в Отгрузке №${shipments.length - shipments?.findIndex((shipment) => shipment._id === s._id)}`}
                  onClick={() => {}}
                />
              ))}
            </>
          )}
        </div>
        <div className='mx-5 rounded-md bg-surface-primary shadow-card'>
          <GridTable className='grid-cols-[42%_58%]'>
            <GridTableRow>
              <GridTableCell className='col-span-full'>
                {supplierCompany?.company?.shortWithOpf}
                <div className='text-labels-secondary'>
                  {supply?.companyBranch?.name || `ИНН: ${supplierCompany?.company?.inn}`}
                </div>
              </GridTableCell>
            </GridTableRow>
            <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
              <GridTableCell className='text-labels-secondary'>Договор поставки</GridTableCell>
              {supply?.contractNumber && supply?.contractDate ? (
                <GridTableCell className='flex items-center gap-x-5'>
                  {supply?.contractNumber} от {dateFormatter.format(new Date(supply?.contractDate))}
                </GridTableCell>
              ) : (
                <GridButton type='button' onClick={() => setEditFormOpen(true)}>
                  <AddIcon />
                  Добавить
                </GridButton>
              )}
            </GridTableRow>
            <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
              <GridTableCell className='text-labels-secondary'>Спецификация</GridTableCell>
              {supply?.specificationNumber && supply?.specificationDate ? (
                <GridTableCell className='flex items-center gap-x-5'>
                  {supply?.specificationNumber} от {dateFormatter.format(new Date(supply?.specificationDate))}
                </GridTableCell>
              ) : (
                <GridButton type='button' onClick={() => setEditFormOpen(true)}>
                  <AddIcon />
                  Добавить
                </GridButton>
              )}
            </GridTableRow>
            <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
              <GridTableCell className='text-labels-secondary'>Сумма поставки</GridTableCell>
              {/* <GridTableCell className='flex items-center gap-x-5'>
                {supply?.amount ? formatMoneyDecimal(supply?.amount / 100) : '—'}
                {amountDeviates && (
                  <div className='relative'>
                    <Tooltip target={<WarningIcon className='text-base-red' />}>
                      Сумма в номенклатуре: {formatMoneyDecimal(totalAmount / 100)}
                    </Tooltip>
                  </div>
                )}
              </GridTableCell> */}
              {supply?.amount ? (
                <GridTableCell className='flex items-center gap-x-5'>
                  {formatMoneyDecimal(supply?.amount / 100)}
                  {amountDeviates && (
                    <div className='relative'>
                      <Tooltip target={<WarningIcon className='text-base-red' />}>
                        Сумма в номенклатуре: {formatMoneyDecimal(totalAmount / 100)}
                      </Tooltip>
                    </div>
                  )}
                </GridTableCell>
              ) : (
                <GridButton type='button' onClick={() => setEditFormOpen(true)}>
                  <AddIcon />
                  Добавить
                </GridButton>
              )}
            </GridTableRow>
            <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
              <GridTableCell className='text-labels-secondary'>Плановая дата отгрузки</GridTableCell>
              {supply?.shippingDate ? (
                <GridTableCell>{dateFormatter.format(new Date(supply.shippingDate))}</GridTableCell>
              ) : (
                <GridButton type='button' onClick={() => setEditFormOpen(true)}>
                  <AddIcon />
                  Добавить
                </GridButton>
              )}
            </GridTableRow>
            {supply.factShippingDate && (
              <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <GridTableCell className='text-labels-secondary'>Фактическая дата отгрузки</GridTableCell>

                <GridTableCell>{dateFormatter.format(new Date(supply.factShippingDate))}</GridTableCell>
              </GridTableRow>
            )}

            <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
              <GridTableCell className='text-labels-secondary'>Валюта поставщика</GridTableCell>
              <GridTableCell>{supply?.currency || '—'}</GridTableCell>
            </GridTableRow>

            {supply?.vatRate !== undefined && (
              <GridTableRow className='col-span-full grid grid-cols-subgrid divide-x-1 divide-separators-primary'>
                <GridTableCell className='text-labels-secondary'>НДС поставки</GridTableCell>
                <GridTableCell className='flex items-center gap-x-5'>
                  {supply.vatRate !== null && supply.vatRate !== undefined ? (
                    <>
                      {`${supply.vatRate === 0 ? `${vatKindDict[supply?.vatKind as VatKind]} ` : ''}${supply.vatRate}%`}
                      {(!suppliesVatRateMatches || !dealAndSuppliesVatKindMatches) && (
                        <div className='relative'>
                          {!suppliesVatRateMatches && (
                            <Tooltip target={<WarningIcon className='text-base-red' />}>
                              Ставки НДС между поставками не совпадают
                            </Tooltip>
                          )}
                          {!dealAndSuppliesVatKindMatches && suppliesVatRateMatches && (
                            <Tooltip target={<WarningIcon className='text-base-red' />}>
                              Вид НДС между поставками и сделкой не совпадают
                            </Tooltip>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='text-labels-secondary'>Нет распознанной номенклатуры</div>
                  )}
                </GridTableCell>
              </GridTableRow>
            )}
          </GridTable>
        </div>

        {step === 0 && (
          <>
            <div className='mx-5 rounded-md bg-surface-primary shadow-card'>
              <div className='px-5 py-4'>
                {frameMode ? (
                  <>
                    <div className='mb-6 font-medium'>Рамочный договор</div>
                    {!!supply?.frameContractDocument && (
                      <Document canDelete={false} doc={supply.frameContractDocument} />
                    )}
                  </>
                ) : (
                  <>
                    <div className='mb-6 font-medium'>Договор поставки</div>
                    <EntityFolder
                      entityId={supply._id}
                      entityType={DocumentTypeEntity.DealSupply}
                      folderName={SUPPLY_FOLDER_NAME}
                      canDelete={!supply.contractDone}
                      canUpload={!supply.contractDone}
                    />
                  </>
                )}
              </div>
              {frameMode && (
                <div className='border-t-1 border-separators-primary px-5 py-4'>
                  <div className='mb-6 font-medium'>Спецификации</div>
                  <EntityFolder
                    entityId={supply._id}
                    entityType={DocumentTypeEntity.DealSupply}
                    folderName={SPECIFICATIONS_FOLDER_NAME}
                    canDelete={!supply.contractDone}
                    canUpload={!supply.contractDone}
                  />
                </div>
              )}
              {/* Фикс для старых поставок, где из-за отсутствия рамочного договора не отображались доки IT-2072 */}
              {!frameMode && !!specificationDocs?.length && (
                <div className='border-t-1 border-separators-primary px-5 py-4'>
                  <div className='mb-6 font-medium'>Спецификации (архив)</div>
                  <EntityFolder
                    entityId={supply._id}
                    entityType={DocumentTypeEntity.DealSupply}
                    folderName={SPECIFICATIONS_FOLDER_NAME}
                    canDelete={!supply.contractDone}
                    canUpload={!supply.contractDone}
                  />
                </div>
              )}
              <div className='border-t-1 border-separators-primary px-5 py-4'>
                <div className='mb-6 font-medium'>Номенклатура</div>
                {!supply?.nomenclature &&
                (!supply?.contractNumber ||
                  !supply?.contractDate ||
                  !supply?.specificationNumber ||
                  !supply?.specificationDate) ? (
                  <div className='grid gap-y-2'>
                    {(!supply?.contractNumber || !supply?.contractDate) && (
                      <Hint
                        text='Добавить номер и дату договора поставки'
                        onClick={() => {
                          setEditFormOpen(true)
                        }}
                      />
                    )}
                    {(!supply?.specificationNumber || !supply?.specificationDate) && (
                      <Hint
                        text='Добавить номер и дату спецификации'
                        onClick={() => {
                          setEditFormOpen(true)
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <Spec
                    editable={!supply.contractDone}
                    supplyId={supply._id}
                    currency={supply?.currency || ''}
                    spec={supply?.nomenclature}
                  />
                )}
              </div>
            </div>
            {hasContractDocs && contractDocsSettled && supply.amount && (
              <div className='mx-5 text-labels-secondary'>
                <Switch
                  left
                  label='Все документы загружены'
                  checked={supply.contractDone}
                  onChange={(checked) => onFlagChange({ contractDone: checked })}
                />
              </div>
            )}
          </>
        )}
        {step === 1 && (
          <>
            <PrimaryButton
              className='mx-5 flex cursor-pointer items-center'
              disabled={!hasAccessBankOrdersManage || !canPay}
              onClick={() => setSupplyPaymentFormOpen(true)}
            >
              <PayIcon className='flex-none' />
              Оплатить поставку
              {!canPay && (
                <Tooltip className='ml-auto' target={<WarningIcon className='text-base-red' />}>
                  <div className='mb-2 font-medium'>Чтобы продолжить, нужно:</div>
                  <ul className='list-inside list-disc'>
                    {!hasRequisites && (
                      <li>
                        <Link className='underline' to={`/suppliers/${supplierCompany._id}`}>
                          Добавить реквизиты поставщика
                        </Link>
                      </li>
                    )}
                    {!hasContractDocs && (
                      <li>
                        <button className='underline' onClick={() => setStep(0)}>
                          Загрузить {frameMode ? 'спецификации' : 'договор поставки'}
                        </button>
                      </li>
                    )}
                    {!hasAmount && (
                      <li>
                        <button className='underline' onClick={() => setEditFormOpen(true)}>
                          Указать стоимость поставки
                        </button>
                      </li>
                    )}
                    {!hasPlannedShippingDate && (
                      <li>
                        <button className='underline' onClick={() => setEditFormOpen(true)}>
                          Указать плановую дату отгрузки
                        </button>
                      </li>
                    )}
                    {!!dealAdvanceRate && !advancePaymentDone && <li>Получить авансовый платёж от клиента</li>}
                    {!!dealComissionRate && !comissionPaymentDone && <li>Получить комиссионный платёж от клиента</li>}
                  </ul>
                </Tooltip>
              )}
            </PrimaryButton>
            {hasPayments && (
              <div className='mx-5 rounded-md bg-surface-primary shadow-card'>
                <div className='px-5 py-4 empty:hidden'>
                  <BankOrders
                    companyName={supplierCompany?.company?.shortWithOpf || ''}
                    payments={payments}
                    canDelete={hasAccessBankOrdersManage}
                  />
                </div>
              </div>
            )}
            {hasAccessSupplyFullyPaid && allPaymentsDone && (
              <div className='mx-5 text-labels-secondary'>
                <Switch
                  left
                  label='Полностью оплачено'
                  checked={supply.paymentDone}
                  onChange={(checked) => onFlagChange({ paymentDone: checked })}
                />
              </div>
            )}
          </>
        )}
        {step === 2 && (
          <>
            <div className='mx-5 rounded-md bg-surface-primary shadow-card'>
              <div className='px-5 py-4'>
                <div className='mb-6 flex justify-between'>
                  <div className='font-medium'>{TRANSFER_FOLDER_NAME}</div>
                  {!!supply?.nomenclature && (
                    <button
                      className='flex items-center gap-4 text-p200 text-labels-secondary'
                      onClick={() => setTransferActFormOpen(true)}
                    >
                      <NewDocIcon title={TRANSFER_FOLDER_NAME} />
                      {TRANSFER_FOLDER_NAME}
                    </button>
                  )}
                </div>
                <EntityFolder
                  entityId={supply._id}
                  entityType={DocumentTypeEntity.DealSupply}
                  folderName={TRANSFER_FOLDER_NAME}
                  canDelete={!supply.shippingDone}
                  canUpload={!supply.shippingDone}
                />
              </div>
            </div>
            <div className='mx-5 rounded-md bg-surface-primary shadow-card empty:hidden'>
              {!!shippingDocs.length && (
                <div className='px-5 py-4'>
                  <div className='mb-6 font-medium'>{SHIPPING_FOLDER_NAME} (Архив)</div>
                  <EntityFolder
                    entityId={supply._id}
                    entityType={DocumentTypeEntity.DealSupply}
                    folderName={SHIPPING_FOLDER_NAME}
                    canDelete={!supply.shippingDone}
                    canUpload={!supply.shippingDone}
                  />
                </div>
              )}
            </div>
            {!!supply.amount && supply.currency === 'RUB' && (
              <div className='mx-5 rounded-md bg-surface-primary shadow-card'>
                <GridTable className='grid-cols-[42%_58%]'>
                  <GridTableRow>
                    <GridTableCell>Остаток поставки</GridTableCell>
                    <GridTableCell>{formatDecimal(supply.amount - totalShipped)}</GridTableCell>
                  </GridTableRow>
                  <GridTableRow>
                    <Tooltip
                      className='col-span-full'
                      target={
                        <div className='p-7'>
                          <div className='h-[3px] w-full rounded-full bg-separators-primary'>
                            <div
                              className='h-full rounded-full bg-base-green'
                              style={{ width: `${Math.min(100, (totalShipped / supply.amount) * 100)}%` }}
                            />
                          </div>
                        </div>
                      }
                    >
                      {formatDecimal(totalShipped)}{' '}
                      <span className='text-labels-secondary'>/ {formatMoneyDecimal(supply.amount / 100)}</span>
                    </Tooltip>
                  </GridTableRow>
                </GridTable>
              </div>
            )}
            {!supply.shippingDone && (
              <PrimaryButton
                disabled={addShipmentLoading}
                className='mx-5 flex cursor-pointer items-center'
                onClick={handleAddShipment}
              >
                {addShipmentLoading ? <LoadingIcon className='animate-spin' /> : <AddIcon className='flex-none' />}
                Добавить отгрузку
              </PrimaryButton>
            )}
            {shipments.map((s, i) => (
              <div key={s._id} className='mx-5 rounded-md bg-surface-primary shadow-card'>
                <SupplyShipment
                  editable={!supply.shippingDone}
                  id={s._id}
                  index={shipments.length - i}
                  amount={s.amount}
                  date={s.date}
                  documentType={s.nomenclatureType}
                  documentId={s.nomenclatureNumber}
                  documentDate={s.nomenclatureDate}
                  nomenclature={s.nomenclature}
                  supplierId={supplierCompany?._id}
                  supplierCompanyBranchKPP={supply?.companyBranch?.kpp}
                  customerId={customerId}
                  onDone={() => {
                    refetchShipments()
                    updateShipmentDocs()
                  }}
                />
              </div>
            ))}
            {/* {!!shipments.length && ( */}
            {hasAccessShippingDoneManage && canShippingDone && (
              <div className='mx-5 text-labels-secondary'>
                <Switch
                  left
                  label='Полностью отгружено'
                  checked={supply.shippingDone}
                  onChange={(checked) => {
                    onFlagChange({ shippingDone: checked })
                  }}
                />
              </div>
            )}
            {/* )} */}
          </>
        )}
      </div>
      <SuspenseModal open={editFormOpen} setOpen={setEditFormOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <SupplyForm dealId={dealId} supplyId={supply._id} onDone={() => setEditFormOpen(false)} />
        </div>
      </SuspenseModal>
      <Modal open={deletionFormOpen} setOpen={setDeletionFormOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <ConfirmationForm
            title='Удаление поставки'
            onDone={() => {
              setDeletionFormOpen(false)
              removeDealSupply({ variables: { input: { id: supply._id.toString() } } })
            }}
            onDismiss={() => setDeletionFormOpen(false)}
          >
            Вы действительно хотите удалить поставку?
          </ConfirmationForm>
        </div>
      </Modal>
      <SuspenseModal open={supplyPaymentFormOpen} setOpen={setSupplyPaymentFormOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <SupplyPaymentForm
            dealId={dealId}
            supplyId={supply._id}
            onDone={() => {
              setSupplyPaymentFormOpen(false)
            }}
          />
        </div>
      </SuspenseModal>
      <SuspenseModal open={transferActFormOpen} setOpen={setTransferActFormOpen}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <TransferActForm dealId={dealId} supplyId={supply._id} />
        </div>
      </SuspenseModal>
      <SuspenseModal open={!!editShipmentFormOpenById} setOpen={() => setEditShipmentFormOpenById(undefined)}>
        <div className='z-10 rounded-xl bg-surface-primary'>
          <ShipmentForm
            shipmentId={editShipmentFormOpenById}
            onDone={() => {
              setEditShipmentFormOpenById(undefined)
              refetchShipments()
            }}
          />
        </div>
      </SuspenseModal>
    </Card>
  )
}

export default Supply
