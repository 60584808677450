import { FC } from 'react'
import { dateTimeFormatter } from '../../utils/dateFormatter'
import { CompaniesListQuery, useCompaniesListQuery } from '../../graphql/schema'
import useScrollPagination from '../../hooks/useScrollPagination'
import GridView, { GridViewConfig } from '../../components/GridView'
import { NodeType } from '../../types'
import useNodes from '../../hooks/useNodes'
import SearchBar from '../../components/Search'
import { useCompanyFilter } from './FilterForm'
import useUpdateEffect from '../../hooks/useUpdateEffect'

type Company = NodeType<CompaniesListQuery['companies']>

const companyTableConfig: GridViewConfig<Company> = {
  grid: 'grid-cols-company',
  columns: [
    { title: 'ID', value: '_id', numeric: true },
    { title: 'Название', value: (c) => c?.shortWithOpf },
    { title: 'ИНН', value: (c) => c?.inn, numeric: true },
    {
      title: 'Дата обновления',
      value: (c) => dateTimeFormatter.format(new Date(c?.created || '')),
      numeric: true
    }
  ],
  rows: {
    link: (c) => `/companies/${c._id}`
  }
}

const Companies: FC = () => {
  const { query, setQuery, variables } = useCompanyFilter()

  const {
    data: companiesListQuery,
    refetch,
    fetchMore,
    loading,
    error
  } = useCompaniesListQuery({ fetchPolicy: 'cache-and-network', variables })
  const companiesList = useNodes(companiesListQuery?.companies?.edges)
  const { wrapperRef, isFetching } = useScrollPagination(fetchMore, companiesListQuery?.companies?.pageInfo)

  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  return (
    <div className='container flex-grow px-6 py-15'>
      <div className='mb-8 rounded-xl bg-surface-secondary p-5'>
        <div className='rounded-md bg-white-0 shadow-xs'>
          <div className='flex items-start justify-between p-10'>
            <div>
              <h1 className='font-display text-h100'>Компании</h1>
            </div>
          </div>
        </div>
      </div>

      <SearchBar onChange={setQuery} query={query} />

      <div className='rounded-xl bg-surface-secondary p-5' ref={wrapperRef}>
        <GridView
          data={companiesList}
          config={companyTableConfig}
          loading={loading || isFetching}
          error={error?.message}
        />
      </div>
    </div>
  )
}

export default Companies
