import { FC, useMemo } from 'react'
import { SpecItem } from '../graphql/schema'
import { GridTable, GridTableCell, GridTableRow } from './Table'
import { currencyDict, formatDecimal } from '../utils/formatNumber'
import { getSpecTotals } from '../containers/Deal/Supplies/parseSpec'
import copyToClipboard from 'copy-to-clipboard'
import c from 'clsx'
import { showFizz } from './Fizz'

interface SpecificationProps {
  items?: SpecItem[]
  expectedTotalVATCents?: number
  expectedTotalCents?: number
  currency: string
  withPricePerUnitCentsWithoutVatCell?: boolean
}

const Specification: FC<SpecificationProps> = ({
  items,
  currency,
  expectedTotalCents,
  expectedTotalVATCents,
  withPricePerUnitCentsWithoutVatCell
}) => {
  const totals = useMemo(() => {
    return getSpecTotals(items || [])
  }, [items])

  return (
    <GridTable className='grid-cols-[auto_1fr_auto_auto_auto_auto_auto]'>
      <GridTableRow>
        <GridTableCell className='text-labels-secondary'>№</GridTableCell>
        <GridTableCell className='text-labels-secondary'>Наименование</GridTableCell>
        <GridTableCell className='text-labels-secondary'>Кол-во</GridTableCell>
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          Цена за ед. с НДС {currencyDict[currency]}
        </GridTableCell>
        {withPricePerUnitCentsWithoutVatCell && (
          <GridTableCell className='whitespace-nowrap text-labels-secondary'>
            Цена за ед. БЕЗ НДС {currencyDict[currency]}
          </GridTableCell>
        )}
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          НДС в сумме {currencyDict[currency]}
        </GridTableCell>
        <GridTableCell className='whitespace-nowrap text-labels-secondary'>
          Сумма с НДС {currencyDict[currency]}
        </GridTableCell>
      </GridTableRow>
      {items?.map((entry, index) => (
        <GridTableRow key={index}>
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(`${index + 1}`)
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer font-mono'
          >
            {index + 1}
          </GridTableCell>
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(entry.name)
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer'
          >
            {entry.name}
          </GridTableCell>
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(`${entry.count} ${entry.unit}`)
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer text-right font-mono'
          >
            {entry.count} {entry.unit}
          </GridTableCell>
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(formatDecimal(entry.pricePerUnitCents))
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer text-right font-mono'
          >
            {formatDecimal(entry.pricePerUnitCents)}
          </GridTableCell>
          {withPricePerUnitCentsWithoutVatCell && (
            <GridTableCell
              onClick={(e) => {
                copyToClipboard(
                  entry.pricePerUnitCentsWithoutVat ? formatDecimal(entry.pricePerUnitCentsWithoutVat) : ''
                )
                showFizz('Скопировано', e.clientX, e.clientY)
              }}
              className={c(
                'cursor-pointer text-right font-mono',
                (entry?.pricePerUnitCentsWithoutVat || 0) / 100 > 100000 && 'text-base-red'
              )}
            >
              {typeof entry?.pricePerUnitCentsWithoutVat === 'number' &&
                formatDecimal(entry.pricePerUnitCentsWithoutVat)}
            </GridTableCell>
          )}
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(formatDecimal(entry.VATCents))
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer text-right font-mono'
          >
            {formatDecimal(entry.VATCents)}
          </GridTableCell>
          <GridTableCell
            onClick={(e) => {
              copyToClipboard(formatDecimal(entry.totalPriceCents))
              showFizz('Скопировано', e.clientX, e.clientY)
            }}
            className='cursor-pointer text-right font-mono'
          >
            {formatDecimal(entry.totalPriceCents)}
          </GridTableCell>
        </GridTableRow>
      ))}
      <GridTableRow>
        <GridTableCell
          className={c('text-right font-medium', withPricePerUnitCentsWithoutVatCell ? 'col-span-5' : 'col-span-4')}
        >
          Итого:
        </GridTableCell>
        <GridTableCell
          onClick={(e) => {
            copyToClipboard(
              expectedTotalVATCents ? formatDecimal(expectedTotalVATCents) : formatDecimal(totals?.totalVat)
            )
            showFizz('Скопировано', e.clientX, e.clientY)
          }}
          className='cursor-pointer text-right font-mono font-medium'
        >
          {expectedTotalVATCents ? formatDecimal(expectedTotalVATCents) : formatDecimal(totals?.totalVat)}
          {/* TODO: show warning if expected does not match calculated */}
        </GridTableCell>
        <GridTableCell
          onClick={(e) => {
            copyToClipboard(expectedTotalCents ? formatDecimal(expectedTotalCents) : formatDecimal(totals?.totalAmount))
            showFizz('Скопировано', e.clientX, e.clientY)
          }}
          className='cursor-pointer text-right font-mono font-medium'
        >
          {expectedTotalCents ? formatDecimal(expectedTotalCents) : formatDecimal(totals?.totalAmount)}
          {/* TODO: show warning if expected does not match calculated */}
        </GridTableCell>
      </GridTableRow>
    </GridTable>
  )
}

export default Specification
