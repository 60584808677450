import { FC, useState } from 'react'
import { ReactComponent as ZipIcon } from '../../../svg/icons/zip.svg'
import { ReactComponent as LoadingIcon } from '../../../svg/icons/loading.svg'
import { showPopup } from '../../../components/Toaster/showPopup.tsx'
import { emptyCell, moneyCell, renderSheet, saveSheet, textCell } from '../../../utils/XLSX.ts'
import { PaymentKind, useDealForSaldoXlsxLazyQuery, useDealPaymentsBalanceLazyQuery } from '../../../graphql/schema.tsx'
import { paymentKindDict } from '../../../utils/dictionaries.ts'
import { dateFormatter } from '../../../utils/dateFormatter.ts'

interface PaymentScheduleSaveBtnProps {
  dealId: string
  className?: string
}

const SaldoSaveBtn: FC<PaymentScheduleSaveBtnProps> = ({ dealId }) => {
  const [loading, setLoading] = useState(false)

  const [getDeal] = useDealForSaldoXlsxLazyQuery()
  const [getTableData] = useDealPaymentsBalanceLazyQuery()

  const handleSaveSaldo = async () => {
    setLoading(true)
    try {
      const dealData = getDeal({
        variables: { id: dealId },
        fetchPolicy: 'network-only'
      })

      const tableDataRaw = getTableData({
        variables: {
          id: dealId
        },
        fetchPolicy: 'network-only'
      })

      const [{ data }, { data: tableDataResultRaw }] = await Promise.all([dealData, tableDataRaw])

      if (!tableDataResultRaw?.dealPaymentsBalance) return

      const deal = data?.deal

      const leasingPayments =
        tableDataResultRaw?.dealPaymentsBalance?.filter((p) => p?.kind === PaymentKind.Leasing) || []

      const tableData = tableDataResultRaw.dealPaymentsBalance?.map((p) => ({
        amount: p?.amount || 0,
        fee: p?.fee || 0,
        body: p?.kind === PaymentKind.Advance ? p?.amount || 0 : p?.body || 0,
        remainingBody: p?.remainingBody || 0,
        interestRate: p.interestRate,
        number:
          p?.kind === PaymentKind.Leasing
            ? `${leasingPayments?.findIndex((payment) => payment?.id === p.id) + 1}`
            : p?.kind && paymentKindDict[p.kind],
        date: p?.date || '',
        leasingDaysCount: p.leasingDaysCount
      }))

      const total = tableData?.reduce(
        (acc, item) => {
          acc.amount += item.amount
          acc.fee += item.fee
          acc.body += item.body
          acc.redemption += item.remainingBody
          acc.leasingPeriodDate += item.leasingDaysCount
          return acc
        },
        {
          amount: 0,
          fee: 0,
          body: 0,
          redemption: 0,
          leasingPeriodDate: 0
        }
      )

      tableData?.push({
        amount: total.amount,
        fee: total.fee,
        body: total.body,
        remainingBody: total.redemption,
        leasingDaysCount: total.leasingPeriodDate,
        number: '',
        date: 'Итого',
        interestRate: 0
      })

      const ws = renderSheet({
        data: tableData,
        columns: [
          { title: '№ лизингового платежа', width: 10, render: (p) => textCell(p.number) },
          {
            title: 'Дата лизингового платежа',
            width: 15,
            render: (p) => textCell(p.date)
          },
          {
            title: 'Ставка, (% годовых)',
            width: 15,
            render: (p) => (p.date === 'Итого' ? emptyCell : textCell(`${p.interestRate}%`))
          },
          {
            title: 'Кол-во дней в лизинговом периоде',
            width: 15,
            render: (p) => (p.leasingDaysCount === 0 ? textCell('-') : textCell(`${p.leasingDaysCount}`))
          },
          {
            title: 'Сумма лизингового платежа, руб.',
            width: 20,
            render: (p) => moneyCell(p.amount)
          },
          {
            title: 'Сумма возврата финансирования в составе лизингового платежа, руб.',
            width: 20,
            render: (p) => moneyCell(p.body)
          },
          {
            title: 'Плата за финансирование в составе лизингового платежа, руб.',
            width: 20,
            render: (p) => moneyCell(p.fee)
          },
          {
            title: 'Остаток предоставленного финансирования, руб.',
            width: 20,
            render: (p) => moneyCell(p.remainingBody)
          }
        ]
      })

      saveSheet(
        ws,
        `${dateFormatter.format(new Date())} Подведение сальдо расчетов по договору ${deal?.contractNumber} от ${deal?.contractDate ? dateFormatter.format(new Date(deal?.contractDate)) : ''}.xlsx`,
        'Подведение сальдо расчетов'
      )
    } catch (e) {
      showPopup({ title: 'Ошибка', subtitle: 'Не удалось скачать подведение сальдо' })
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='cursor-pointer rounded-md bg-white-0 px-8 py-8 shadow-card'>
      <button
        className='flex h-25 w-full items-center justify-center gap-5 rounded-md border-1 border-dashed border-grayscale-300 px-4 text-labels-tertiary hover:text-labels-secondary'
        onClick={handleSaveSaldo}
        disabled={loading}
      >
        {loading ? <LoadingIcon className='flex-none animate-spin' width='20px' height='20px' /> : <ZipIcon />}
        Подведение сальдо расчетов
      </button>
    </div>
  )
}

export default SaldoSaveBtn
