import { FC, useEffect, useRef } from 'react'
import { ReactComponent as SearchIcon } from '../../svg/icons/search.svg'
import { ReactComponent as CalendarIcon } from '../../svg/ui/calendar.svg'
import { ReactComponent as LoadingIcon } from '../../svg/icons/loading.svg'
import { ReactComponent as CrossIcon } from '../../svg/icons/cross.svg'
import { ReactComponent as ChevronDownIcon } from '../../svg/ui/chevron-down-small.svg'
import { useAtsConversationsQuery } from '../../graphql/schema.tsx'
import { Card, CardHeader, CardMenu, CardTitle } from '../../components/Card.tsx'
import UserFilter from '../../components/Tasks/UserFilter.tsx'
import useCurrentUserId from '../../hooks/useCurrentUserId.ts'
import { dateFormatter } from '../../utils/dateFormatter.ts'
import useNodes from '../../hooks/useNodes.ts'
import useScrollPagination from '../../hooks/useScrollPagination.ts'
import useUpdateEffect from '../../hooks/useUpdateEffect.ts'
import { useCallHistoryFilter } from '../../components/Calls/useCallHistoryFilter.tsx'
import Call from '../../components/Calls/Call.tsx'
import c from 'clsx'

const CallHistory: FC = () => {
  const currentUserId = useCurrentUserId()
  const { filterState, setFilterState, query, setQuery, userId, setUserId, variables } = useCallHistoryFilter()

  const {
    data: atsConversationsData,
    fetchMore,
    refetch
  } = useAtsConversationsQuery({
    fetchPolicy: 'cache-and-network',
    variables
  })
  const calls = useNodes(atsConversationsData?.atsConversations?.edges)

  const { wrapperRef: triggerRef, isFetching } = useScrollPagination(
    fetchMore,
    atsConversationsData?.atsConversations?.pageInfo
  )

  useEffect(() => {
    setUserId(currentUserId)
  }, [currentUserId, setUserId])
  useUpdateEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const inputRef = useRef<HTMLInputElement>(null)
  const handleCalendarClick = () => {
    inputRef?.current?.showPicker()
  }

  return (
    <div className='container flex-grow px-6 py-15' ref={triggerRef}>
      <Card className='w-full'>
        <CardHeader>
          <CardTitle>История звонков</CardTitle>

          <CardMenu className='gap-8 pr-3'>
            <button
              className={c(
                'cursor-pointer font-medium hover:opacity-80',
                filterState?.callType === 'all' ? 'text-labels-secondary' : 'text-labels-tertiary'
              )}
              onClick={() =>
                setFilterState({
                  ...setFilterState(),
                  callType: 'all'
                })
              }
            >
              Все звонки
            </button>
            <button
              className={c(
                'cursor-pointer font-medium hover:opacity-80',
                filterState?.callType === 'noanswer' ? 'text-labels-secondary' : 'text-labels-tertiary'
              )}
              onClick={() => {
                setFilterState({
                  ...setFilterState(),
                  callType: 'noanswer'
                })
              }}
            >
              Без связи
            </button>
          </CardMenu>
        </CardHeader>

        <div className='flex items-center gap-8 px-8 pb-8'>
          <div className='flex h-16 w-full items-center rounded-md bg-surface-primary'>
            <SearchIcon className='mx-5 text-labels-tertiary' />
            <input
              value={query}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 11)
                setQuery(e.target.value)
              }}
              className='flex-grow border-none placeholder-labels-tertiary outline-none ring-0 focus:ring-0'
              placeholder='Введите номер'
            />
          </div>

          <UserFilter userId={userId} onChange={(id) => setUserId(id)} />

          <button
            className='relative flex h-16 min-w-86 cursor-pointer items-center justify-between rounded-md bg-surface-primary px-5 shadow-card'
            onClick={handleCalendarClick}
          >
            {/* Скрытый input, который вызывает системный календарь */}
            <input
              type='date'
              ref={inputRef}
              value={filterState.date}
              onChange={(e) =>
                setFilterState({
                  ...setFilterState(),
                  date: e.target.value
                })
              }
              className='absolute inset-0 h-full w-full cursor-pointer opacity-0'
            />

            <CalendarIcon className='text-labels-secondary' />
            <div className={c('whitespace-nowrap', filterState.date ? 'text-labels-primary' : 'text-labels-secondary')}>
              {filterState.date ? dateFormatter.format(new Date(filterState.date)) : 'Дата разговора'}
            </div>
            <ChevronDownIcon />
          </button>

          {filterState.date && (
            <button
              className='text-labels-tertiary hover:text-base-red'
              onClick={() => {
                setFilterState({
                  ...setFilterState(),
                  date: undefined
                })
              }}
            >
              <CrossIcon height='12px' width='12px' />
            </button>
          )}
        </div>

        <div className='flex flex-col gap-3 px-8 pb-8 empty:hidden'>
          {calls?.map((call) => <Call key={call.id} call={call} />)}
        </div>
        <div className='flex items-center justify-center pb-10 empty:hidden'>
          {isFetching && <LoadingIcon className='animate-spin self-center text-labels-tertiary' />}
        </div>
      </Card>
    </div>
  )
}

export default CallHistory
