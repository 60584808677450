import { FC } from 'react'
import { useCreateLegalCollectionMutation } from '../../../../graphql/schema'
import { ReactComponent as LoadingIcon } from './../../../../svg/icons/loading.svg'
import useCurrentUserId from '../../../../hooks/useCurrentUserId.ts'

interface CreateLegalCollectionProps {
  paymentId: string
  onDone: () => void
  disabled?: boolean
}

const CreateLegalCollection: FC<CreateLegalCollectionProps> = ({ paymentId, onDone, disabled }) => {
  const currentUserId = useCurrentUserId()
  const [createLegalCollection, { loading: createLegalCollectionLoading }] = useCreateLegalCollectionMutation()

  return (
    <button
      disabled={disabled}
      onClick={async () => {
        await createLegalCollection({
          variables: { input: { paymentId, legalUserId: `${currentUserId}` } }
        })
        onDone()
      }}
      className='flex items-center rounded-md border-1 border-grayscale-400 px-5 py-2 font-medium text-labels-secondary'
    >
      Взять в работу
      {createLegalCollectionLoading && <LoadingIcon className='mx-auto ml-5 h-7 w-7 animate-spin' />}
    </button>
  )
}

export default CreateLegalCollection
