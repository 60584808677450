import { FC } from 'react'
import c from 'clsx'
import { Switch as UISwitch, Field, Label } from '@headlessui/react'

interface SwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label?: string
  left?: boolean
  disabled?: boolean
}

const Switch: FC<SwitchProps> = ({ checked, onChange, label, left = false, disabled }) => {
  return (
    <Field className={c('inline-flex gap-x-4', left && 'flex-row-reverse')}>
      {label && <Label className='cursor-pointer'>{label}</Label>}
      <UISwitch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className='group block h-10 w-16 rounded-full bg-labels-tertiary transition data-[checked]:bg-base-red'
      >
        <div className='size-8 translate-x-1 scale-80 rounded-full bg-base-white transition group-data-[checked]:translate-x-7 group-data-[checked]:scale-100' />
      </UISwitch>
    </Field>
  )
}

export default Switch
